import { accountBookingsType as events } from '../../types'
import { ga4Mapper } from '../ga4-mapper'

export const deleteAccountMapper = (eventName) => {
  let dataObject = null
  const result = getDeleteAccountResult(eventName)
  if (result) {
    dataObject = ga4Mapper({
      event: 'manageAccount',
      description: 'manage account',
      element: 'button',
      result,
      interaction: true
    })
  }
  return dataObject
}

export const getDeleteAccountResult = (event) => {
  switch (event) {
    case events.DELETE_ACCOUNT_MODAL_OPEN:
      return 'delete account modal open'

    case events.ACCOUNT_DELETED:
      return 'account deleted'

    default:
      return ''
  }
}

/**
* Mapper for the booking card options
* @param {String} cardTitle
* @returns {Object | null}
*/
export const bookingCardMapper = (cardTitle, track) => {
  if (!cardTitle) return null

  const description = getBookingCardData(track)
  if (!description) return null

  return ga4Mapper({
    event: 'bookingOverview',
    description,
    element: 'accommodation tile',
    type: cardTitle,
    result: 'redirect to booking overview',
    interaction: true
  })
}

export const getBookingCardData = (event) => {
  const descriptions = {
    [events.BOOKING_CARD_CLICKED]: 'click on upcoming booking',
    [events.PAST_BOOKING_CARD_CLICKED]: 'click on past booking'
  }

  return descriptions[event] || null
}

export const addBookingMapper = (event) => {
  let dataObject = null
  const eventData = getAddBookingData(event)
  if (eventData) {
    dataObject = ga4Mapper({
      event: 'manageAccount',
      ...eventData,
      element: 'modal',
      interaction: true
    })
  }
  return dataObject
}

export const getAddBookingData = (event) => {
  switch (event) {
    case events.ADD_BOOKING_MODAL_OPEN:
      return {
        description: 'click on add booking button',
        result: 'add booking modal open'
      }

    case events.ADD_BOOKING_SUCCEEDED_MODAL_CLOSE:
      return {
        description: 'add booking succeeded',
        result: 'add booking succeeded modal close'
      }

    case events.ADD_BOOKING_FAILED_MODAL_CLOSE:
      return {
        description: 'add booking failed',
        result: 'add booking failed modal close'
      }

    default:
      return null
  }
}
