export const loginStatusMapper = (data) => {
  let dataObject = null
  if (data) {
    dataObject = {
      event: 'logged_in_check',
      userId: data.profileId || '',
      logged_in: data.isUserLoggedIn || false
    }
  }
  return dataObject
}
