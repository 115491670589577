import { CUSTOM_PARAMETERS } from '../../constants'

export const accommodationSkiBlockMapper = (data, eventName) => {
  let dataObject = null

  if (data && (eventName === 'open' || eventName === 'close') && data.attr && data.attr.skiBlockTitle) {
    const titleOfSkiBlock = data.attr.skiBlockTitle
    const moreOrLess = (eventName === 'open' ? 'more' : 'less')

    dataObject = {
      event: 'PDP',
      [CUSTOM_PARAMETERS.parameter_2]: "Ski extra's", // description
      [CUSTOM_PARAMETERS.parameter_3]: 'Accordion', // element
      [CUSTOM_PARAMETERS.parameter_4]: titleOfSkiBlock, // type
      [CUSTOM_PARAMETERS.parameter_5]: `show ${moreOrLess}`, // result
      [CUSTOM_PARAMETERS.parameter_6]: true // interaction
    }
  }
  return dataObject
}
