import * as eventTypes from '../../../../js/document/event-types'
import pusher from '../pusher'
import { redeemVoucherServerValidationErrorMapper, redeemVoucherServerSideErrorMapper } from '../mappers/voucher-mapper'

export function listenVoucherRedeemerWidgetEvents (event, track) {
  event.on(eventTypes.voucherRedeemerEvents.VOUCHER_REDEEMER_SERVER_VALIDATION_ERROR, (data) => {
    const mappedData = redeemVoucherServerValidationErrorMapper(data)
    pusher.pushDataObject(mappedData)
  })

  event.on(eventTypes.voucherRedeemerEvents.VOUCHER_REDEEMER_SERVER_ERROR, (data) => {
    const mappedData = redeemVoucherServerSideErrorMapper(data)
    pusher.pushDataObject(mappedData)
  })
}
