export const validationErrorsMapper = (validations, track, eventName, fieldNameSuffix) => {
  const dataObjects = []
  validations.forEach(validation => {
    const dataObject = validationErrorMapper(validation, track, eventName, fieldNameSuffix)
    if (dataObject) {
      dataObjects.push(dataObject)
    }
  })
  return dataObjects
}

export const validationErrorMapper = (validation, track, eventName, fieldNameSuffix) => {
  if (validation && validation.errorMessages && validation.errorMessages.length > 0) {
    return {
      event: eventName || 'trackErrorMessage',
      eventAction: validation.errorMessages.join('; '),
      eventLabel: addSuffix(validation.fieldName, fieldNameSuffix),
      ...(validation.fieldElement && validation.fieldElement.hasAttribute('data-track-value')) && { eventValue: validation.fieldValue }
    }
  }
  return null
}

export const genericErrorMapper = (data) => {
  let dataObject = null
  if (data) {
    dataObject = {
      event: data.eventName,
      eventAction: data.message,
      eventLabel: data.field
    }
  }
  return dataObject
}

const addSuffix = (mainstring, suffix) => {
  if (suffix) {
    return `${mainstring}_${suffix}`
  }
  return mainstring
}
