export const refundRequestMapper = (data) => {
  let dataObject = null
  if (data && data.voucherCode) {
    dataObject = {
      event: 'eec.refundRequestVoucher',
      ecommerce: {
        refund: {
          actionField: {
            id: data.voucherCode
          }
        }
      }
    }
  }
  return dataObject
}
