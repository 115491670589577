import { accommodationRoomtypesType as events } from '../../types'
import { CUSTOM_PARAMETERS } from '../../constants'

export const accommodationRoomtypesMapper = (data, eventName, track) => {
  let dataObject = null
  const dataDescription = getRoomtypeEventDescriptionData(track)

  if (data && (eventName === 'open' || eventName === 'close') && data.attr && dataDescription !== '' && data.attr.roomtypeTitle) {
    dataObject = {
      event: 'PDP',
      [CUSTOM_PARAMETERS.parameter_2]: dataDescription, // description
      [CUSTOM_PARAMETERS.parameter_3]: 'Accordion', // element
      [CUSTOM_PARAMETERS.parameter_4]: data.attr.roomtypeTitle, // type
      [CUSTOM_PARAMETERS.parameter_5]: (eventName === 'open' ? 'show more' : (eventName === 'close' ? 'show less' : null)), // result
      [CUSTOM_PARAMETERS.parameter_6]: true // interaction
    }
  }
  return dataObject
}

/**
* Get the roomtype event description data depending on the event received
* @param {String} event
* @returns {Object}
*/
export const getRoomtypeEventDescriptionData = (event) => {
  switch (event) {
    case events.ROOMTYPES_DESCRIPTION_TOGGLED :
      return 'roomtype description'

    case events.ROOMTYPES_SELECTOR_TOGGLED :
      return 'roomtype selector'

    default:
      return ''
  }
}
