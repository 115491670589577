import storage from '../../../js/document/web-storage'
import { registerWidget } from '../../../js/core/widget/widget-directory'

const widgetApi = 'w-cookie-modal'

export default class CookieModal {
  /**
   * Creates a new Cookie Modal
   *
   * @constructor
   *
   * @param {HTMLElement} element -
   */
  constructor (element) {
    if (!element) { return }
    this.element = element

    this.cookie = storage.cookie
    this.modal = this.element.querySelector('.c-modal')['c-modal']

    this._init()
  }

  _init () {
    const data = this.element.dataset
    const oneTimeCookie = data.cookieOneTimeFlag !== undefined && data.cookieOneTimeFlag.toLowerCase() === 'true'

    if (!oneTimeCookie) {
      // Show modal if cookie does not have value
      // and set value in cookie
      this.modal.events.on('close', () => {
        this._setCookies(data)
      })

      if (!this._isCookieSet(data)) {
        this._hideBackdropIfModalIsNotShown()
        this.modal.open()
      }
    } else {
      // Show modal if cookie has value
      // and delete cookie
      this.modal.events.on('close', () => {
        this._deleteCookie(data)
      })
      if (this._isCookieSet(data)) {
        this._hideBackdropIfModalIsNotShown()
        this.modal.open()
      }
    }
  }

  _hideBackdropIfModalIsNotShown () {
    const styles = window.getComputedStyle(this.element)
    const modalIsHidden = styles.display === 'none' || styles.visibility === 'hidden'
    if (modalIsHidden) {
      this.modal.setProp('hiddenBackdrop', true)
    }
  }

  _isCookieSet (data) {
    const cookieValue = this.cookie.get(data.cookieName)
    return (cookieValue !== null && cookieValue !== undefined && cookieValue.toString() === data.cookieValueEnabled)
  }

  _setCookies (data) {
    const hasExpirationDays = (data.cookieExpirationDays !== null && data.cookieExpirationDays !== undefined)
    this.cookie.set(data.cookieName, data.cookieValueEnabled, {
      domain: data.cookieDomain,
      path: data.cookiePath,
      expirationDays: hasExpirationDays ? parseInt(data.cookieExpirationDays) : undefined
    })
  }

  _deleteCookie (data) {
    this.cookie.delete(data.cookieName)
  }
}

registerWidget(CookieModal, widgetApi)
