import * as eventTypes from '../../../../js/document/event-types'
import * as types from '../types'
import {
  toggleMapper,
  paymentMapper,
  deleteBookingMapper,
  excursionsMapper,
  downloadDocumentsMapper,
  accountSpecialLuggageMapper
} from '../mappers/account-booking/account-booking-mapper'
import pusher from '../pusher'
import {
  openFullCancellationConditionsMapper,
  confirmFullCancellationMapper,
  openPartialCancellationConditionsMapper,
  confirmPartialCancellationMapper
} from '../mappers/account-booking/booking-cancellation-mapper'

/**
* Listeners for the collapse events on the account
* @param {Object} event
* @param {String} track
*/
export function listenCollapseEvents (event, track) {
  event.on(eventTypes.collapseEvents.OPEN, (data) => {
    const mappedData = toggleMapper(data, eventTypes.collapseEvents.OPEN, track)
    pusher.pushDataObject(mappedData)
  })

  event.on(eventTypes.collapseEvents.CLOSE, (data) => {
    const mappedData = toggleMapper(data, eventTypes.collapseEvents.CLOSE, track)
    pusher.pushDataObject(mappedData)
  })
}

export function listenButtonEvents (event, track) {
  const paymentButtonEventPressedEvent = types.accountBookingType.PAYMENT_BUTTON_PRESSED
  const deleteBookingButtonPressedEvent = types.accountBookingType.DELETE_BOOKING_MODAL_OPEN
  const bookingDeletedEvent = types.accountBookingType.BOOKING_DELETED

  if (track === paymentButtonEventPressedEvent) {
    event.on('clickButton', (element, data) => {
      const textBtn = element['c-btn'].getText()
      const mappedData = paymentMapper(data, textBtn)
      pusher.pushDataObject(mappedData)
    })
  }

  if (track === deleteBookingButtonPressedEvent) {
    event.on('clickButton', (_, data) => {
      const mappedData = deleteBookingMapper(data, deleteBookingButtonPressedEvent)
      pusher.pushDataObject(mappedData)
    })
  }

  if (track === bookingDeletedEvent) {
    event.on('clickButton', (_, data) => {
      const mappedData = deleteBookingMapper(data, bookingDeletedEvent)
      pusher.pushDataObject(mappedData)
    })
  }
}

export function listenBookingCancellationFlow (event) {
  event.on(eventTypes.bookingCancellationFlowEvents.CHECK_REGULAR_CANCELLATION_CONDITIONS, (data) => {
    const mappedData = openFullCancellationConditionsMapper(data)
    pusher.pushDataObject(mappedData)
  })

  event.on(eventTypes.bookingCancellationFlowEvents.CONFIRM_REGULAR_CANCELLATION, (data) => {
    const mappedData = confirmFullCancellationMapper(data)
    pusher.pushDataObject(mappedData)
  })

  event.on(eventTypes.bookingCancellationFlowEvents.GET_PARTIAL_CANCELLATION_PARTICIPANTS, (data) => {
    const mappedData = openPartialCancellationConditionsMapper(data)
    pusher.pushDataObject(mappedData)
  })

  event.on(eventTypes.bookingCancellationFlowEvents.CONFIRM_PARTIAL_CANCELLATION, (data) => {
    const mappedData = confirmPartialCancellationMapper(data)
    pusher.pushDataObject(mappedData)
  })
}

/**
* Listeners for the excursions on the account
* @param {Object} event
*/
export function listenExcursionsEvents (event) {
  const excursionClickedEvent = eventTypes.excursionsEvents.EXCURSION_CLICKED
  const seeMoreExcursionsClickedEvent = eventTypes.excursionsEvents.SEE_MORE_EXCURSIONS_CLICKED

  event.on(excursionClickedEvent, (data) => {
    const mappedData = excursionsMapper(excursionClickedEvent, data)
    pusher.pushDataObject(mappedData)
  })

  event.on(seeMoreExcursionsClickedEvent, () => {
    const mappedData = excursionsMapper(seeMoreExcursionsClickedEvent, null)
    pusher.pushDataObject(mappedData)
  })
}

/**
* Listeners for the download documents on the account
* @param {Object} event
*/
export function listenDownloadDocumentsEvents (event) {
  event.on(eventTypes.downloadDocumentsEvents.DOWNLOADED_DOCUMENT, (data) => {
    const mappedData = downloadDocumentsMapper(data)
    pusher.pushDataObject(mappedData)
  })
}

export function listenAccountSpecialLuggageEvents (event) {
  const openEvent = eventTypes.accountSpecialLuggageEvents.OPEN
  const submittedEvent = eventTypes.accountSpecialLuggageEvents.SUBMITTED
  const errorSubmittingEvent = eventTypes.accountSpecialLuggageEvents.ERROR_SUBMITTING

  event.on(openEvent, () => {
    const mappedData = accountSpecialLuggageMapper(openEvent)
    pusher.pushDataObject(mappedData)
  })

  event.on(submittedEvent, type => {
    const mappedData = accountSpecialLuggageMapper(submittedEvent, type)
    pusher.pushDataObject(mappedData)
  })

  event.on(errorSubmittingEvent, () => {
    const mappedData = accountSpecialLuggageMapper(errorSubmittingEvent)
    pusher.pushDataObject(mappedData)
  })
}
