import { ga4Mapper } from '../ga4-mapper'

export const reviewsListPartyCompositionMapper = (data) => {
  let dataObject = null
  const result = []

  if (data) {
    data.forEach(element => {
      if (element.checked) {
        result.push(element.text)
      }
    })
  }

  if (data && result.length !== 0) {
    dataObject = ga4Mapper({
      event: 'reviews',
      description: 'show reviews of party composition',
      element: 'dropdown',
      type: window.navigator.language || window.navigator.userLanguage, // type,
      result: result.join(','),
      interaction: true
    })
  }

  return dataObject
}
