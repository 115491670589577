import { CUSTOM_PARAMETERS } from '../../constants'

export const reviewsListLanguageSwitchMapper = (data) => {
  let dataObject = null
  if (data && data.status != null) {
    dataObject = {
      event: 'reviews',
      [CUSTOM_PARAMETERS.parameter_2]: 'show review in own language', // description
      [CUSTOM_PARAMETERS.parameter_3]: 'toggle', // element
      [CUSTOM_PARAMETERS.parameter_4]: window.navigator.language || window.navigator.userLanguage, // type
      [CUSTOM_PARAMETERS.parameter_5]: data.status ? 'on' : 'off', // result
      [CUSTOM_PARAMETERS.parameter_6]: true // interaction
    }
  }

  return dataObject
}
