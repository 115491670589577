import { CUSTOM_PARAMETERS } from '../../constants'

export const mainMenuMapper = (data) => {
  let dataObject = null
  const isDataValid = data &&
    (data.text && typeof data.text === 'string') &&
    (data.isFinalLink !== null && typeof data.isFinalLink === 'boolean') &&
    (data.itemsTrace && typeof data.itemsTrace === 'string')

  if (isDataValid) {
    dataObject = {
      event: 'mainMenu',
      [CUSTOM_PARAMETERS.parameter_2]: 'Item in main menu is clicked: ' + (data.text ? data.text : ''), // description
      [CUSTOM_PARAMETERS.parameter_3]: 'Clickable text', // element
      [CUSTOM_PARAMETERS.parameter_4]: 'Final link: ' + (data.isFinalLink ? 'true' : 'false'), // type
      [CUSTOM_PARAMETERS.parameter_5]: (data.itemsTrace ? data.itemsTrace : ''), // result
      [CUSTOM_PARAMETERS.parameter_6]: true // interaction
    }
  }
  return dataObject
}
