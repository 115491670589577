import storage from '../../../js/document/web-storage'
import { toCamelCase } from '../../../js/helpers/string'
import { registerWidget } from '../../../js/core/widget/widget-directory'

const widgetApi = 'w-cookie-emergency-dialog'

const classNames = {
  item: 'c-absolute-dialog',
  masterCookieName: 'master-cookie',
  cookieName: 'data-cookie-name',
  cookieValue: 'cookie-values-enabled',
  modalSettings: 'cookie-settings',
  close: 'c-absolute-dialog__close',
  notFullWidth: 'w-cookie-emergency-dialog--not-full-width'
}

export default class CookieEmergencyDialog {
  /**
   * Creates a new Emergency Dialog
   *
   * @constructor
   *
   * @param {HTMLElement} element - The element where to attach QuickSearch
   */
  constructor (element) {
    if (!element) { return }
    this.element = element

    this.cookie = storage.cookie

    this._init()
  }

  _init () {
    this._setMasterCookieListenerFn()
    this._cleanCookied()
    this._setEventListener()
    this._notCookiesBanner()
    this.element.classList.add('in')
  }

  /**
   * Remove those items whose cookies have been set already
   */
  _cleanCookied () {
    Array.from(this.element.querySelectorAll(`.${classNames.item}[${classNames.cookieName}]`))
      .filter((item) => this._isCookieSet(item.dataset.cookieName)).forEach((item) => item.remove())
  }

  /**
   * Get the master cookie element
   */
  _getMasterCookieElement () {
    return this.element.querySelector(`#${classNames.masterCookieName}.${classNames.item}`)
  }

  /**
   * Listener to set the cookie when dialog item is closed
   */
  _setEventListener () {
    this.element.addEventListener('click', (ev) => {
      const target = ev.target && ev.target.closest(`.${classNames.close}`)
      if (target) {
        const item = target.closest(`.${classNames.item}`)
        const dataset = item.dataset
        if (dataset.cookieName) { this._setCookies(dataset) }
        item.remove()
      }
      this._notCookiesBanner()
    })

    this._setMasterCookieListeners()
  }

  /**
   * Listener's function for master cookie's settings modal
   */
  _setMasterCookieListenerFn () {
    const masterCookie = this._getMasterCookieElement()
    if (masterCookie) {
      const masterCookieDataset = masterCookie.dataset
      this._masterCookieListenerFn = () => {
        const cookieSettingElements = Array.from(this.element
          .querySelectorAll(`[id*="-cookie-settings"] .${classNames.modalSettings}__form input[name="setting-value"]`))
        if (cookieSettingElements) {
          const selectedElement = cookieSettingElements.find((element) => element.checked)
          const dataset = masterCookieDataset
          // Sets the cookie's value and whatever settings found for master cookie
          dataset[toCamelCase(classNames.cookieValue)] = selectedElement.value
          Object.entries(selectedElement.dataset).forEach(([key, value]) => {
            dataset[key] = value
          })

          const masterCookie = this._getMasterCookieElement()
          if (masterCookie) {
            masterCookie.querySelector('button').click()
          } else {
            this._setCookies(dataset)
          }
        }
      }
    }
  }

  /**
   * Listener to submit master cookie's settings modal
   */
  _setMasterCookieListeners () {
    const masterCookieSubmitBtn = this.element.querySelector(`.${classNames.modalSettings}__submit`)
    if (masterCookieSubmitBtn) {
      masterCookieSubmitBtn.addEventListener('click', this._masterCookieListenerFn)
    }
  }

  _isMasterCookie (element) { return (element.id === classNames.masterCookieName) }

  _isCookieSet (id) { return (this.cookie.get(id) != null) }

  _setCookies (data) {
    this.cookie.set(data.cookieName, data[toCamelCase(classNames.cookieValue)], {
      domain: data.cookieDomain,
      path: data.cookiePath,
      expirationDays: parseInt(data.cookieExpirationDays)
    })
  }

  _notCookiesBanner () {
    if (!this.element.contains(document.getElementById(classNames.masterCookieName))) {
      this.element.classList.add(classNames.notFullWidth)
    }
  }
}

registerWidget(CookieEmergencyDialog, widgetApi)
