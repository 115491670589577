import { CUSTOM_DIMENSIONS } from '../constants'

export function mapExtraData (accoData, data) {
  let dataObject = null
  if (accoData && data) {
    dataObject = {
      [CUSTOM_DIMENSIONS.accoId]: accoData.id || '',
      [CUSTOM_DIMENSIONS.accoName]: accoData.name || '',
      [CUSTOM_DIMENSIONS.accoBrand]: data.brand || '',
      [CUSTOM_DIMENSIONS.accoCategory]: accoData.category || '',
      [CUSTOM_DIMENSIONS.accoStars]: accoData.accoStars ? parseFloat(accoData.accoStars) : 0,
      [CUSTOM_DIMENSIONS.transport]: accoData.transport || '',
      [CUSTOM_DIMENSIONS.boardType]: accoData.boardType || '',
      [CUSTOM_DIMENSIONS.duration]: accoData.duration ? accoData.duration : 0,
      [CUSTOM_DIMENSIONS.rating]: accoData.rating ? parseFloat(accoData.rating) : 0,
      [CUSTOM_DIMENSIONS.partyComposition]: data.partyComposition || '',
      [CUSTOM_DIMENSIONS.totalGuests]: data.totalGuests || 0,
      [CUSTOM_DIMENSIONS.departureDate]: data.departureDate || '',
      [CUSTOM_DIMENSIONS.entryPrice]: accoData.price || 0,
      [CUSTOM_DIMENSIONS.totalStepPrice]: data.totalPrice || 0,
      [CUSTOM_DIMENSIONS.mandatoryPayment]: data.mandatoryPayment || false,
      [CUSTOM_DIMENSIONS.campaignId]: data.campaignCode || ''
    }
  }
  return dataObject
}
