export const mapOptionPrice = (data) => {
  const pricePerParticipant = getPricePerParticipant(data)
  return pricePerParticipant?.price !== undefined ? pricePerParticipant.price : data.price || 0
}

export const getQuantity = (data) => {
  const pricePerParticipant = getPricePerParticipant(data)
  return pricePerParticipant?.participantIds ? pricePerParticipant.participantIds.length : 1
}

const getPricePerParticipant = (data) => {
  let pricePerParticipant
  if (data.prices && data.participantIds) {
    pricePerParticipant = data.prices.find(pricePerParticipant => pricePerParticipant.participantIds && pricePerParticipant.participantIds.some(participantId => data.participantIds.includes(participantId))) || null
  }
  return pricePerParticipant
}
