import { FloatingBoxTemplate } from '../../components/floating-box/c-floating-box.template'
import { FloatingBoxHeaderTemplate } from '../../components/floating-box/c-floating-box__header.template'
import { FloatingBoxBodyTemplate } from '../../components/floating-box/c-floating-box__body.template'
import { FloatingBoxFooterTemplate } from '../../components/floating-box/c-floating-box__footer.template'
import { defaultTextboxData, TextboxTemplate } from '../../components/textbox/c-textbox.template'

export const NewLiveSearchFloatingBoxTemplate = d => {
  return `${FloatingBoxTemplate(
    {
      opened: false,
      extraClasses: 'c-dropdown__floating-box',
      attributes: {
        'data-w-new-live-search__floating-box': ''
      }
    },
    Content(d)
  )}`
}

const Content = d => {
  const header = FloatingBoxHeaderTemplate({}, HeaderContent(d))
  const body = FloatingBoxBodyTemplate({}, BodyContent(d))
  const footer = FloatingBoxFooterTemplate({}, FooterContent(d))
  return `${header + body + footer}`
}

const HeaderContent = d => {
  return `<div class="w-new-live-search__autocomplete">  ${TextboxTemplate({
    ...defaultTextboxData,
    placeholder: d.options.placeholder,
    icon: 'search',
    hiddenMessages: true,
    attributes: {
      'data-c-autocomplete__textbox': ''
    }
  })}</div>`
}

const BodyContent = d => {
  return '<div></div>'
}

const FooterContent = d => {
  return `<div class="c_autocomplete__results-footer">
  <button type="button" class="c-btn c-btn--flat-neutral c-autocomplete__cancel" data-c-autocomplete__action="cancel">
    ${d.options.cancelText ? d.options.cancelText : d.locales.cancel}
  </button>
</div>`
}
