import { formatLocations, calculateOccupancy } from './search/search-impressions-mapper'

export const favoritesMapper = (data) => {
  data = { ...data, category: getLocation(data) }

  const dataObject = {
    event: 'favourites',
    ecommerce: {
      page: data.page,
      action: data.action,
      products: {
        id: data.id || '',
        name: data.name || '',
        position: data.position || '',
        accoStarts: data.rating || '',
        badges: data.collection || '',
        rating: data.userRating || '',
        entryPrice: data.price ? data.price.averagePrice || '' : '',
        campaignId: data.price ? data.price.yieldCampaignId || '' : '',
        campaignName: data.price ? data.price.yieldCampaignName || '' : '',
        list: '',
        duration: data.featuredFilters ? data.featuredFilters.duration || '' : '',
        totalGuests: calculateOccupancy(data.occupancyInfo),
        departureDate: data.departureDateDetail || '',
        country: data.countryName || '',
        countryId: data.countryId || '',
        region: data.regionName || '',
        regionId: data.regionId || '',
        city: data.cityName || '',
        cityId: data.cityId || ''
      }
    }
  }
  return dataObject
}

const getLocation = (data) => {
  if (data.location) return data.location
  if (data.countryName && data.countryName && data.countryName) { return formatLocations(data.countryName, data.countryName, data.countryName) }
  return ''
}
