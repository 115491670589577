import { AccordionTemplate } from '../../components/accordion/c-accordion.template'
import { faqTopQuestionsEvents } from '../../../js/document/event-types'

/**
 * The FaqTopQuestionsContentData contains all data needed to hydrate inner content for FaqTopQuestions widget
 *
 * @typedef {Object}                      FaqTopQuestionsContentData
 *
 * @property {string}     templateData.id                                  - The id of the widget.
 * @property {Object[]}   templateData.questions                           - The list of questions.
 * @property {string}     templateData.questions[].title                   - The title of the question, with the question.
 * @property {string}     templateData.questions[].body                    - The body of the question, with the answer.
 */

export const defaultFaqTopQuestionsContentData = {
  id: 'faq-top-questions',
  questions: []
}

export const FaqTopQuestionsContentTemplate = (d) => {
  d = { ...defaultFaqTopQuestionsContentData, ...d }

  return `${(d.questions && d.questions.length > 0)
    ? AccordionTemplate({
      id: `${d.id}__accordion`,
      size: 'small',
      items: d.questions.map((question, index) => {
        return {
          title: question.title,
          id: `${d.id}__accordion__item-${index}`,
          innerHTML: question.body,
          track: faqTopQuestionsEvents.QUESTION_TOGGLED,
          collapseAttributes: {
            'data-question-title': question.title
          }
        }
      })
    })
    : ''
    }`
}
