import storage from '../../../js/document/web-storage'
import { registerWidget } from '../../../js/core/widget/widget-directory'

const widgetApi = 'w-language-modal'

export default class LanguageModal {
  /**
   * Creates a new Language Modal
   *
   * @constructor
   *
   * @param {HTMLElement} element -
   */
  constructor (element) {
    if (!element) { return }
    this.element = element

    this.cookie = storage.cookie
    this.data = this.element.dataset
    this.modal = this.element.querySelector('.c-modal')['c-modal']
    this.buttons = this.modal.element.querySelectorAll('.w-language-modal__language-select-btn')

    this._init()
  }

  _init () {
    if (!this._isCookieSet()) {
      this.modal.open()
      this._setListeners()
    }
  }

  /**
   * Set click listeners on every button in the modal
   */
  _setListeners () {
    this.buttons.forEach(element => element.addEventListener('click', () => {
      this.data.cookieValue = element.dataset.lang
      this._setCookie()
      window.location.href = element.dataset.url
    }))
  }

  /**
   * Checks if the cookie is already set
   */
  _isCookieSet () {
    const cookieValue = this.cookie.get(this.data.cookieName)
    return (cookieValue !== null && cookieValue !== undefined)
  }

  /**
   * Sets the lang cookie to the selected language
   */
  _setCookie () {
    this.cookie.set(this.data.cookieName, this.data.cookieValue, {
      domain: this.data.cookieDomain,
      path: this.data.cookiePath,
      expirationDays: parseInt(this.data.cookieExpirationDays)
    })
  }
}

registerWidget(LanguageModal, widgetApi)
