import { CUSTOM_PARAMETERS } from '../../constants'

export const accommodationMealplansMapper = (data, eventName) => {
  let dataObject = null

  if (data && (eventName === 'open' || eventName === 'close') && data.attr && data.attr.mealplanTitle) {
    const titleOfMealplan = data.attr.mealplanTitle
    const [shownOrHidden, moreOrLess] = (eventName === 'open' ? ['shown', 'more'] : ['hidden', 'less'])

    dataObject = {
      event: 'PDP',
      [CUSTOM_PARAMETERS.parameter_2]: `click accordion for ${moreOrLess} ${titleOfMealplan} info`, // description
      [CUSTOM_PARAMETERS.parameter_3]: 'Accordion', // element
      [CUSTOM_PARAMETERS.parameter_4]: titleOfMealplan, // type
      [CUSTOM_PARAMETERS.parameter_5]: `all ${titleOfMealplan} info ${shownOrHidden}`, // result
      [CUSTOM_PARAMETERS.parameter_6]: true // interaction
    }
  }
  return dataObject
}
