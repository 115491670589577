import { accountBookingsType } from '../types'
import {
  deleteAccountMapper,
  addBookingMapper,
  bookingCardMapper
} from '../mappers/account-bookings/account-bookings-mapper'
import pusher from '../pusher'

export function listenButtonEvents (event, track) {
  const deleteAccountButtonPressedEvent = accountBookingsType.DELETE_ACCOUNT_MODAL_OPEN
  const accountDeletedEvent = accountBookingsType.ACCOUNT_DELETED

  if (track === deleteAccountButtonPressedEvent) {
    event.on('clickButton', () => {
      const mappedData = deleteAccountMapper(deleteAccountButtonPressedEvent)
      pusher.pushDataObject(mappedData)
    })
  }

  if (track === accountDeletedEvent) {
    event.on('clickButton', () => {
      const mappedData = deleteAccountMapper(accountDeletedEvent)
      pusher.pushDataObject(mappedData)
    })
  }
}

export function listenModalEvents (event, track) {
  const addBookingModalOpen = accountBookingsType.ADD_BOOKING_MODAL_OPEN
  const addBookingSucceededModalClose = accountBookingsType.ADD_BOOKING_SUCCEEDED_MODAL_CLOSE
  const addBookingFailedModalClose = accountBookingsType.ADD_BOOKING_FAILED_MODAL_CLOSE

  if (track === addBookingModalOpen) {
    event.on('open', () => {
      const mappedData = addBookingMapper(track)
      pusher.pushDataObject(mappedData)
    })
  }

  if (track === addBookingSucceededModalClose || track === addBookingFailedModalClose) {
    event.on('close', () => {
      const mappedData = addBookingMapper(track)
      pusher.pushDataObject(mappedData)
    })
  }
}

/**
* Listeners for the card events on bookings overview
* @param {Object} event
* @param {track} String
*/
export function listenCardEvents (event, track) {
  const { BOOKING_CARD_CLICKED, PAST_BOOKING_CARD_CLICKED } = accountBookingsType

  if ([BOOKING_CARD_CLICKED, PAST_BOOKING_CARD_CLICKED].includes(track)) {
    event.on('clickCard', element => {
      const elementApi = element['c-card']
      const cardTitle = elementApi.getTitle()
      const mappedData = bookingCardMapper(cardTitle, track)
      pusher.pushDataObject(mappedData)
    })
  }
}
