import * as eventTypes from '../../../../js/document/event-types'
import { profileDataMapper } from '../mappers/profile-data-mapper'
import pusher from '../pusher'

/**
* listener for the collapse events on the profile data
* @param {Object} event
* @param {String} track
*/
export function listenCollapseEvents (event, track) {
  event.on(eventTypes.collapseEvents.OPEN, (data) => {
    const mappedData = profileDataMapper(track, data)
    pusher.pushDataObject(mappedData)
  })
}

/**
* listener for the button events on the profile data
* @param {Object} event
* @param {String} track
*/
export function listenButtonEvents (event, track) {
  event.on('clickButton', (_, data) => {
    const mappedData = profileDataMapper(track, data)
    pusher.pushDataObject(mappedData)
  })
}
