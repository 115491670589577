import pusher from '../pusher'
import * as types from '../types'
import * as eventTypes from '../../../../js/document/event-types'
import { headerNavigationMapper, mainHeaderNavigationMapper } from '../mappers/nav/nav-mapper'
import { navLogoHeaderMapper } from '../mappers/nav/nav-logo-header'

export function listenMainNavigationEvents (event, track) {
  event.on(eventTypes.navigationEvents.CLICK_NAVIGATION, (data) => {
    if (!data.isBackNavigation) {
      const navigationData = mainHeaderNavigationMapper(data, track)
      if (navigationData) {
        pusher.pushDataObject(navigationData)
      }
    }
  })
}

export function listenHeaderButtonEvents (event, track) {
  if (track === types.headerNavigationType.ICON_MOBILE) {
    event.on('clickButton', () => {
      const mappedData = headerNavigationMapper()
      if (mappedData) {
        pusher.pushDataObject(mappedData)
      }
    })
  }
}

export function listenHeaderLogoEvents (event, track) {
  if (track === types.headerNavigationType.LOGO) {
    event.on('click', () => {
      const mappedData = navLogoHeaderMapper()
      if (mappedData) {
        pusher.pushDataObject(mappedData)
      }
    })
  }
}
