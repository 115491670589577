import { ga4Mapper } from '../ga4-mapper'

export const globalLiveSearchMapper = (data) => {
  return ga4Mapper({
    event: 'clickSiteSearch',
    description: 'engagement with site search ',
    element: 'search bar',
    type: data.term,
    result: 'result found',
    interaction: true
  })
}

export const clickLiveSearchResultMapper = (data, callback) => {
  if (!data) return null
  const mappedObj = ga4Mapper({
    event: 'clickSiteSearch',
    description: 'engagement with site search ',
    element: 'search bar',
    type: data.selection.model.attributes.name,
    result: 'click result',
    interaction: true
  })
  mappedObj.eventCallback = () => callback()
  return mappedObj
}

export const noResultsLiveSearchMapper = (data) => {
  return ga4Mapper({
    event: 'clickSiteSearch',
    description: 'engagement with site search ',
    element: 'search bar',
    type: data.term,
    result: 'no result',
    interaction: true
  })
}
