/**
 * The AccoListerContentData contains all data needed to hydrate inner content for AccoLister widget
 *
 * @typedef {Object}                      AccoListerContentData
 *
 * @property {String}                     id                     - The AccoLister id
 * @property {String}                     variant                - The AccoLister variant (default|listed)
 * @property {String}                     acmModalId             - The ACM modal ID
 * @property {String}                     priceLabelText         - The price label text
 * @property {PackageItemData[]}          items                  - The packageItems to be shown
 * @property {Object}                     [sliderAttributes]     - The slider attributes
 */

import { PackageItemTemplate } from '../../components/package-item/c-package-item.template'
import { SliderTemplate } from '../../components/slider/c-slider.template'
import { chunk } from '../../../js/helpers/array'
import { getTokenClass } from '../../../js/helpers/tokens'

// Import tokens
const tokensShared = require('./data/w-acco-lister__tokens.json')['w-acco-lister']
const tokensBrand = (() => {
  try {
    return require(`../../../../brands/${WEBPACK_BRAND}/modules/widgets/acco-lister/data/w-acco-lister__tokens.json`)['w-acco-lister'] // eslint-disable-line
  } catch (error) {
    return {}
  }
})()
const tokens = { ...tokensShared, ...tokensBrand }

const SLIDER_ATTRIBUTES_DEFAULT = {
  'data-loop': false,
  'data-responsive': 'xs:1,sm:1.5,sml:2,md:3,xl:4',
  'data-gutter': '12'
}

const SLIDER_ATTRIBUTES_LISTED = {
  'data-loop': false,
  'data-responsive': 'xs:1,lg:2',
  'data-gutter': '12'
}

const SLIDER_ATTRIBUTES_TIPS = {
  'data-loop': true,
  'data-responsive': 'xs:1,lg:1.5',
  'data-gutter': '12'
}

const ITEMS_PER_SLIDE_LISTED = 3

export const defaultAccoListerContentData = {
  id: '',
  variant: 'default',
  acmModalId: '',
  priceLabelText: '',
  items: [],
  sliderAttributes: {},
  ctaButtonText: '',
  showCtaButton: false,
  track: ''
}

export const AccoListerContentTemplate = (d) => {
  d = { ...defaultAccoListerContentData, ...d }

  const sliderAttributes = {
    ...(d.variant === 'listed')
      ? SLIDER_ATTRIBUTES_LISTED
      : (d.variant === 'tips')
          ? SLIDER_ATTRIBUTES_TIPS
          : SLIDER_ATTRIBUTES_DEFAULT,
    ...d.sliderAttributes
  }

  const sliderItems = (d.variant === 'listed' || d.variant === 'tips')
    ? chunk(d.items, d.packagesPerSlide || ITEMS_PER_SLIDE_LISTED).map(group => (
      group.map(item => (
        PackageItemTemplate({
          ...item,
          variant: 'listed',
          extraClasses: 'w-acco-lister__package-item--listed'
        })
      )).join(''))
    )
    : d.items.map(item => (
      PackageItemTemplate({
        ...item,
        variant: 'default',
        ctaButtonText: d.ctaButtonText,
        showCtaButton: d.showCtaButton,
        track: d.track,
        extraClasses: 'w-acco-lister__package-item'
      }))
    )

  return SliderTemplate({
    id: `${d.id}__slider`,
    items: sliderItems,
    attributes: sliderAttributes,
    barNavigation: false,
    outerNavigation: true,
    track: d.track ? d.track + '.slider' : '',
    withGradient: true,
    extraClasses: 'w-acco-lister__slider',
    luminosity: getTokenClass('luminosity', (d.luminosity ? d.luminosity : 'default'), tokens)
  })
}
