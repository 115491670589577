import { ga4Mapper } from '../ga4-mapper'
import { mapOptionPrice, getQuantity } from '../../helpers/booking-price-helper'

export const bookingChoiceListSelectionMapper = (data, interaction = false) => {
  if (data !== null && data.label && data.label.trim() !== '') {
    return ga4Mapper({
      event: 'Ancillaries',
      description: data.label,
      element: 'radiobutton',
      type: data.text,
      result: mapOptionPrice(data) * getQuantity(data),
      interaction
    })
  }
  return null
}
