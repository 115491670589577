import pusher from '../pusher'
import * as eventTypes from '../../../../js/document/event-types'
import { accountBookedServiceEditMapper } from '../mappers/account-booked-service-edit/account-booked-service-edit-mapper'
import { accountBookedServiceChangeCompleteMapper } from '../mappers/account-booked-service-edit/account-booked-service-change-complete-mapper'
import context from '../context'
import { selfServiceMapper } from '../mappers/booking/booking-services-mapper'
import { serviceAvailableMapper } from '../mappers/service-available-mapper'
import { serviceNotAvailableMapper } from '../mappers/service-not-available-mapper'
import { bookingIdentifierMapper } from '../mappers/shared/booking-identifier-mapper'

export function listenBookedServiceEditEvents (event) {
  event.on(eventTypes.bookedServiceEditEvents.BOOKED_SERVICE_EDIT_FETCHED, (data) => {
    context.addContext('bookingServices', data.services)
    context.addContext('selfserviceBookingDraft', data)
  })

  event.on(eventTypes.bookedServiceEditEvents.BOOKED_SERVICE_EDIT_FETCHED_ITEM_CHANGED, (data) => {
    context.addContext('bookingServices', data.services)
    context.addContext('selfserviceBookingDraft', data)
  })

  event.on(eventTypes.bookedServiceEditEvents.CONDITIONS_VALIDATION_FAILED, data => {
    const mappedData = accountBookedServiceEditMapper(data, eventTypes.bookedServiceEditEvents.CONDITIONS_VALIDATION_FAILED)
    pusher.pushDataObject(mappedData)
  })

  event.on(eventTypes.bookedServiceEditEvents.SAVE_CHANGES_TO_BOOKING_FAILED, data => {
    const mappedData = accountBookedServiceEditMapper(data, eventTypes.bookedServiceEditEvents.SAVE_CHANGES_TO_BOOKING_FAILED)
    pusher.pushDataObject(mappedData)
  })

  event.on(eventTypes.bookedServiceEditEvents.SAVE_CHANGES_TO_BOOKING_SUCCESS, data => {
    const bookingIdentifier = data.draftId ? bookingIdentifierMapper(data.draftId) : null
    const bookingDraft = context.getContext('selfserviceBookingDraft')
    const mappedData = accountBookedServiceChangeCompleteMapper(bookingDraft)
    pusher.pushDataObject({ ...bookingIdentifier, ...mappedData })
  })

  event.on(eventTypes.bookedServiceEditEvents.API_CALL_FAILED, data => {
    const mappedData = accountBookedServiceEditMapper(data, eventTypes.bookedServiceEditEvents.API_CALL_FAILED)
    pusher.pushDataObject(mappedData)
  })

  event.on(eventTypes.bookedServiceEditEvents.ANCILLARY_DATA_CHANGED, data => {
    const servicesData = context.getContext('bookingServices')
    const mappedDataArray = selfServiceMapper(data, servicesData)
    pusher.pushDataObjects(mappedDataArray)
  })

  event.on(eventTypes.bookedServiceEditEvents.BOOKED_SERVICE_EDIT_SERVICE_NOT_AVAILABLE, data => {
    if (data) {
      const mappedData = serviceNotAvailableMapper(data)
      pusher.pushDataObject(mappedData)
    }
  })

  event.on(eventTypes.bookedServiceEditEvents.BOOKED_SERVICE_EDIT_SERVICE_AVAILABLE, (data) => {
    if (data) {
      const mappedData = serviceAvailableMapper(data)
      pusher.pushDataObject(mappedData)
    }
  })
}
