import pusher from '../pusher'
import { preferenceQuestionnaireMapper } from '../mappers/preference-questionnaire-mapper'
import { widgetQueries } from '../../preference-questionnaire/main'
import * as eventTypes from '../../../../js/document/event-types'

/**
* Listeners for the button events on the preference questionnaire
* @param {Object} event
* @param {String} track
*/
export function listenButtonEvents (event, track) {
  event.on('clickButton', (input) => {
    if (input.hasAttribute(widgetQueries.disableTracking)) return
    const isComingThroughMail = input.hasAttribute(widgetQueries.comesTroughEmail)
    const mappedData = preferenceQuestionnaireMapper(track, isComingThroughMail, null)
    pusher.pushDataObject(mappedData)
  })

  event.on(eventTypes.preferenceQuestionnaireEvents.PREFERENCE_QUESTIONNAIRE_EDIT, (data) => {
    const mappedData = preferenceQuestionnaireMapper(eventTypes.preferenceQuestionnaireEvents.PREFERENCE_QUESTIONNAIRE_EDIT, false, data)
    pusher.pushDataObject(mappedData)
  })
}
