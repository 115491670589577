import { ga4Mapper } from '../ga4-mapper'

export const navLogoHeaderMapper = () => {
  return ga4Mapper({
    event: 'navigation',
    description: 'header navigation',
    element: 'navigation bar',
    result: 'logo home',
    interaction: true
  })
}
