import { rebookingOptionsMapper } from '../mappers/rebooking-options-mapper'
import pusher from '../pusher'

/**
* Listeners for the rebooking options widget
* @param {Object} event
*/
export function listenButtonEvents (event) {
  event.on('clickButton', (element) => {
    const textBtn = element['c-btn'].getText()
    const mappedData = rebookingOptionsMapper(textBtn)
    pusher.pushDataObject(mappedData)
  })
}
