import { ga4Mapper } from '../ga4-mapper'

export const mainHeaderNavigationMapper = (data, track) => {
  if (!data && !track) return

  const dataObject = ga4Mapper({
    event: 'navigation',
    description: 'header navigation',
    element: 'main navigation bar',
    result: (data.path || []).join(' > '),
    interaction: true
  })

  return dataObject
}

export const headerNavigationMapper = () => {
  return ga4Mapper({
    event: 'clickHeaderMenu',
    description: 'header navigation',
    element: 'button',
    type: 'person icon',
    result: 'redirect to my account',
    interaction: true
  })
}
