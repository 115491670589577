export const refundRequestClickMapper = (data) => {
  let dataObject = null
  if (data && data.voucherCode) {
    dataObject = {
      event: 'click.refundRequest',
      category: 'refund',
      action: 'click',
      label: data.voucherCode
    }
  }
  return dataObject
}
