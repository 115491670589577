import * as eventTypes from '../../../../js/document/event-types'
import pusher from '../pusher'
import { genericErrorMapper, validationErrorsMapper } from '../mappers/shared/validation-errors-mapper'
import { paymentMethodMapper, voucherSelectedMapper, downPaymentCoveredMapper } from '../mappers/payment-mapper'
import context from '../context'
import { ErrorOrigins } from '../helpers/error-origin-helper'

export function listenPaymentWidgetEvents (event, track) {
  event.on(eventTypes.paymentEvents.VOUCHER_AMOUNT_ERROR, (data) => {
    const mappedData = genericErrorMapper(
      { eventName: 'trackErrorVoucher', field: 'redeem part of the voucher', message: data })
    pusher.pushDataObject(mappedData)
  })

  event.on(eventTypes.paymentEvents.PAYMENT_METHOD_SELECTED, (data) => {
    const bookingContext = context.getContext('booking')
    const mappedData = paymentMethodMapper(data, bookingContext)
    pusher.pushDataObject(mappedData)
  })

  event.on(eventTypes.paymentEvents.VOUCHER_SELECTED, (data) => {
    const bookingContext = context.getContext('booking')
    const mappedData = voucherSelectedMapper(data, bookingContext)
    pusher.pushDataObject(mappedData)
  })

  event.on(eventTypes.paymentEvents.ADD_VOUCHER, (data) => {
    const bookingContext = context.getContext('booking')
    const mappedData = voucherSelectedMapper(data, bookingContext)
    pusher.pushDataObject(mappedData)
  })

  event.on(eventTypes.paymentEvents.DOWN_PAYMENT_COVERED, () => {
    const mappedData = downPaymentCoveredMapper({ element: 'alert', type: 'payment', result: 'pending confirmation', interaction: false })
    pusher.pushDataObject(mappedData)
  })
}

export function listenFormComponentEvents (event, track) {
  event.on('validated', (data) => {
    const mappedData = validationErrorsMapper(data, track, 'trackErrorVoucher', ErrorOrigins.CLIENT_SIDE_VALIDATION_ERROR)
    pusher.pushDataObjects(mappedData)
  })
}

export function listenButtonEvents (event) {
  event.on('clickButton', (element) => {
    const textBtn = element['c-btn'].getText()
    const mappedData = downPaymentCoveredMapper({ element: 'button', type: textBtn, result: 'booking confirmed', interaction: true })
    pusher.pushDataObject(mappedData)
  })
}
