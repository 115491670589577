class Context {
  constructor () {
    this.contexts = {}

    if (!Context.instance) {
      Context.instance = this
    }
    return Context.instance
  }

  addContext (context, data) {
    if (!data) return
    this.contexts[context] = data
  }

  getContext (context) {
    return this.contexts[context] || {}
  }
}

const context = new Context()
Object.freeze(context)
export default context
