import { filtersSections, formatRange, getDatalayerName, useValue } from '../../helpers/filters-config-convention-transform'
import { ga4Mapper } from '../ga4-mapper'
import { search } from '../../../search/event-types'

export const globalSearchMapper = (filters) => {
  const dataObjects = []
  let dataObject = {}
  if (filters) {
    let dataObjectKey = null
    filters.forEach(v => {
      dataObjectKey = getDatalayerName(v.type, filtersSections.SEARCH)
      if (dataObjectKey) {
        dataObject[dataObjectKey] = formatRange(v.type, useValue(v.type, filtersSections.SEARCH) ? v.values : v.captions, filtersSections.SEARCH)
      }
    })

    if (dataObject && (Object.entries(dataObject).length !== 0 && dataObject.constructor === Object)) {
      dataObject = { event: 'filterCollection', ...dataObject }
      dataObjects.push(dataObject)
    }
  }

  return dataObjects
}

export const searchModifiersMapper = (data) => {
  let values = {}
  if (data.event === search.SEARCH_PAGINATION_CHANGE) {
    values = {
      event: 'paginationSearch',
      description: 'pagination of product listing',
      element: 'number',
      type: 'result page'
    }
  } else if (data.event === search.SEARCH_SORT_CHANGE) {
    values = {
      event: 'sortingSearch',
      description: 'sort product listing',
      element: 'dropdown',
      type: 'sorting on'
    }
  }

  return ga4Mapper({
    ...values,
    result: data.value,
    interaction: true
  })
}
export const searchMapMapper = (data) => {
  return ga4Mapper({
    ...data,
    description: 'engagement with map',
    element: 'button',
    interaction: true
  })
}

export const searchErrorMapper = (data) => {
  const dataObject = {
    event: 'trackErrorMessage',
    eventAction: data.isError ? 'An error has occured' : 'No results found',
    eventLabel: data.search
  }
  return dataObject
}
