import * as eventTypes from '../../../../js/document/event-types'
import { validationErrorMapper } from '../mappers/shared/validation-errors-mapper'
import { bookingChoiceListSelectionMapper } from '../mappers/booking/booking-choice-list-selection-mapper'
import { bookingChoiceListSwitchMapper } from '../mappers/booking/booking-choice-list-switch-mapper'
import { bookingComparisonTableSelectionMapper } from '../mappers/booking/booking-comparison-table-selection-mapper'
import { bookingLuggageSwitchMapper } from '../mappers/booking/booking-luggage-switch-mapper'
import { bookingParticipantsBadgeMapper } from '../mappers/booking/booking-participants-badge-mapper'
import { bookingParticipantsFormFieldValidationErrorMapper } from '../mappers/booking/booking-participants-form-mapper'
import { bookingDraftMapper, bookingPurchaseMapper } from '../mappers/booking/booking-draft-mapper'
import { bookingIdentifierMapper } from '../mappers/shared/booking-identifier-mapper'
import context from '../context'
import pusher from '../pusher'
import { checkoutMapper } from '../mappers/booking/booking-services-mapper'
import { bookingErrorMapper, bookingErrorModalButtonMapper } from '../mappers/booking/booking-error-mapper'
import {
  bookingCarRentalEquipmentsMapper,
  bookingCarRentalCreditCardMapper,
  bookingCarRentalButtonsMapper,
  bookingCarRentalCollapseMapper,
  bookingCarRentalSliderButtonMapper
} from '../mappers/booking/booking-car-rental-mapper'
import {
  bookingSkiServiceAccordionMapper,
  bookingSkiServiceSwitchMapper,
  bookingSkiServiceCancelAndSaveButtonMapper,
  bookingSkiServiceFilterSportMapper,
  bookingSkiServiceFilterDurationMapper,
  bookingSkiServiceFilterMaterialCategoryMapper,
  bookingSkiServiceFilterSportLevelMapper,
  bookingSkiServiceChoiceListMapper
} from '../mappers/booking/booking-filterable-services-mapper'
import { serviceAvailableMapper } from '../mappers/service-available-mapper'
import {
  bookingOptionalAncillariesPopupBackToAncillariesMapper, bookingOptionalAncillariesPopupCloseEscMapper,
  bookingOptionalAncillariesPopupCloseMapper,
  bookingOptionalAncillariesPopupCloseOutsideMapper,
  bookingOptionalAncillariesPopupContinueBookingMapper,
  bookingOptionalAncillariesPopupShownMapper
} from '../mappers/booking/booking-optional-ancillaries-popup-mapper'
import { collapseMapper } from '../mappers/booking/booking-box-mapper'
import { bookingPreferencesMapper } from '../mappers/booking/booking-preferences-mapper'

export function listenChoiceListEvents (event) {
  event.on(eventTypes.choiceListEvents.CHOICE_LIST_VALIDATION_ERROR, (data) => {
    const mappedData = validationErrorMapper(data)
    pusher.pushDataObject(mappedData)
  })
}

export function listenBookingErrorEvents (event, track) {
  event.on(eventTypes.bookingStepsEvents.ERROR_MESSAGE, (data) => {
    const mappedData = bookingErrorMapper(data)
    pusher.pushDataObject(mappedData)
  })

  event.on('clickButton', () => {
    const mappedData = bookingErrorModalButtonMapper(track)
    pusher.pushDataObject(mappedData)
  })
}

export function listenBookingChoiceListEvents (event) {
  event.on(eventTypes.bookingItemEvents.BOOKING_ITEM_DATA_CHANGED, (data) => {
    if (data.rawData) {
      const mappedData = bookingChoiceListSelectionMapper(data.rawData, true)
      pusher.pushDataObject(mappedData)
    }
  })

  event.on(eventTypes.bookingStepsEvents.BOOKING_ITEM_INITIAL_VALUES, (data) => {
    const mappedData = bookingChoiceListSelectionMapper(data)
    pusher.pushDataObject(mappedData)
  })

  event.on(eventTypes.bookingChoiceListEvents.CHOOSE_PER_PERSON_SWITCH, (data) => {
    const mappedData = bookingChoiceListSwitchMapper(data)
    pusher.pushDataObject(mappedData)
  })

  listenBookingItemEvents(event)
}

export function listenBookingComparisonTableEvents (event) {
  event.on(eventTypes.bookingItemEvents.BOOKING_ITEM_DATA_CHANGED, (data) => {
    if (data.rawData) {
      const mappedData = bookingComparisonTableSelectionMapper(data.rawData, true)
      pusher.pushDataObject(mappedData)
    }
  })

  listenBookingItemEvents(event)
}

export function listenBookingLuggageEvents (event) {
  event.on(eventTypes.bookingLuggageEvents.CHOOSE_PER_PERSON_SWITCH, (data) => {
    console.log(data)
    const mappedData = bookingLuggageSwitchMapper(data)
    pusher.pushDataObject(mappedData)
  })

  listenBookingItemEvents(event)
}

export function listenBookingPreferencesEvents (event) {
  event.on('click', (input) => {
    if (input) {
      const mappedData = bookingPreferencesMapper(input)
      pusher.pushDataObject(mappedData)
    }
  })
}

export function listenBookingParticipantsBadgeEvents (event) {
  event.on(eventTypes.bookingItemEvents.BOOKING_ITEM_DATA_CHANGED, (data) => {
    const mappedData = bookingParticipantsBadgeMapper(data)
    pusher.pushDataObject(mappedData)
  })

  listenBookingItemEvents(event)
}

export function listenBookingCarRentalEvents (event) {
  event.on(eventTypes.bookingCarRentalEvents.EQUIPMENTS_CHANGED, (data) => {
    const mappedData = bookingCarRentalEquipmentsMapper(data)
    pusher.pushDataObject(mappedData)
  })

  event.on(eventTypes.bookingCarRentalEvents.CREDIT_CARD_CHANGED, (data) => {
    const mappedData = bookingCarRentalCreditCardMapper(data)
    pusher.pushDataObject(mappedData)
  })

  event.on(eventTypes.bookingCarRentalEvents.SLIDER_BUTTON_CLICKED, (data) => {
    const mappedData = bookingCarRentalSliderButtonMapper(data)
    pusher.pushDataObject(mappedData)
  })

  listenBookingItemEvents(event)
}

export function listenBookingFilterableServiceSkiEvents (event) {
  event.on(eventTypes.bookingFilterableServicesEvents.BOOKING_FILTERABLE_SERVICES_CONTAINER_TOGGLE, (data) => {
    const mappedData = bookingSkiServiceAccordionMapper(data)
    pusher.pushDataObject(mappedData)
  })
  event.on(eventTypes.bookingFilterableServicesEvents.BOOKING_FILTERABLE_SERVICES_SWITCH_TOGGLE, (data) => {
    const mappedData = bookingSkiServiceSwitchMapper(data)
    pusher.pushDataObject(mappedData)
  })
  event.on(eventTypes.bookingFilterableServicesEvents.BOOKING_FILTERABLE_SERVICES_CANCEL_BUTTON, (data) => {
    const mappedData = bookingSkiServiceCancelAndSaveButtonMapper(data)
    pusher.pushDataObject(mappedData)
  })
  event.on(eventTypes.bookingFilterableServicesEvents.BOOKING_FILTERABLE_SERVICES_SAVE_BUTTON, (data) => {
    const mappedData = bookingSkiServiceCancelAndSaveButtonMapper(data)
    pusher.pushDataObject(mappedData)
  })
  event.on(eventTypes.bookingFilterableServicesEvents.BOOKING_FILTERABLE_SERVICES_FILTER_SPORT, (data) => {
    const mappedData = bookingSkiServiceFilterSportMapper(data)
    pusher.pushDataObject(mappedData)
  })
  event.on(eventTypes.bookingFilterableServicesEvents.BOOKING_FILTERABLE_SERVICES_FILTER_DURATION, (data) => {
    const mappedData = bookingSkiServiceFilterDurationMapper(data)
    pusher.pushDataObject(mappedData)
  })
  event.on(eventTypes.bookingFilterableServicesEvents.BOOKING_FILTERABLE_SERVICES_FILTER_MATERIAL_CATEGORY, (data) => {
    const mappedData = bookingSkiServiceFilterMaterialCategoryMapper(data)
    pusher.pushDataObject(mappedData)
  })
  event.on(eventTypes.bookingFilterableServicesEvents.BOOKING_FILTERABLE_SERVICES_FILTER_SPORT_LEVEL, (data) => {
    const mappedData = bookingSkiServiceFilterSportLevelMapper(data)
    pusher.pushDataObject(mappedData)
  })
  event.on(eventTypes.bookingFilterableServicesEvents.BOOKING_FILTERABLE_SERVICES_CHOICE_LIST, (data) => {
    const mappedData = bookingSkiServiceChoiceListMapper(data)
    pusher.pushDataObject(mappedData)
  })
  listenBookingItemEvents(event)
}

export function listenBookingItemEvents (event) {
  event.on(eventTypes.bookingItemEvents.BOOKING_ITEM_SERVICE_AVAILABLE, (data) => {
    if (data) {
      const mappedData = serviceAvailableMapper(data)
      pusher.pushDataObject(mappedData)
    }
  })
  listenBookingBoxEvents(event)
}

export function listenBookingBoxEvents (event) {
  event.on(eventTypes.bookingBoxEvents.COLLAPSE_OPEN, (data) => {
    const mappedData = collapseMapper(data, eventTypes.bookingBoxEvents.COLLAPSE_OPEN)
    pusher.pushDataObject(mappedData)
  })

  event.on(eventTypes.bookingBoxEvents.COLLAPSE_CLOSE, (data) => {
    const mappedData = collapseMapper(data, eventTypes.bookingBoxEvents.COLLAPSE_CLOSE)
    pusher.pushDataObject(mappedData)
  })
}

export function listenBookingOptionalAncillariesPopup (event) {
  event.on('open', () => {
    const mappedData = bookingOptionalAncillariesPopupShownMapper()
    pusher.pushDataObject(mappedData)
  })
  event.on('clickButton', () => {
    const mappedData = bookingOptionalAncillariesPopupCloseMapper()
    pusher.pushDataObject(mappedData)
  })
  event.on('backdropClicked', () => {
    const mappedData = bookingOptionalAncillariesPopupCloseOutsideMapper()
    pusher.pushDataObject(mappedData)
  })
  event.on('EscClicked', () => {
    const mappedData = bookingOptionalAncillariesPopupCloseEscMapper()
    pusher.pushDataObject(mappedData)
  })
  event.on(eventTypes.bookingOptionalAncillariesPopupEvents.CLOSE_BUTTON_CLICKED, () => {
    const mappedData = bookingOptionalAncillariesPopupBackToAncillariesMapper()
    pusher.pushDataObject(mappedData)
  })
  event.on(eventTypes.bookingOptionalAncillariesPopupEvents.ACCEPT_BUTTON_CLICKED, () => {
    const mappedData = bookingOptionalAncillariesPopupContinueBookingMapper()
    pusher.pushDataObject(mappedData)
  })
}

export function listenBookingStepsWidgetEvents (event) {
  event.on(eventTypes.bookingStepsEvents.BOOKING_ITEM_BOOKINGDRAFT_FETCHED, (data) => {
    context.addContext('booking', data.eCommerceCheckout)
    context.addContext('bookingServices', data.services)
    let mappedData = null
    const bookingIdentifier = data.draftId ? bookingIdentifierMapper(data.draftId) : null
    // step data
    if (data.eCommerceCheckout) {
      mappedData = bookingDraftMapper(data.eCommerceCheckout)
      pusher.pushDataObject({ ...bookingIdentifier, ...mappedData })
    }

    // transaction complete data
    if (data.eCommerceAnalytics && data.eCommerceCheckout && data.isJustConfirmed) {
      const bookingStepsData = { eCommerceAnalytics: data.eCommerceAnalytics, eCommerceCheckout: data.eCommerceCheckout }
      mappedData = bookingPurchaseMapper(bookingStepsData)
      pusher.registerNewrelicEvent(mappedData)
      pusher.pushDataObject({ ...bookingIdentifier, ...mappedData })
    }
  })

  event.on(eventTypes.bookingStepsEvents.BOOKINGDRAFT_FETCHED_AFTER_ITEM_CHANGED, (data) => {
    context.addContext('bookingServices', data.services)
  })

  event.on(eventTypes.bookingStepsEvents.DATA_CHANGED, (data) => {
    try {
      const bookingContext = context.getContext('booking')
      const servicesContext = context.getContext('bookingServices')
      const mappedDataArray = checkoutMapper(data, bookingContext, servicesContext)
      mappedDataArray.map(d => pusher.pushDataObject(d))
    } catch (error) {}
  })
}

/**
* listener for the button events on the profile data
* @param {Object} event
* @param {String} track
*/
export function listenCarRentalButtonEvents (event, track) {
  event.on('clickButton', (_, data) => {
    const mappedData = bookingCarRentalButtonsMapper(track, data)
    pusher.pushDataObject(mappedData)
  })
}

export function listenCollapseEvents (event) {
  event.on(eventTypes.collapseEvents.OPEN, (data) => {
    const mappedData = bookingCarRentalCollapseMapper(data, eventTypes.collapseEvents.OPEN)
    pusher.pushDataObject(mappedData)
  })

  event.on(eventTypes.collapseEvents.CLOSE, (data) => {
    const mappedData = bookingCarRentalCollapseMapper(data, eventTypes.collapseEvents.CLOSE)
    pusher.pushDataObject(mappedData)
  })
}

export function listenBookingParticipantsFormEvents (event) {
  event.on(eventTypes.bookingParticipantsFormEvents.ITEM_FORM_VALIDATON_ERROR, (data) => {
    const mappedData = bookingParticipantsFormFieldValidationErrorMapper(data)
    pusher.pushDataObject(mappedData)
  })

  listenBookingBoxEvents(event)
}
