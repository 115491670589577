import * as eventTypes from '../../../../../js/document/event-types'
import { CUSTOM_PARAMETERS } from '../../constants'

export const accommodationTabsMapper = (data, eventName) => {
  let dataObject = null
  const eventData = getEventData(data, eventName)

  if (eventData && eventData.result && eventData.result !== '' && eventData.interaction !== null) {
    dataObject = {
      event: 'PDP',
      [CUSTOM_PARAMETERS.parameter_2]: 'Accommodation tab', // description
      [CUSTOM_PARAMETERS.parameter_3]: 'tab', // element
      [CUSTOM_PARAMETERS.parameter_5]: eventData.result, // result
      [CUSTOM_PARAMETERS.parameter_6]: eventData.interaction // interaction
    }
  }
  return dataObject
}

/**
* Get the tab result data depending on the event received
* @param {Object} data
* @param {String} eventName
* @returns {Object}
*/
export const getEventData = (data, eventName) => {
  if (data && data.buttonData && data.buttonData.result && data.buttonData.result !== '') {
    switch (eventName) {
      case eventTypes.tabsEvents.CLICKED :
        return { result: data.buttonData.result, interaction: true }

      case eventTypes.tabsEvents.LOADED :
        return { result: data.buttonData.result + '_default', interaction: false }

      default:
        return {}
    }
  }
}
