export const filterSelectedData = (data, useValue = false) => {
  const listOfSelected = []
  data.filter(c => c.checked === true && c.disabled === false)
    .map(c => listOfSelected.push(useValue ? c.value : c.text))
  data
    .forEach(c => {
      if (c.items) {
        c.items.filter(i => i.checked === true && i.disabled === false)
          .map(it => listOfSelected.push(useValue ? it.value : it.text))
      }
    })
  return listOfSelected
}

export const autocompleteSelectedData = (data) => {
  if (data && data.model && data.model.attributes) {
    return [data.model.attributes.caption]
  }
  return []
}

export const dropdownSimpleSelectedData = (data, useValue = false) => {
  return data.filter(c => c.selected === true && c.disabled === false)
    .map(c => useValue ? c.value : c.text)
}
