import { fetchJsonData } from '../../../js/helpers/json-fetch'
import { getUrlFromString } from '../../../js/document/url'
import { observerAPI, documentObserver } from '../../../js/document/intersector'
import { registerWidget } from '../../../js/core/widget/widget-directory'

const widgetApi = 'w-dynamic-label'

const widgetQueries = {
  url: `data-${widgetApi}__url`,
  extraParams: 'input[type="hidden"]'
}

export default class DynamicLabel {
  /**
   * Creates a new DynamicLabel
   *
   * @constructor
   *
   * @param {HTMLElement} element - The HTML widget element
   */
  constructor (element) {
    this.element = element
    this.label = this.element.querySelector('.c-label')
    this.loader = this.element.querySelector('.c-loader')

    this.url = this.element.getAttribute(widgetQueries.url)
    this.extraParams = this.getExtraParameters()

    const observer = documentObserver()
    observer.observe(this.element)
    this.element[observerAPI].events.on('enter', () => {
      this.getData()
        .then(freshData => this.render(freshData))
        .catch(e => { this.clearLabel() })
      observer.unobserve(this.element)
    })
  }

  async clearLabel () {
    this.element.innerHTML = ''
  }

  /**
   * Fetches data from the API and returns data
   *
   * @return {String} data
   */
  async getData () {
    const fetchUrl = getUrlFromString(this.url, this.extraParams)
    return fetchJsonData(fetchUrl).then((result) => {
      return result.data
    })
  }

  /**
   * Renders a dynamic label
   *
   * @param {String} data - The data
   */
  async render (data) {
    if (data && data.length && data[0] !== '') {
      this.label.innerHTML += data[0]
      this.element.removeChild(this.loader)
    } else {
      this.clearLabel()
    }
  }

  /**
   * Returns the extra parameters that are exposed in the DOM
   *
   * @returns {object} - Returns the extra parameters object
   */
  getExtraParameters () {
    const extraParamsElements = this.element.querySelectorAll(widgetQueries.extraParams)
    return extraParamsElements
      ? [...extraParamsElements].reduce((obj, el) => {
          obj[el.name] = el.value
          return obj
        }, {})
      : undefined
  }
}

registerWidget(DynamicLabel, widgetApi)
