export const da = {
  navigationNavText: 'Afgang',
  durationInDaysText: 'dage',
  durationInNightsText: 'nætter'
}

export const de = {
  navigationNavText: 'Abfahrt',
  durationInDaysText: 'tage',
  durationInNightsText: 'nächte'
}

export const en = {
  navigationNavText: 'Departure',
  durationInDaysText: 'days',
  durationInNightsText: 'nights'
}

export const nb = {
  navigationNavText: 'Avgang',
  durationInDaysText: 'dagger',
  durationInNightsText: 'netter'
}

export const fr = {
  navigationNavText: 'Départ',
  durationInDaysText: 'journées',
  durationInNightsText: 'nuits'
}

export const nl = {
  navigationNavText: 'Vertrek',
  durationInDaysText: 'dagen',
  durationInNightsText: 'nachten'
}

export const sv = {
  navigationNavText: 'Avresa',
  durationInDaysText: 'dagar',
  durationInNightsText: 'nätter'
}
