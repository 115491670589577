import { registerWidget } from '../../../js/core/widget/widget-directory'
import { apiCaller } from '../../../js/helpers/api-caller'

const classNames = {
  widgetApi: 'w-ce-login'
}

const urlParamNames = {
  user: 'usr',
  pass: 'pas'
}

export const attr = {
  ceLoginRequestUrl: `data-${classNames.widgetApi}__request-url`
}

export default class CeLogin {
  /**
  * Creates a new CE Login widget.
  * @constructor
  * @param {HTMLElement} element The HTML element.
  */
  constructor (element) {
    this.element = element
    let requestUrl
    const loginParams = this._getCeLoginCredentialsFromUrl()
    if (loginParams) requestUrl = this._getRequestUrl()
    if (requestUrl) this._isValidCeUser(requestUrl, loginParams)
  }

  /**
  * Returns the user name and the pass if the url has usr and pas parameters(CE login)
  * @return {Object | null}
  */
  _getCeLoginCredentialsFromUrl () {
    const queryString = window.location.search
    const urlParams = new URLSearchParams(queryString)
    const userParam = urlParams.get(urlParamNames.user)
    const passParam = urlParams.get(urlParamNames.pass)
    if (userParam && passParam) {
      return { userParam, passParam }
    } else {
      return null
    }
  }

  /**
  * Returns the request url to know if the user is a valid CE Login user
  * @return {String | null}
  */
  _getRequestUrl () {
    const mainUrl = this.element.getAttribute(attr.ceLoginRequestUrl)
    return mainUrl || null
  }

  /**
  * Called if is a valid CE  User
  * @param {String} requestUrl
  * @param {{userParam: String, passParam:String }} loginParams
  */
  async _isValidCeUser (requestUrl, loginParams) {
    const result = await apiCaller(requestUrl, { method: 'POST', body: loginParams })
    if (result.success && result.response.isValidCeUser) this._removeUrlParams()
  }

  /**
  * Remove the user and pass parameters from the url
  */
  _removeUrlParams () {
    const url = new URL(window.location.href)
    url.searchParams.delete(urlParamNames.user)
    url.searchParams.delete(urlParamNames.pass)
    window.location.replace(url)
  }
}

registerWidget(CeLogin, classNames.widgetApi)
