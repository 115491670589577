import { BoxTemplate } from '../../components/box/c-box.template'
import { getTokenClass } from '../../../js/helpers/tokens'

/**
 * The PreferenceSummaryQuestionData contains all data needed to hydrate a PreferenceSummaryQuestionTemplate
 *
 * @typedef {Object}            PreferenceSummaryQuestionData
 *
 * @property {String}           index                             - index of each question
 * @property {String}           titleIcon                         - title icon for each question
 * @property {String}           titleText                         - title for each question
 * @property {String}           editBtnText                       - text of the edit btn
 * @property {Array}            answers                           - answers for each question
 * @property {String}           noAnswerText                      - text when there is no answers
 * @property {String}           noAnswerBtn                       - Button text for when there is no answers
 */

export const defaultPreferenceSummaryQuestionData = {
  index: '',
  titleIcon: '',
  titleText: '',
  editBtnText: '',
  answers: null,
  noAnswerText: '',
  noAnswerBtn: ''
}

// Import tokens
const tokensShared = require('./data/w-preference-questionnaire__tokens.json')['w-preference-questionnaire']
const tokensBrand = (() => {
  try {
    return require(`../../../../brands/${WEBPACK_BRAND}/modules/widgets/preference-questionnaire/data/w-preference-questionnaire__tokens.json`)['w-preference-questionnaire'] // eslint-disable-line
  } catch (error) {
    return {}
  }
})()
const tokens = { ...tokensShared, ...tokensBrand }

export const PreferenceSummaryQuestionTemplate = (d) => {
  d = { ...defaultPreferenceSummaryQuestionData, ...d }
  return `
    ${BoxTemplate({
      variant: 'flat',
      extraClasses: 'cs-default cs-default--light w-preference-questionnaire__summary-question',
      html: `
        <div class="o-bullet w-preference-questionnaire__summary-question-title-container">
          <div class="o-bullet w-preference-questionnaire__summary-question-title">
            <span class="o-bullet__icon m-icon m-icon--size-large m-icon--${d.titleIcon}"></span>
            <h2 class="o-bullet__text m-heading ${getTokenClass('question-summary-title__size', 'default', tokens)}">${d.titleText}</h2>
          </div>
          ${d.editBtnText
            ? `<a class="w-preference-questionnaire__questionnaire-edit u-ml--small" data-w-preference-questionnaire__summary-question-edit=${d.index}>
              <span class="m-icon m-icon--pencil w-preference-questionnaire__questionnaire__questionnaire-edit-icon"></span>
              <span class="w-preference-questionnaire__questionnaire-edit-text">${d.editBtnText}</span>
            </a>`
          : ''}
        </div>
      ${d.answers.length
        ? `<div class="w-preference-questionnaire__summary-question-answers">
          ${d.answers.map(answer => `
            <div class="o-bullet w-preference-questionnaire__summary-question-answer">
              <span class="o-bullet__icon m-icon m-icon--checkmark"></span>
              <p class="o-bullet__text u-mb--none">${answer}</p>
            </div>
          `).join('')}
        </div>`
        : `<p class="u-mb--none">
          ${d.noAnswerText}
          <a class="w-preference-questionnaire__questionnaire-edit--hover u-ml--tiny" data-w-preference-questionnaire__summary-question-edit=${d.index} href>${d.noAnswerBtn}</a>
        </p>`
      }
      `
    })}
  `
}
