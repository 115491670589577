class Pusher {
  constructor () {
    this.dataObjectsBuffer = []
    this.GTMLoadedHandler = GTMLoadedHandler.bind(this)

    if (!Pusher.instance) {
      Pusher.instance = this
    }
    return Pusher.instance
  }

  pushDataObjects (dataObjects) {
    dataObjects.forEach(dataObject => {
      this.pushDataObject(dataObject)
    })
  }

  pushDataObject (dataObject) {
    if (!dataObject) return
    if (window.google_tag_manager) {
      window.dataLayer.push(dataObject)
    } else {
      window.addEventListener('gtm_loaded', this.GTMLoadedHandler)
      this.dataObjectsBuffer.push(dataObject)
    }
  }

  registerNewrelicEvent (dataObject) {
    if (!dataObject || !dataObject.event) return
    if (window.newrelic) {
      window.newrelic.noticeError(`[${dataObject.event}] event from datalayer`)
    }
  }
}

function GTMLoadedHandler () {
  window.removeEventListener('gtm_loaded', this.GTMLoadedHandler, false)
  this.dataObjectsBuffer.forEach(d => {
    window.dataLayer.push(d)
  })
}

const pusher = new Pusher()
Object.freeze(pusher)
export default pusher
