import pusher from '../pusher'
import { isEmptyObject } from '../../../../js/helpers/object'
import * as eventTypes from '../../../../js/document/event-types'
import { globalLiveSearchMapper, clickLiveSearchResultMapper, noResultsLiveSearchMapper } from '../mappers/live-search/live-search-mapper'

export function listenLiveSearchEvents (event) {
  event.on(eventTypes.liveSearchEvents.SUBMIT, (data) => {
    if (!isEmptyObject(window.google_tag_manager)) {
      const clickEvent = clickLiveSearchResultMapper(data, data.callback)
      pusher.pushDataObject(clickEvent)
    } else {
      data.callback()
    }
  })

  event.on(eventTypes.autocompleteEvents.INPUT_CHANGED, (data) => {
    const mappedData = globalLiveSearchMapper(data)
    pusher.pushDataObject(mappedData)
  })

  event.on(eventTypes.autocompleteEvents.NO_RESULTS, (data) => {
    const mappedData = noResultsLiveSearchMapper(data)
    pusher.pushDataObject(mappedData)
  })
}
