import { getDatesDifference, isValidDateObject } from '../../../../../js/helpers/dates'
import { ga4Mapper } from '../ga4-mapper'

export const mapMarkerMapper = (data) => {
  if (data !== null && data.markerType && data.markerType.trim() !== '') {
    let dataDescription = ''
    let dataType = ''
    if (data.markerType === 'center') {
      dataDescription = 'accomodation marker click on enlarged map'
      dataType = 'orginal acco'
    } else if (data.markerType === 'nearby') {
      dataDescription = 'nearby accomodation marker click on enlarged map'
      dataType = 'alternative acco'
    }

    const breadcrumbs = data.breadcrumbs.map(breadcrumb => breadcrumb.text).join(' > ')

    return ga4Mapper({
      event: 'MAP',
      description: dataDescription,
      element: 'enlarged map',
      type: dataType,
      result: data.titleText,
      interaction: true
    }, {
      accoCategory: breadcrumbs
    })
  }
  return null
}

export const mapOpenedMapper = (data) => {
  if (data !== null && data.productName && data.productName.trim() !== '') {
    const productBreadcrumbs = data.productBreadcrumbs.map(breadcrumb => breadcrumb.text).join(' > ')

    return ga4Mapper({
      event: 'MAP',
      description: 'Click on map thumpnail to view accomodation on enlarged map',
      element: 'Thumbnail',
      type: 'map',
      result: data.productName,
      interaction: true
    }, {
      accoCategory: productBreadcrumbs
    })
  }
  return null
}

export const mapClosedMapper = (data) => {
  if (data !== null && data.mapOpenDate !== null && isValidDateObject(data.mapOpenDate) && data.mapClosedDate !== null && isValidDateObject(data.mapClosedDate)) {
    const timeSpentSeconds = getDatesDifference(data.mapOpenDate, data.mapClosedDate, 'seconds')

    return ga4Mapper({
      event: 'MAP',
      description: 'the total time the enlarged map was open',
      element: 'enlarged map',
      type: 'timer',
      result: timeSpentSeconds,
      interaction: false
    })
  }
  return null
}
