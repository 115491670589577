import { CUSTOM_PARAMETERS } from '../../constants'

export const bookingParticipantsBadgeMapper = (data) => {
  let dataObject = null
  if (data) {
    dataObject = mapParticipantsBadgeSaveButton(data.body)
  }
  return dataObject
}

const mapParticipantsBadgeSaveButton = (body) => {
  if (!body) return null
  const description = body.participants && body.participants.some(participant => !!(participant.birthdate))
    ? 'Save participants badge form with birthdate'
    : 'Save participants badge form'
  return {
    event: 'Participants Badge',
    [CUSTOM_PARAMETERS.parameter_1]: true, // validated
    [CUSTOM_PARAMETERS.parameter_2]: description, // description
    [CUSTOM_PARAMETERS.parameter_3]: 'button', // element
    [CUSTOM_PARAMETERS.parameter_5]: 'save', // result
    [CUSTOM_PARAMETERS.parameter_6]: true // interaction
  }
}
