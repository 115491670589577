import { accommodationRoomtypesType as events } from '../../types'
import { CUSTOM_PARAMETERS } from '../../constants'

export const accommodationRoomtypesCarouselMapper = (element, track) => {
  let dataObject = null
  const dataElement = getElementData(element, track)

  dataObject = {
    event: 'PDP',
    roomtype: dataElement.roomTitle,
    hasimage: dataElement.hasImage,
    [CUSTOM_PARAMETERS.parameter_2]: 'Interaction with roomtypes carousel', // description
    [CUSTOM_PARAMETERS.parameter_3]: 'Button', // element
    [CUSTOM_PARAMETERS.parameter_4]: dataElement.btnLabel, // type
    [CUSTOM_PARAMETERS.parameter_5]: dataElement.result, // result
    [CUSTOM_PARAMETERS.parameter_6]: true // interaction
  }
  return dataObject
}

export const getElementData = (element, track) => {
  const dataElement = {
    btnLabel: element['c-btn'].getText(),
    roomTitle: element.getAttribute('data-room-title'),
    hasImage: element.getAttribute('data-has-image'),
    result: null
  }

  switch (track) {
    case events.ROOMTYPES_MODAL_OPENED:
      dataElement.result = 'View room description in pop-up'
      break

    case events.ROOMTYPES_MODAL_SEE_PRICES:
      dataElement.result = 'View prices on price table from modal'
      break

    case events.ROOMTYPES_SEE_PRICES:
      dataElement.result = 'View prices on price table'
      break
  }

  return dataElement
}
