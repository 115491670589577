import { CUSTOM_PARAMETERS } from '../../constants'

export const accommodationDescriptionMapper = (data, eventName) => {
  let dataObject = null

  if (data && (eventName === 'open' || eventName === 'close')) {
    dataObject = {
      event: 'PDP',
      [CUSTOM_PARAMETERS.parameter_2]:
        eventName === 'open'
          ? 'Show more text about acco info'
          : eventName === 'close'
            ? 'Show less text about acco info'
            : null, // description
      [CUSTOM_PARAMETERS.parameter_3]: 'Clickable text', // element
      [CUSTOM_PARAMETERS.parameter_4]:
        eventName === 'open'
          ? 'show more'
          : eventName === 'close'
            ? 'show less'
            : null, // type
      [CUSTOM_PARAMETERS.parameter_5]:
        eventName === 'open'
          ? 'all acco info shown'
          : eventName === 'close'
            ? 'all acco info hidden'
            : null, // result
      [CUSTOM_PARAMETERS.parameter_6]: true // interaction
    }
  }
  return dataObject
}
