export const da = {
  openJawSwitchTitle: 'Flere lufthavne',
  openJawSwitchDescription: 'Flyrejser, hvor retur- og afgangslufthavne er forskellige'
}

export const de = {
  openJawSwitchTitle: 'Mehrere Flughäfen',
  openJawSwitchDescription: 'Flüge, bei denen die Hin- und Rückflughäfen unterschiedlich sind'
}

export const en = {
  openJawSwitchTitle: 'Multiple airports',
  openJawSwitchDescription: 'Flights where the return and departure airports are different'
}

export const nb = {
  openJawSwitchTitle: 'Flere flyplasser',
  openJawSwitchDescription: 'Flyreiser der retur- og avgangsflyplassene er forskjellige'
}

export const fr = {
  openJawSwitchTitle: 'Plusieurs aéroports',
  openJawSwitchDescription: 'Vols où les aéroports de retour et de départ sont différents'
}

export const nl = {
  openJawSwitchTitle: 'Meerdere luchthavens',
  openJawSwitchDescription: 'Vluchten waarbij de retour- en vertrekluchthavens verschillend zijn'
}

export const sv = {
  openJawSwitchTitle: 'Flera flygplatser',
  openJawSwitchDescription: 'Flyg där retur- och avgångsflygplatserna är olika'
}
