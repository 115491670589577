import { CUSTOM_PARAMETERS } from '../../constants'

export const accommodationTabsGoBackBtnMapper = () => {
  const dataObject = {
    event: 'PDP',
    [CUSTOM_PARAMETERS.parameter_2]: 'Back from pricetable to PDP overview', // description
    [CUSTOM_PARAMETERS.parameter_3]: 'button', // element
    [CUSTOM_PARAMETERS.parameter_4]: 'Go back to overview', // type
    [CUSTOM_PARAMETERS.parameter_5]: 'PDP overview', // result
    [CUSTOM_PARAMETERS.parameter_6]: true // interaction
  }
  return dataObject
}
