import pusher from '../pusher'
import * as eventTypes from '../../../../js/document/event-types'
import { reviewsListMapper } from '../mappers/reviews-list/reviews-list-mapper'
import { reviewsListLanguageSwitchMapper } from '../mappers/reviews-list/reviews-list-language-switch-mapper'
import { reviewsListPaginationMapper } from '../mappers/reviews-list/reviews-list-pagination-mapper'
import { reviewsListPartyCompositionMapper } from '../mappers/reviews-list/reviews-list-party-composition-mapper'
import { reviewsListSortingMapper } from '../mappers/reviews-list/reviews-list-sorting-mapper'

export function listenReviewsListEvents (event) {
  event.on(eventTypes.reviewsListEvents.REVIEWS_LIST_REVIEW_TRANSLATED, (data) => {
    const mappedData = reviewsListMapper(data)
    pusher.pushDataObject(mappedData)
  })

  event.on(eventTypes.reviewsListEvents.REVIEWS_LIST_LANGUAGE_SWITCH, (data) => {
    const mappedData = reviewsListLanguageSwitchMapper(data)
    pusher.pushDataObject(mappedData)
  })

  event.on(eventTypes.reviewsListEvents.REVIEWS_LIST_PAGINATION, (data) => {
    const mappedData = reviewsListPaginationMapper(data)
    pusher.pushDataObject(mappedData)
  })
}

export function listenDropdownMultipleEvents (event) {
  event.on('submit', (data) => {
    const mappedData = reviewsListPartyCompositionMapper(data)
    pusher.pushDataObject(mappedData)
  })
}

export function listenDropdownEvents (event) {
  event.on('change', (data) => {
    const mappedData = reviewsListSortingMapper(data)
    pusher.pushDataObject(mappedData)
  })
}
