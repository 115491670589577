import pusher from '../pusher'
import { ga4Mapper } from '../mappers/ga4-mapper'

export function listenFormComponentEvents (event) {
  event.on('submitted', () => {
    const data = ga4Mapper({
      event: 'manageAccount',
      description: 'login info',
      element: 'button',
      type: 'create',
      result: 'success',
      interaction: true
    })
    pusher.pushDataObject(data)
  })
}
