import Autocomplete from '../../components/autocomplete/main'
import { registerWidget } from '../../../js/core/widget/widget-directory'
import { getAbsoluteUrl } from '../../../js/document/url'
import { liveSearchEvents } from '../../../js/document/event-types'
import { elementFromString } from '../../../js/document/html-helper'
import Component from '../../../js/core/component/component'
import { NewLiveSearchFloatingBoxTemplate } from './new-live-search_floating-box.template'
import { CategoryBlock } from '../live-search/category-block.template'
import { register } from '../../../js/document/namespace'
import { language } from '../../../js/user/locale-settings'
import {
  CATEGORIES,
  setCategoryTitles,
  orderCategories,
  addCategoryIfNotAlreadyExisting
} from '../live-search/categories'

const widgetApi = 'w-new-live-search'

const originListParameter = 'originList=live-search'

const widgetQueries = {
  urlAttr: `data-${widgetApi}__url`,
  urlSearchParamAttr: `data-${widgetApi}__url-search-param`,
  useDestinationsLayoutAttr: `data-${widgetApi}__use-destinations-layout`,
  filterAndSortAttr: `data-${widgetApi}__filter-and-sort`,
  extraParams: 'input[type="hidden"]',
  showAllText: `data-${widgetApi}__show-all-text`,
  showLessText: `data-${widgetApi}__show-less-text`,
  showMoreText: `data-${widgetApi}__show-more-text`,
  cancelText: `data-${widgetApi}__cancel-text`,
  placeholder: `data-${widgetApi}__placeholder`,
  newWidgetClass: 'c-autocomplete--live-search_new',
  asyncSearchMinChars: `data-${widgetApi}__minimum-characters`,
  btn: '[data-js-component="c-btn"]',
  floatingBox: '[data-w-new-live-search__floating-box]',
  textBox: '.w-new-live-search__autocomplete'
}

const attr = {
  track: 'data-track'
}

const defaults = {
  hiddenClassName: 'is-hidden'
}

const globalLocales = register(`window.sundio.i18n.${language}.global`)
const componentLocales = register(`window.sundio.i18n.${language}.autocomplete`)

export default class NewLiveSearch extends Autocomplete {
  constructor (element, options = {}) {
    const extraParamsElements = element.querySelectorAll(widgetQueries.extraParams)
    const extraParams = extraParamsElements
      ? [...extraParamsElements].reduce((obj, el) => {
          obj[el.name] = el.value
          return obj
        }, {})
      : undefined
    const asyncSearchMinChars = element.getAttribute(widgetQueries.asyncSearchMinChars)
    const autocompleteOptions = {
      ...{
        url: element.getAttribute(widgetQueries.urlAttr),
        urlSearchParam: element.getAttribute(widgetQueries.urlSearchParamAttr),
        urlParams: extraParams,
        searchAttributes: ['name'],
        displayAttributes: ['name'],
        emptyShownResults: false,
        cache: false,
        disableTextBoxOnBlur: true,
        asyncSearchMinChars: asyncSearchMinChars > 0 ? asyncSearchMinChars : 1,
        delay: 300,
        placeholder: element.getAttribute(widgetQueries.placeholder)
      },
      ...defaults,
      ...options
    }

    const _getLocales = element => {
      const customLocaleElement = document.querySelector(`[data-type="i18n"][data-uid="${element.id}"]`)
      let customLocaleData = null
      try {
        customLocaleData = JSON.parse(customLocaleElement.textContent)
      } catch (err) {}

      return { ...globalLocales, ...componentLocales, ...(customLocaleData || {}) }
    }

    const locales = _getLocales(element)
    element.appendChild(elementFromString(NewLiveSearchFloatingBoxTemplate({ options: autocompleteOptions, locales })))

    Component.initDocumentComponentsFromAPI(element)
    Component.initComponentActionElements(element)

    const autocompleteElement = element.querySelector(widgetQueries.textBox)

    super(autocompleteElement, autocompleteOptions)
    this.locales = locales
    this.btn = element.querySelector(widgetQueries.btn)
    this.btn.addEventListener('click', ev => this.toggle(ev))
    this.floatingBox = element.querySelector(widgetQueries.floatingBox)
    this.floatingBoxApi = this.floatingBox['c-floating-box']

    this.widgetElement = element
    this.options.useDestinationsLayout = this._parseBooleanAttribute(
      element,
      widgetQueries.useDestinationsLayoutAttr,
      true
    )
    this.options.filterAndSort = this._parseBooleanAttribute(element, widgetQueries.filterAndSortAttr, true)
    this.options.showAllText = element.getAttribute(widgetQueries.showAllText)
    this.options.showLessText = element.getAttribute(widgetQueries.showLessText)
    this.options.showMoreText = element.getAttribute(widgetQueries.showMoreText)
    this.options.cancelText = element.getAttribute(widgetQueries.cancelText)
    this.options.attr = attr

    this.categories = []
    this.siteTypes = []
    setCategoryTitles(this.options, element)

    element.classList.add(widgetQueries.newWidgetClass)

    this.events.on('submit', selection => {
      if (selection && selection.model && selection.model.attributes && selection.model.attributes.url) {
        this.clear()
        const url = getAbsoluteUrl(selection.model.attributes.url)
        const urlWithOrigin = url + (url.includes('?') ? '&' : '?') + originListParameter
        if (!element.hasAttribute(attr.track)) {
          window.location.href = urlWithOrigin
        } else {
          this.events.emit(liveSearchEvents.SUBMIT, {
            selection,
            targetUrl: url,
            callback: () => {
              window.location.href = urlWithOrigin
            }
          })
        }
        this.updateInputValueFromSelection({})
      }
    })

    document.querySelectorAll(`[data-w-live-search__id="${element.id}"]`).forEach(e => {
      if (e.matches('[data-w-live-search__action="force-open"]')) {
        e.addEventListener('click', e => {
          this.textBoxInput.focus()
          this.updateResults()
        })
      }
    })

    this.floatingBox
      .querySelector('[data-c-autocomplete__action="cancel"]')
      .addEventListener('click', ev => this.close())
  }

  toggle (ev) {
    if (this.opened) {
      this.close()
    } else {
      this.open()
    }
    ev.preventDefault()
    ev.stopPropagation()
  }

  close () {
    if (!this.opened) return this
    this.floatingBoxApi.setProp('opened', false)
    this.opened = false
    this.events.emit('close')
    document.removeEventListener('mouseup', ev => this._handleDomCLick(ev))
    return this
  }

  open () {
    if (this.opened) return this
    this.floatingBoxApi.setProp('opened', true)
    document.addEventListener('mouseup', ev => this._handleDomCLick(ev))
    this.opened = true
    this.textBoxInput.focus()
    return this
  }

  clear () {
    this.close()
    this.detachEvents()
    this.removeResultsElement()
  }

  onFocus () {
    if (!this.currentSearch) {
      this.currentSearch = this.newEmptySearch()
      this.tempSelection = { ...this.selection }
    }
    this.events.emit('focus')
    this.updateResults()
  }

  updateResults () {
    this.resultsElement = this.floatingBox.querySelector('.c-floating-box__body-content')

    let htmlContent = ''

    if (this.tempSelection.text === '') {
      this.removeResultsElement()
      return
    }

    if (this.asyncSearchInProgress) {
      htmlContent = this.getSearchingResultsHtml()
    } else if (this.currentSearch.resultsShown.length > 0) {
      htmlContent = this.getResultsHtml()
    } else {
      htmlContent = this.getNoResultsHtml()
    }

    this.updateResultsElement(htmlContent)
  }

  getResultsHtml () {
    const html = this._getResultsHtml()
    return html
  }

  updateResultsElement (htmlContent) {
    this.resultsElement.innerHTML = htmlContent
    Component.initDocumentComponentsFromAPI(this.resultsElement)
    Component.initComponentActionElements(this.resultsElement)
    this.detachEvents()
    this.attachEvents(this.resultsElement)
  }

  processFreshData (data) {
    const results = []
    this.options.hasMoreResults = data.hasMoreResults ? data.hasMoreResults : false

    data.results.forEach(result => {
      if (result.searchUrl) {
        results.push({ ...result, url: result.searchUrl, category: CATEGORIES.SearchPage })
        addCategoryIfNotAlreadyExisting(CATEGORIES.SearchPage, result.siteType, this.categories, this.siteTypes)
      }
      const category = CATEGORIES[result.type]
      addCategoryIfNotAlreadyExisting(category, result.siteType, this.categories, this.siteTypes)
      results.push({ ...result, url: result.pageUrl, category })
    })

    orderCategories(this.categories)

    return results
  }

  _getResultsHtml () {
    return `
    <div class="c-autocomplete__results-wrapper">
    <div class="c-autocomplete__results" >
    ${this.categories
      .map(
        category =>
          `${CategoryBlock(
            category,
            this.currentSearch.resultsShown,
            this.currentSearch.termRegExp,
            this.siteTypes,
            this.options
          )}`
      )
      .join('')}    
      <div class="c_autocomplete__results-footer">
    <button type="button" class="c-btn c-btn--flat-neutral c-autocomplete__cancel" data-c-autocomplete__action="cancel">
      ${this.options.cancelText ? this.options.cancelText : this.locales.cancel}
      </button>
      </div>
      </div>
    </div>
    `
  }

  _handleDomCLick (ev) {
    const el = ev.target
    const shouldCloseFloatingBox =
      el.closest(`.${widgetQueries.newWidgetClass}`) === null || el.classList.contains('c-textbox-element')
    if (shouldCloseFloatingBox) this.close()
  }

  _parseBooleanAttribute (element, attrName, defaultValue) {
    let attrValue = element.getAttribute(attrName)
    if (!attrValue) {
      return defaultValue
    }
    attrValue = attrValue.toLowerCase()
    const value = attrValue === 'true' ? true : attrValue === 'false' ? false : defaultValue

    return value
  }

  removeResultsElement () {
    if (this.resultsElement) {
      this.resultsElement.innerHTML = ''
    }
  }
}

registerWidget(NewLiveSearch, widgetApi)
