import { registerWidget } from '../../../js/core/widget/widget-directory'
import registeredEvents from '../../../js/helpers/registered-events'
import { getProfileData } from '../account-data/account-data'
import { loginStatusEvents } from '../../../js/document/event-types'

const EventEmitter = require('eventemitter3')

const widgetApi = 'w-login-status'

const attr = {
  track: 'data-track'
}

export default class LoginStatus {
  constructor (element) {
    if (!element) return
    this.element = element
    this.events = new EventEmitter()
    registeredEvents.registerWidgetEvents(widgetApi, this.events, {
      ...this.element.hasAttribute(attr.track) && { track: this.element.attributes[attr.track].value }
    })
    const profileData = getProfileData()
    this.events.emit(loginStatusEvents.LOADED, profileData)
  }
}

registerWidget(LoginStatus, widgetApi)
