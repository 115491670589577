import { registerWidget } from '../../../js/core/widget/widget-directory'
import { apiCaller } from '../../../js/helpers/api-caller'
import { DropdownTemplate } from '../../components/dropdown/c-dropdown.template'
import { getUrlFromString } from '../../../js/document/url'
import Component from '../../../js/core/component/component'

const widgetApi = 'w-dropdown-redirect-group'
const dropdownApi = 'c-dropdown'

const widgetQueries = {
  dropdownItem: `data-${widgetApi}__dropdown`,
  dropdownItemRendered: `data-${widgetApi}__dropdown--rendered`,
  apiUrl: `data-${widgetApi}__api-url`,
  method: `data-${widgetApi}__method`,
  dropdownWrapper: `data-${widgetApi}__wrapper`,
  dropdownWrapperRendered: `data-${widgetApi}__wrapper--rendered`,
  group: `data-${widgetApi}__group`,
  extraParams: `data-${widgetApi}__extra-parameter`,
  dropdownJs: `data-js-component="${dropdownApi}"`
}

export default class DropdownRedirectGroup {
  /**
   * Creates a new DropdownRedirectGroup
   *
   * @constructor
   *
   * @param {HTMLElement} element - The element where to attach DropdownRedirectGroup
   */
  constructor (element) {
    this.element = element
    this.elementId = this.element.id
    this.extraParams = this.getExtraParameters()
    this.dropdownItems = element.querySelectorAll(`[${widgetQueries.dropdownItem}]`)
    this.dropdownItemsProcessed = []
    this.dropdownWrapper = element.querySelector(`[${widgetQueries.dropdownWrapper}]`)
    this.dropdownWrapperRendered = element.querySelector(`[${widgetQueries.dropdownWrapperRendered}]`)
    this.options = {
      method: this.element.getAttribute(widgetQueries.method),
      url: this.element.getAttribute(widgetQueries.apiUrl)
    }

    this._processItems()
    this._renderDropdowns()
  }

  _processItems () {
    this.dropdownItems.forEach(item => {
      const processedItem = {
        itemPlaceholder: item[dropdownApi].getPlaceholder(),
        itemLabel: item[dropdownApi].getLabel(),
        itemGroup: item.getAttribute(widgetQueries.group)
      }
      this.dropdownItemsProcessed.push(processedItem)
    })
  }

  async _renderDropdowns () {
    try {
      const fetchUrl = getUrlFromString(this.options.url, this.extraParams)
      const result = await apiCaller(fetchUrl, { method: this.options.method })

      if (result.success) {
        this.dropdownItemsProcessed.forEach(item => {
          result.response.groupItems.forEach(responseItem => {
            if (item.itemGroup === responseItem.group) {
              const dropdownData = {
                id: this.element.id + '__dropdown--' + item.itemGroup,
                variant: 'compact',
                label: item.itemLabel,
                placeholder: item.itemPlaceholder,
                options: responseItem.options.map(option => ({
                  selected: option.isSelected,
                  text: option.name,
                  value: option.url
                }))
              }
              this.dropdownWrapperRendered.innerHTML += DropdownTemplate(dropdownData)
            }
          })
        })

        const renderedDropdowns = this.dropdownWrapperRendered.querySelectorAll(`[${widgetQueries.dropdownJs}]`)
        renderedDropdowns.forEach(element => {
          Component.initDocumentComponentsFromAPI(element)

          element[dropdownApi].events.on('propChanged', (changes) => {
            if (changes.name === 'value') {
              const selectedValue = element[dropdownApi].getValue() || ''
              if (selectedValue) window.location.href = selectedValue
            }
          })
        })

        this.dropdownWrapper.classList.add('is-hidden')
        this.dropdownWrapperRendered.classList.add('rendered')
      }
    } catch (error) {
      if (window.newrelic) {
        window.newrelic.noticeError('[DropdownRedirectGroup] Error with:' + error)
      }
    }
  }

  /**
   * Returns the extra parameters that are exposed in the DOM
   *
   * @returns {string} - Returns the api url
   */
  getExtraParameters () {
    const extraParamsElements = this.element.querySelectorAll(`[${widgetQueries.extraParams}]`)
    const extraParams = extraParamsElements
      ? [...extraParamsElements].reduce((obj, el) => {
          obj[el.name] = el.value
          return obj
        }, {})
      : undefined

    return extraParams
  }
}

registerWidget(DropdownRedirectGroup, widgetApi)
