import * as eventTypes from '../../../../js/document/event-types'
import {
  questionToogleMapper
} from '../mappers/faq/faq-top-questions-mapper'
import pusher from '../pusher'

export function listenCollapseEvents (event, track) {
  event.on(eventTypes.collapseEvents.OPEN, (data) => {
    const mappedData = questionToogleMapper(data, eventTypes.collapseEvents.OPEN)
    pusher.pushDataObject(mappedData)
  })

  event.on(eventTypes.collapseEvents.CLOSE, (data) => {
    const mappedData = questionToogleMapper(data, eventTypes.collapseEvents.CLOSE)
    pusher.pushDataObject(mappedData)
  })
}
