import * as eventTypes from '../../../../js/document/event-types'
import { autocompleteSelectedData } from '../helpers/basic-components-tranformation-helper'
import { filtersSections, formatParticipantsData, formatDataFromChipFilters, filterAndMapSelectedData, filterAndMapSelectedDropdownData } from '../helpers/filters-config-convention-transform'
import { clickFilterMapper } from '../mappers/filters-mapper'
import pusher from '../pusher'

export function listenDropdownMultipleEvents (event, track) {
  event.on('submit', (data) => {
    data = filterAndMapSelectedData(data, track, filtersSections.QUICK_SEARCH)
    const mappedData = clickFilterMapper(track, data, filtersSections.QUICK_SEARCH)
    pusher.pushDataObject(mappedData)
  })
}

export function listenDestinationMultipleEvents (event, track) {
  event.on('submit', (data) => {
    data = formatDataFromChipFilters(data)
    const mappedData = clickFilterMapper(track, data, filtersSections.QUICK_SEARCH)
    pusher.pushDataObject(mappedData)
  })
}

export function listenDropdownEvents (event, track) {
  event.on('changedOptions', (data) => {
    data = filterAndMapSelectedDropdownData(data, track, filtersSections.QUICK_SEARCH)
    const mappedData = clickFilterMapper(track, data, filtersSections.QUICK_SEARCH)
    pusher.pushDataObject(mappedData)
  })
}

export function listenAutocompleteEvents (event, track) {
  event.on('submit', (data) => {
    data = autocompleteSelectedData(data)
    const mappedData = clickFilterMapper(track, data, filtersSections.QUICK_SEARCH)
    pusher.pushDataObject(mappedData)
  })
}

export function listenDateFilterWidgetEvents (event, track) {
  event.on(eventTypes.datesFilterEvents.DATES_FILTERS_APPLIED, (data) => {
    const mappedData = clickFilterMapper(track, data, filtersSections.QUICK_SEARCH)
    pusher.pushDataObject(mappedData)
  })
}

export function listenParticipantsFilterWidgetEvents (event, track) {
  event.on(eventTypes.participantsFilterEvents.PARTICIPANTS_FILTER__FILTER_APPLIED, (data) => {
    data = formatParticipantsData(data)
    const mappedData = clickFilterMapper(track, data, filtersSections.QUICK_SEARCH)
    pusher.pushDataObject(mappedData)
  })
}
