export const openFullCancellationConditionsMapper = (data) => {
  let dataObject = null
  if (data) {
    dataObject = {
      ...{
        event: 'openFullCancellation'
      },
      ...mapCommonData(data, 'full', 'open')
    }
  }
  return dataObject
}

export const confirmFullCancellationMapper = (data) => {
  let dataObject = null
  if (data) {
    dataObject = {
      ...{
        event: 'confirmFullCancellation',
        eventValue: data.amount || 0
      },
      ...mapCommonData(data, 'full', 'confirm')
    }
  }
  return dataObject
}

export const openPartialCancellationConditionsMapper = (data) => {
  let dataObject = null
  if (data) {
    dataObject = {
      ...{
        event: 'openPartialCancellation'
      },
      ...mapCommonData(data, 'partial', 'open')
    }
  }
  return dataObject
}

export const confirmPartialCancellationMapper = (data) => {
  let dataObject = null
  if (data) {
    dataObject = {
      ...{
        event: 'confirmPartialCancellation',
        eventValue: data.participantsNum || 0
      },
      ...mapCommonData(data, 'partial', 'confirm')
    }
  }
  return dataObject
}

function mapCommonData (data, type, actionPrefix) {
  return {
    eventCategory: 'cancellation',
    eventAction: `${actionPrefix} ${type} cancellation${(data.isSuccess
      ? (data.isWithCost !== undefined ? (data.isWithCost ? ' with cost' : ' without cost') : '')
      : ' failed')}`,
    eventLabel: data.bookingNumber || ''
  }
}
