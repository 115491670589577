import { registerWidget } from '../../../js/core/widget/widget-directory'
import { elementFromString } from '../../../js/document/html-helper'
import { CeProfile } from '../ce-profile/ce-profile'

const widgetApi = 'w-ce-welcome-msg'

const textVar = {
  partnerName: '{PartnerName}',
  partnerDiscount: '{PartnerDiscount}'
}

export const widgetQueries = {
  settings: `[data-${widgetApi}__settings]`
}

export default class CeWelcomeMsg {
  constructor (element) {
    this.element = element
    this._getHtmlElements()
    this.settingsData = this._getSettingsDataFromDom()
    if (this.settingsData) this._showCeWelcomeMsg()
  }

  _getHtmlElements () {
    this.settings = this.element.querySelector(widgetQueries.settings)
  }

  _getSettingsDataFromDom () {
    if (!this.settings) return false
    return JSON.parse(this.settings.textContent)
  }

  _renderWelcomeMsg (partnerDiscount, partnerName) {
    if (!this.settingsData.title || !this.settingsData.body) return
    const title = this._replaceTextVars(this.settingsData.title, partnerName, partnerDiscount)
    const body = this._replaceTextVars(this.settingsData.body, partnerName, partnerDiscount)
    const ceWelcomeMsgHtml = ceWelcomeMsgTemplate(title, body)
    const html = elementFromString(ceWelcomeMsgHtml)
    this.element.appendChild(html)
  }

  _replaceTextVars (str, partnerName, partnerDiscount) {
    let replacedStr = str.replaceAll(textVar.partnerName, partnerName)
    replacedStr = replacedStr.replaceAll(textVar.partnerDiscount, partnerDiscount)
    return replacedStr
  }

  _showCeWelcomeMsg () {
    const partner = new CeProfile().getPartnerData(this.settingsData.publicationCode)
    if (!partner) return false
    this._renderWelcomeMsg(partner.partnerDiscount, partner.partnerName)
  }
}

registerWidget(CeWelcomeMsg, widgetApi)

const ceWelcomeMsgTemplate = (title, body) => {
  return `
    <div class="o-wrapper o-wrapper--from@xs o-wrapper--to@sm">
      <div class="u-align--center sp-stack-children--medium">
        <div>
          <i class="${widgetApi}__icon m-icon m-icon--spark m-icon--size-giant"></i>
        </div>
        <div class="m-heading m-heading--medium">${title}</div>
        ${body}
      </div>
    </div>`
}
