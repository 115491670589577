export function getDiscount (discounts, publicationCode) {
  function sortDesc (a, b) {
    return b.discount - a.discount
  }

  function sortAsc (a, b) {
    return a.discount - b.discount
  }

  if (discounts && discounts.length > 0) {
    let filteredAndSorted
    if (publicationCode) {
      filteredAndSorted = discounts
        .filter(d => d?.publicationCode === publicationCode)
        .sort(sortDesc)
    } else {
      const filteredDiscounts = discounts.filter(item => item.discount !== 0)
      if (!filteredDiscounts.length) return null
      filteredAndSorted = filteredDiscounts.sort(sortAsc)
    }

    return filteredAndSorted.length > 0
      ? filteredAndSorted[0]?.discount
      : null
  }
  return null
}
