import { CUSTOM_PARAMETERS } from '../../constants'
import { accommodationGalleryType as events } from '../../types'

export const accommodationGallerySliderMapper = eventName => {
  let dataObject = null

  if (
    eventName === events.ACCOMMODATION_GALLERY ||
    eventName === events.ACCOMMODATION_GALLERY_OPEN
  ) {
    dataObject = {
      event: 'PDP',
      [CUSTOM_PARAMETERS.parameter_2]:
        eventName === events.ACCOMMODATION_GALLERY
          ? 'image gallery - interaction'
          : eventName === events.ACCOMMODATION_GALLERY_OPEN
            ? 'image gallery opened - interaction'
            : null, // description
      [CUSTOM_PARAMETERS.parameter_3]: 'Photo gallery', // element
      [CUSTOM_PARAMETERS.parameter_4]:
        eventName === events.ACCOMMODATION_GALLERY
          ? 'modal not opened'
          : eventName === events.ACCOMMODATION_GALLERY_OPEN
            ? 'modal opened'
            : null, // type
      [CUSTOM_PARAMETERS.parameter_5]: 'Clicked on arrow or Swiped',
      [CUSTOM_PARAMETERS.parameter_6]: true // interaction
    }
  }
  return dataObject
}
