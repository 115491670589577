import { ga4Mapper } from '../ga4-mapper'

export const reviewsListSortingMapper = (data) => {
  let dataObject = null

  if (data) {
    dataObject = ga4Mapper({
      event: 'reviews',
      description: 'sort reviews',
      element: 'dropdown',
      type: window.navigator.language || window.navigator.userLanguage, // type,
      result: data,
      interaction: true
    })
  }

  return dataObject
}
