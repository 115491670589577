import * as eventTypes from '../../../../js/document/event-types'
import { accommodationTabsMapper } from '../mappers/accommodation-tabs/accommodationn-tabs-mapper'
import { accommodationTabsGoBackBtnMapper } from '../mappers/accommodation-tabs/accommodationn-tabs-go-back-btn-mapper'
import pusher from '../pusher'

export function listenTabsEvents (event, track) {
  event.on(eventTypes.tabsEvents.CLICKED, (data) => {
    const mappedData = accommodationTabsMapper(data, eventTypes.tabsEvents.CLICKED, track)
    pusher.pushDataObject(mappedData)
  })

  event.on(eventTypes.tabsEvents.LOADED, (data) => {
    const mappedData = accommodationTabsMapper(data, eventTypes.tabsEvents.LOADED, track)
    pusher.pushDataObject(mappedData)
  })
}
export function listenBtnGoBackEvents (event) {
  event.on('clickButton', () => {
    const mappedData = accommodationTabsGoBackBtnMapper()
    pusher.pushDataObject(mappedData)
  })
}
