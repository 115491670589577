import { reviewsTeaserTranslateButtonMapper } from '../mappers/reviews-teaser/reviews-teaser-translate-button-mapper'
import { reviewsTeaserClosePopupMapper } from '../mappers/reviews-teaser/reviews-teaser-close-popup-mapper'
import { reviewsTeaserOpenPopupMapper } from '../mappers/reviews-teaser/reviews-teaser-open-popup-mapper'
import { reviewsTeaserShowMoreMapper } from '../mappers/reviews-teaser/reviews-teaser-show-more-mapper'
import { reviewsTeaserType as events } from '../types'
import * as eventTypes from '../../../../js/document/event-types'
import pusher from '../pusher'

export function listenButtonEvents (event, track) {
  event.on('clickButton', data => {
    if (
      track === events.REVIEWS_TEASER_TRANSLATIONS ||
      track === events.REVIEWS_TEASER_ORIGINAL
    ) {
      const mappedData = reviewsTeaserTranslateButtonMapper(data, track)
      pusher.pushDataObject(mappedData)
    }
  })
}

export function listenReviewsTeaserPopup (event) {
  event.on('close', () => {
    const mappedData = reviewsTeaserClosePopupMapper()
    pusher.pushDataObject(mappedData)
  })
}

export function listenCollapseEvents (event) {
  event.on(eventTypes.collapseEvents.OPEN, () => {
    const mappedData = reviewsTeaserShowMoreMapper(
      eventTypes.collapseEvents.OPEN
    )
    pusher.pushDataObject(mappedData)
  })

  event.on(eventTypes.collapseEvents.CLOSE, () => {
    const mappedData = reviewsTeaserShowMoreMapper(
      eventTypes.collapseEvents.CLOSE
    )
    pusher.pushDataObject(mappedData)
  })
}

export function listenReviewsTeaserEvents (event) {
  event.on(eventTypes.reviewsTeaserEvents.REVIEWS_TEASER_MODAL_OPENED, (data) => {
    const mappedData = reviewsTeaserOpenPopupMapper(data)
    pusher.pushDataObject(mappedData)
  })
}
