import { ga4Mapper } from '../ga4-mapper'
import { bookingBoxEvents } from '../../../../../js/document/event-types'
import { toCamelCase } from '../../../../../js/helpers/string'

export const collapseMapper = (data, eventName) => {
  const allowedEvents = [bookingBoxEvents.COLLAPSE_OPEN, bookingBoxEvents.COLLAPSE_CLOSE]
  const allowedBoxStates = ['default', 'selected']

  if (data && data.boxState && data.serviceName && allowedEvents.includes(eventName) && allowedBoxStates.includes(data.boxState)) {
    const boxStateDescription = (data.boxState === 'default' ? 'unselected ancillary' : 'already selected ancillary')

    const description = `${(eventName === bookingBoxEvents.COLLAPSE_OPEN ? 'open' : 'close')} ${boxStateDescription}`
    const result = `${boxStateDescription} ${(eventName === bookingBoxEvents.COLLAPSE_OPEN ? 'opened' : 'closed')}`

    return ga4Mapper({
      event: 'ancillaryengagement',
      description,
      element: 'collapse',
      type: toCamelCase(data.serviceName),
      result,
      interaction: true
    })
  }
  return null
}
