import { registerWidget } from '../../../js/core/widget/widget-directory'
import registeredEvents from '../../../../shared/js/helpers/registered-events'
import { getEventFunction } from './listeners'

const widgetApi = 'w-data-layer'

export default class DataLayer {
  /**
   * Creates a new Datalayer
   *
   * @constructor
   *
   * @param {HTMLElement} element - The HTML widget element.
   * @param {{}} [options={}] - Options object
   *
   */
  constructor (element, options = {}) {
    this.element = element

    this.options = {
      ...{},
      ...options
    }

    this._bind(registeredEvents.components, 'componentEventsRegistered')
    this._bind(registeredEvents.widgets, 'widgetEventsRegistered')
  }

  _bind (elements, eventName) {
    this._bindQueuedElements(elements)
    this._bindEvent(eventName)
  }

  _bindQueuedElements (elements) {
    Object.entries(elements).forEach(([name, itemsArray]) => {
      itemsArray.forEach(item => {
        if (item.options.track) {
          const listenEvents = getEventFunction(name, item.options.track)
          if (listenEvents) {
            listenEvents(item.events, item.options.track)
          }
        }
      })
    })
  }

  _bindEvent (eventName) {
    registeredEvents.events.on(eventName, (data) => {
      if (data.options.track) {
        const listenEvents = getEventFunction(data.name, data.options.track)
        if (listenEvents) {
          listenEvents(data.events, data.options.track)
        }
      }
    })
  }
}

registerWidget(DataLayer, widgetApi)
