import { CUSTOM_DIMENSIONS } from '../../constants'

export const accountBookedServiceChangeCompleteMapper = (data) => {
  let dataObject = null
  if (data?.eCommerceAnalytics && data?.eCommerceCheckout) {
    dataObject = {
      event: 'ancillaryChangeComplete',
      ecommerce: data.eCommerceAnalytics.ecommerce,
      ...mapCommonData(data.eCommerceCheckout)
    }
  }
  return dataObject
}

function mapCommonData (data) {
  return {
    [CUSTOM_DIMENSIONS.accoId]: data.accoId || '',
    [CUSTOM_DIMENSIONS.accoBrand]: data.brand || '',
    [CUSTOM_DIMENSIONS.partyComposition]: data.partyComposition || '',
    [CUSTOM_DIMENSIONS.totalGuests]: data.totalGuests || 0,
    [CUSTOM_DIMENSIONS.departureDate]: data.departureDate || '',
    [CUSTOM_DIMENSIONS.mandatoryPayment]: data.mandatoryPayment || false
  }
}
