import { ChoiceListTemplate } from '../../components/choice-list/c-choice-list.template'

/**
 * The PreferenceQuestionData contains all data needed to hydrate a PreferenceQuestionTemplate
 *
 * @typedef {Object}            PreferenceQuestionData
 *
 * @property {Bool}             allAnswersHasIllustrations        - all the answers have illustrations
 * @property {Bool}             someAnswersHasFlags               - some answer has flags
 * @property {String}           index                             - index of each question
 * @property {String}           titleIcon                         - title icon for each question
 * @property {String}           titleText                         - title for each question
 * @property {String}           infoText                          - info text for each question
 * @property {String}           method                            - type method for the choice list(single/multiple)
 * @property {String}           id                                - id for the choice list
 * @property {Object}           options                           - different options for the choice list
 * @property {Object}           value                             - value question type
 */

export const defaultPreferenceQuestionData = {
  allAnswersHasIllustrations: false,
  someAnswersHasFlags: false,
  index: '',
  titleIcon: '',
  titleText: '',
  infoText: '',
  method: '',
  id: '',
  options: {},
  value: ''
}

export const PreferenceQuestionTemplate = (d) => {
  d = { ...defaultPreferenceQuestionData, ...d }
  return `
  <div class="w-preference-questionnaire__question"
    data-w-preference-questionnaire__question=${d.index}
    ${d.conditional ? 'data-w-preference-questionnaire__conditional-question' : ''}
    ${d.conditioned ? `data-w-preference-questionnaire__conditioned-question='${d.conditioned}'` : ''}>
    <div class="o-bullet w-preference-questionnaire__question-title-container">
      <span class="o-bullet__icon m-icon m-icon--size-large m-icon--${d.titleIcon}"></span>
      <h2 class="o-bullet__text m-heading m-heading--medium w-preference-questionnaire__question-title">${d.titleText}</h2>
    </div>
    ${d.infoText ? `<div class="w-preference-questionnaire__question-info-text">${d.infoText}</div>` : ''}
    <div class="w-preference-questionnaire__question-options">
      ${ChoiceListTemplate({
        method: d.method,
        variant: 'boxed',
        highlighted: false,
        id: d.id,
        label: '',
        extraClasses: `w-preference-questionnaire__options ${d.allAnswersHasIllustrations ? 'w-preference-questionnaire__options--illustrations' : d.someAnswersHasFlags ? 'w-preference-questionnaire__options--flags' : ''}`,
        disabled: false,
        unresolved: false,
        items: d.options,
        attributes: {
          'data-w-preference-questionnaire__options': '',
          'data-w-preference-questionnaire__question-value': d.value
        }
      })}
    </div>
  </div>
  `
}

export const checkboxContentWithFlag = (title, countryFlag) => {
  return `
    <span class="c-checkbox__text">${title}</span>
    <span class="m-country-flag" data-m-country-flag__country-id="${countryFlag}"></span>
  `
}
