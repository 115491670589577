import pusher from '../pusher'
import * as eventTypes from '../../../../js/document/event-types'
import { accommodationNavMapper } from '../mappers/accommodation-nav/accommodation-nav-mapper'

export function listenNavigationEvents (event) {
  event.on(eventTypes.navigationEvents.CLICK_NAVIGATION, data => {
    const navigationData = accommodationNavMapper(data)
    if (navigationData) {
      pusher.pushDataObject(navigationData)
    }
  })
}
