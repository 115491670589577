import pusher from '../pusher'
import * as eventTypes from '../../../../js/document/event-types'
import { ErrorOrigins } from '../helpers/error-origin-helper'
import { validationErrorsMapper } from '../mappers/shared/validation-errors-mapper'
import { refundRequestMapper } from '../mappers/refund/refund-request-mapper'
import { refundRequestClickMapper } from '../mappers/refund/refund-request-click-mapper'

export function listenFormComponentEvents (event, track) {
  event.on('validated', (data) => {
    const mappedData = validationErrorsMapper(data, track, 'trackErrorRefund', ErrorOrigins.CLIENT_SIDE_VALIDATION_ERROR)
    pusher.pushDataObjects(mappedData)
  })
}

export function listenRefundRequestWidgetEvents (event, track) {
  event.on(eventTypes.refundEvents.REFUND_REQUESTED, (data) => {
    const mappedData = refundRequestMapper(data)
    pusher.pushDataObject(mappedData)
  })

  event.on(eventTypes.refundEvents.REFUND_REQUEST_CLICKED, (data) => {
    const mappedData = refundRequestClickMapper(data)
    pusher.pushDataObject(mappedData)
  })
}
