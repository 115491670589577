import { destinationIcons } from '../../../js/data/filters/destinations/icons-config'
import { defaultCollapseData, CollapseTemplate } from '../../components/collapse/c-collapse.template'
import {
  defaultCollapseTriggerData,
  CollapseTriggerTemplate
} from '../../components/collapse/c-collapse__trigger.template'

export const CategoryBlock = (category, results, regExp, siteTypes, options) => {
  if (!results.some(result => result.getAttribute('category') === category)) {
    return ''
  }
  return `
  <div data-cid="${category}-Category" class="w-live-search__category" >
    <i class="m-icon m-icon--${destinationIcons(siteTypes[category], category)} w-live-search__category-icon"></i>
    <span class="w-live-search__category-header">${
      options.categoryTitles[category] ? options.categoryTitles[category] : category
    }</span>
    ${CollapseTemplate(
      Object.assign({}, defaultCollapseData, {
        id: category + 'Collapse',
        maxItems: 5,
        childrenWrapper: '.' + category.toLowerCase() + '-options',
        attributes: '',
        extraClasses: 'live-search__item-collapse',
        track: options.attr.track,
        html: `<ul class='${category.toLowerCase()}-options'>${results
          .filter(result => result.getAttribute('category') === category)
          .map(result => `${NewResultTemplate(result, regExp)}`)
          .join('')}
      </ul>`
      })
    )}
    ${CollapseTriggerTemplate({
      ...defaultCollapseTriggerData,
      id: category + 'Collapse',
      text: `${options.showAllText}`,
      action: 'open',
      extraClasses: 'w-live-search__category-trigger'
    })}
    ${CollapseTriggerTemplate({
      ...defaultCollapseTriggerData,
      id: category + 'Collapse',
      text: `${options.showLessText}`,
      action: 'close',
      extraClasses: 'w-live-search__category-trigger'
    })}
  </div>`
}

const NewResultTemplate = (result, regExp) => {
  const breadcrumbs = result.getAttribute('breadcrumbs')
  return `
  <li class="c-autocomplete__result w-live-search__result" data-result-type="${result.getAttribute(
    'type'
  )}" data-cid="${result.cid}">
    <span data-attribute="name" class="w-live-search__result-name">
      ${result.getAttribute('name').replace(regExp, '<mark>$1</mark>')}
    </span>
    ${
      breadcrumbs && breadcrumbs.length > 0
        ? `<div class="w-live-search__breadcrumbs"> ${breadcrumbs
            .map(breadcrumb => `${BreadCrumbTemplate(breadcrumb)}`)
            .join(', ')} </div>`
        : ''
    }
  </li>
  `
}

const BreadCrumbTemplate = breadcrumb => {
  return `<span data-attribute="breadcrumb" class="w-live-search__breadcrumb">${breadcrumb}</span>`
}
