import { CUSTOM_PARAMETERS } from '../../constants'

export const reviewsTeaserOpenPopupMapper = data => {
  let dataObject = null
  if (data) {
    dataObject = {
      event: 'reviews',
      [CUSTOM_PARAMETERS.parameter_2]: 'Show all reviews - teaser component', // description
      [CUSTOM_PARAMETERS.parameter_3]: 'button - ' + data, // element
      [CUSTOM_PARAMETERS.parameter_4]: 'click text button', // type
      [CUSTOM_PARAMETERS.parameter_5]: 'open modal', // result
      [CUSTOM_PARAMETERS.parameter_6]: true // interaction
    }
  }

  return dataObject
}
