import { CUSTOM_PARAMETERS } from '../../constants'

export const reviewsTeaserClosePopupMapper = () => {
  let dataObject = null

  dataObject = {
    event: 'reviews',
    [CUSTOM_PARAMETERS.parameter_2]: 'Close all reviews - teaser component', // description
    [CUSTOM_PARAMETERS.parameter_3]: 'button', // element
    [CUSTOM_PARAMETERS.parameter_4]: 'click text button', // type
    [CUSTOM_PARAMETERS.parameter_5]: 'close modal', // result
    [CUSTOM_PARAMETERS.parameter_6]: true // interaction
  }

  return dataObject
}
