import pusher from '../pusher'
import * as eventTypes from '../../../../js/document/event-types'
import { mainMenuMapper } from '../mappers/main-menu/main-menu-mapper'

export function listenMainMenuEvents (event) {
  event.on(eventTypes.mainMenuEvents.MAIN_MENU_ITEM_CLICKED, (data) => {
    const mappedData = mainMenuMapper(data)
    pusher.pushDataObject(mappedData)
  })
}
