import { accountBookingType as events } from '../../types'
import { excursionsEvents, accountSpecialLuggageEvents } from '../../../../../js/document/event-types'

import { ga4Mapper } from '../ga4-mapper'

/**
* Mapper for the toggles of the account booking
* @param {Object} data
* @param {String} eventName
* @param {String} track
* @returns {Object | null}
*/
export const toggleMapper = (data, eventName, track) => {
  const dataResult = eventName
  let dataObject = null
  const dataEvent = track && data ? getToggleData(track, data) : null

  if (dataEvent && dataResult) {
    dataObject = ga4Mapper({
      ...dataEvent,
      result: dataResult,
      element: 'button',
      interaction: true
    })
  }
  return dataObject
}

/**
* Get the toggle data depending on the event received
* @param {String} event
* @returns {Object}
*/
export const getToggleData = (event, data) => {
  switch (event) {
    case events.PARTICIPANT_TOGGLED:
      if (!data.attr || !data.attr.participant) return null
      return {
        event: 'participantInfo',
        description: 'open or close personal detail overview',
        type: data.attr.participant
      }

    case events.TRANSPORT_SUMMARY_TOGGLED:
      if (!data.attr || !data.attr.participant) return null
      return {
        event: 'transportSummaryInfo',
        description: 'open or close transport summary overview',
        type: data.attr.participant
      }

    case events.CONTACT_INFO_TOGGLED:
      return {
        event: 'contactInfo',
        description: 'open or close contact information'
      }

    default:
      return ''
  }
}

export const paymentMapper = (data, textBtn) => {
  let dataObject = null
  if (data && data.attr && data.attr.bookingNumber && textBtn) {
    dataObject = ga4Mapper({
      event: 'paymentCTA',
      description: 'click on payment cta',
      element: 'button',
      type: textBtn,
      result: data.attr.bookingNumber,
      interaction: true
    })
  }
  return dataObject
}

export const deleteBookingMapper = (eventName) => {
  let dataObject = null
  const eventResult = getDeleteBookingEventResult(eventName)
  if (eventResult) {
    dataObject = ga4Mapper({
      event: 'manageAccount',
      description: 'manage account',
      element: 'button',
      result: eventResult,
      interaction: true
    })
  }
  return dataObject
}

export const getDeleteBookingEventResult = (event) => {
  switch (event) {
    case events.DELETE_BOOKING_MODAL_OPEN:
      return 'delete booking modal open'

    case events.BOOKING_DELETED:
      return 'booking deleted'

    default:
      return ''
  }
}

/**
* Mapper for the excursions
* @param {String} eventName
* @param {Object} [data]
* @returns {Object | null}
*/
export const excursionsMapper = (eventName, data) => {
  let dataObject = null
  const excursionTitle = data && data.excursionTitle ? data.excursionTitle : {}
  const eventData = getExcursionsEventData(eventName, excursionTitle)
  if (eventData) {
    dataObject = ga4Mapper({
      event: 'excursionsAccount',
      description: 'excursions engagement in my account',
      element: 'clickable text',
      ...eventData,
      interaction: true
    })
  }
  return dataObject
}

/**
* Get the excursion label depending on the event received
* @param {String} event
* @returns {String}
*/
export const getExcursionsEventData = (event, excursionTitle) => {
  switch (event) {
    case excursionsEvents.EXCURSION_CLICKED:
      return {
        result: 'redirect to excursions page',
        type: excursionTitle
      }

    case excursionsEvents.SEE_MORE_EXCURSIONS_CLICKED:
      return {
        result: 'show more excursions link clicked'
      }

    default:
      return null
  }
}

/**
* Mapper for the download documents
* @param {Object} [data]
* @returns {Object | null}
*/
export const downloadDocumentsMapper = (data) => {
  let dataObject = null
  if (data && data.documentName && data.bookingNumber) {
    dataObject = ga4Mapper({
      event: 'download',
      description: 'click on download',
      element: 'button',
      type: data.documentName,
      result: data.bookingNumber,
      interaction: true
    })
  }
  return dataObject
}

export const accountSpecialLuggageMapper = (event, type) => {
  let dataObject = null
  const eventData = getAccountSpecialLuggageEventData(event, type)
  if (eventData) {
    dataObject = ga4Mapper({
      event: 'accountSpecialLuggage',
      element: 'button',
      ...eventData,
      interaction: true
    })
  }
  return dataObject
}

export const getAccountSpecialLuggageEventData = (event, type) => {
  switch (event) {
    case accountSpecialLuggageEvents.OPEN:
      return {
        description: 'Special luggage modal opened'
      }
    case accountSpecialLuggageEvents.SUBMITTED:
      if (!type) return null
      return {
        description: 'Special luggage submitted',
        type
      }

    case accountSpecialLuggageEvents.ERROR_SUBMITTING:
      return {
        description: 'Error submitting special luggage'
      }

    default:
      return null
  }
}
