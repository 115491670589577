import { preferenceQuestionnaireEvents as events } from '../../../../js/document/event-types'

/**
* Mapper for the preference questionnaire
* @param {String} eventName
* @param {Bool} isComingThroughMail
* @returns {Object}
*/
export const preferenceQuestionnaireMapper = (eventName, isComingThroughMail, data) => {
  let dataObject = null
  const questionValue = data && data.questionValue ? data.questionValue : ''

  const eventData = getEventData(eventName, isComingThroughMail, questionValue)
  if (eventData && eventData.event && eventData.eventLabel) {
    dataObject = {
      ...eventData,
      eventAction: 'click',
      eventCategory: 'preference questionnaire'
    }
  }
  return dataObject
}

/**
* Get the event label and event depending on the event name and if is coming through mail or account
* @param {String} eventName
* @param {Bool} isComingThroughMail
* @returns {Object}
*/
export const getEventData = (eventName, isComingThroughMail, questionValue) => {
  const fromWhereUserComes = isComingThroughMail ? 'email' : 'account'
  switch (eventName) {
    case events.PREFERENCE_QUESTIONNAIRE_START:
      return {
        event: 'preferenceQuestionnaireStart',
        eventLabel: `Preference questionnaire start from ${fromWhereUserComes}`
      }

    case events.PREFERENCE_QUESTIONNAIRE_SEND:
      return {
        event: 'preferenceQuestionnaireSend',
        eventLabel: `Preference questionnaire send from ${fromWhereUserComes}`
      }

    case events.PREFERENCE_QUESTIONNAIRE_EDIT:
      if (!questionValue) return
      return {
        event: 'preferenceQuestionnaireEdit',
        eventLabel: `Preference questionnaire question edit: ${questionValue}`
      }

    default:
      return ''
  }
}
