import { CUSTOM_PARAMETERS } from '../../constants'

export const accommodationFacilitiesMapper = (data, eventName) => {
  let dataObject = null

  if (data && (eventName === 'open' || eventName === 'close')) {
    dataObject = {
      event: 'PDP',
      [CUSTOM_PARAMETERS.parameter_2]: (eventName === 'open' ? 'click for more information about facilities' : (eventName === 'close' ? 'click for less information about facilities' : null)), // description
      [CUSTOM_PARAMETERS.parameter_3]: 'Button', // element
      [CUSTOM_PARAMETERS.parameter_4]: (eventName === 'open' ? 'show more' : (eventName === 'close' ? 'show less' : null)), // type
      [CUSTOM_PARAMETERS.parameter_5]: (eventName === 'open' ? 'all facilities text shown' : (eventName === 'close' ? 'all facilities text hidden' : null)), // result
      [CUSTOM_PARAMETERS.parameter_6]: true // interaction
    }
  }
  return dataObject
}
