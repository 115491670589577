import { ga4Mapper } from '../ga4-mapper'

export const bookingOptionalAncillariesPopupShownMapper = () => {
  return bookingOptionalAncillariesPopupMapper({
    element: 'element visible',
    result: 'pop-up shown',
    interaction: false
  })
}

export const bookingOptionalAncillariesPopupCloseMapper = () => {
  return clickedMapper({ result: 'close' })
}

export const bookingOptionalAncillariesPopupCloseOutsideMapper = () => {
  return clickedMapper({ result: 'close outside box' })
}

export const bookingOptionalAncillariesPopupCloseEscMapper = () => {
  return clickedMapper({ result: 'close ESC key' })
}

export const bookingOptionalAncillariesPopupBackToAncillariesMapper = () => {
  return clickedMapper({ result: 'back to ancillaries' })
}

export const bookingOptionalAncillariesPopupContinueBookingMapper = () => {
  return clickedMapper({ result: 'continue booking' })
}

const clickedMapper = (data) => {
  return bookingOptionalAncillariesPopupMapper({
    ...data,
    element: 'element clicked',
    interaction: true
  })
}

const bookingOptionalAncillariesPopupMapper = (data) => {
  if (data) {
    return ga4Mapper({
      ...{
        event: 'Ancillaries',
        description: 'optional ancillaries popup'
      },
      ...data
    })
  }
  return null
}
