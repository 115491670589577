import { CUSTOM_PARAMETERS } from '../../constants'

export const accommodationPracticalBlockMapper = (data, eventName) => {
  let dataObject = null

  if (data && (eventName === 'open' || eventName === 'close') && data.attr && data.attr.practicalBlockTitle) {
    const titleOfPracticalBlock = data.attr.practicalBlockTitle
    const moreOrLess = (eventName === 'open' ? 'more' : 'less')

    dataObject = {
      event: 'PDP',
      [CUSTOM_PARAMETERS.parameter_2]: 'Practical info', // description
      [CUSTOM_PARAMETERS.parameter_3]: 'Accordion', // element
      [CUSTOM_PARAMETERS.parameter_4]: titleOfPracticalBlock, // type
      [CUSTOM_PARAMETERS.parameter_5]: `show ${moreOrLess}`, // result
      [CUSTOM_PARAMETERS.parameter_6]: true // interaction
    }
  }
  return dataObject
}
