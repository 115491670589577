/**
 * List of events used by booking steps widget
 *
 */
export const bookingCancellationFlow = {
  CHECK_REGULAR_CANCELLATION_CONDITIONS: 'bookingCancellationFlow.checkRegularCancellationConditions',
  CONFIRM_REGULAR_CANCELLATION: 'bookingCancellationFlow.confirmRegularCancellation',
  GET_PARTIAL_CANCELLATION_PARTICIPANTS: 'bookingCancellationFlow.getPartialCancellationParticipants',
  CONFIRM_PARTIAL_CANCELLATION: 'bookingCancellationFlow.confirmPartialCancellation'
}
