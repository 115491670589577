import { PRODUCT_DIMENSIONS, SERVICE_MAPPER_TYPE } from '../../constants'
import { mapExtraData } from '../../helpers/booking-dimensions-helper'
import { mapOptionPrice } from '../../helpers/booking-price-helper'

const OUTBOUND_LUGGAGE = 'OUT'
const INBOUND_LUGGAGE = 'IN'
const INBOUND_LUGGAGE_DIRECTION = 'Inbound'

export const mapRemove = (serviceMapperType, data, service, bookingData, servicesData) => {
  return map(serviceMapperType, data, service, bookingData, servicesData, 'ancillary_remove', 'remove')
}

export const mapAdd = (serviceMapperType, data, service, bookingData, servicesData) => {
  return map(serviceMapperType, data, service, bookingData, servicesData, 'ancillary_add', 'add')
}

export const mapAddAppliesToAllParticipants = (serviceMapperType, data, service, bookingData, servicesData) => {
  const events = []
  let event = null

  const bookingService = servicesData.find(s => s.componentId === data.componentId)
  const bookingOption = bookingService ? bookingService.options.find(o => o.code === service.serviceId) : null

  if (bookingOption) {
    const participantsToSwapOrAddService = bookingOption.appliesToParticipants.filter(p => !bookingOption.assignedParticipants.includes(p))
    participantsToSwapOrAddService.forEach(p => {
      const oldOption = bookingService.options.find(o =>
        o.isSelected &&
        o.assignedParticipants.includes(p)
      )

      if (oldOption) {
        const oldService = {
          code: oldOption.code,
          participants: [p]
        }
        event = mapRemove(serviceMapperType, data, oldService, bookingData, servicesData)
        event && events.push(event)
      }

      const newService = {
        code: service.serviceId,
        participants: [p]
      }
      event = mapAdd(serviceMapperType, data, newService, bookingData, servicesData)
      event && events.push(event)
    })
  }

  return events
}

export const mapAddInboundLuggage = (serviceMapperType, data, bookingData, servicesData) => {
  const events = []
  let event = null
  const service = data.body.newService
  if (data.type === 'luggage' && service.code.includes(OUTBOUND_LUGGAGE)) {
    const bookingService = servicesData.find(s => s.componentId === data.componentId)
    const bookingOption = bookingService ? bookingService.options.find(o => o.code === service.code) : null

    if (bookingOption) {
      let newInboundServiceOption = null
      const inboundServiceName = service.code.replace(OUTBOUND_LUGGAGE, INBOUND_LUGGAGE)
      if (bookingOption.isNoneOption) {
        newInboundServiceOption = bookingService.options.find(o =>
          (!o.isSelected || !service.participants.every(p => o.assignedParticipants.includes(p))) &&
          o.direction === INBOUND_LUGGAGE_DIRECTION &&
          o.code === inboundServiceName &&
          o.isNoneOption)
      } else {
        newInboundServiceOption = bookingService.options.find(o =>
          (!o.isSelected || !service.participants.every(p => o.assignedParticipants.includes(p))) &&
          o.direction === INBOUND_LUGGAGE_DIRECTION &&
          o.code === inboundServiceName &&
          o.unitWeight === bookingOption.unitWeight &&
          o.numberOfUnits === bookingOption.numberOfUnits)
      }
      if (newInboundServiceOption) {
        const oldInboundServiceOption = bookingService.options.find(o =>
          o.isSelected &&
          service.participants.every(p => o.assignedParticipants.includes(p)) &&
          o.direction === INBOUND_LUGGAGE_DIRECTION)
        if (oldInboundServiceOption) {
          const oldInboundService = {
            code: oldInboundServiceOption.code,
            participants: service.participants
          }
          event = mapRemove(serviceMapperType, data, oldInboundService, bookingData, servicesData)
          event && events.push(event)
        }

        const newInboundService = {
          code: newInboundServiceOption.code,
          participants: service.participants
        }
        event = mapAdd(serviceMapperType, data, newInboundService, bookingData, servicesData)
        event && events.push(event)
      }
    }
  }
  return events
}

const map = (serviceMapperType, data, service, bookingData, servicesData, event, operation) => {
  const isCheckout = serviceMapperType === SERVICE_MAPPER_TYPE.checkout

  let dataObject = null

  if (!service) return null
  const codeSanitized = sanitizeCode(data, service)
  const bookingService = getBookingService(data, servicesData)
  const bookingServiceOption = getBookingServiceOption(bookingService, service.code)
  if (bookingServiceOption) {
    dataObject = {
      event,
      ecommerce: {
        [operation]: {
          actionField: {
            list: isCheckout ? `checkout${bookingData.step ? bookingData.step : 0}` : 'my account'
          },
          products: [
            {
              id: codeSanitized,
              name: bookingServiceOption.displayText,
              price: mapOptionPrice(Object.assign({}, bookingServiceOption, data.rawData)),
              [PRODUCT_DIMENSIONS.category]: getServiceOptionCategory(bookingServiceOption),
              [PRODUCT_DIMENSIONS.davinciServiceGroup]: bookingServiceOption.groupCode,
              quantity: getQuantity(service, bookingService, bookingServiceOption),
              ...mapExtraPropertiesForServices(data, bookingServiceOption)
            }
          ]
        }
      },
      ...bookingData && mapExtraData(bookingData.products[0], bookingData)
    }
  }
  return dataObject
}

const getBookingService = (data, servicesData) => {
  return servicesData.find(s => s.componentId === data.componentId)
}

const getBookingServiceOption = (bookingService, code) => {
  return bookingService ? bookingService.options.find(o => o.code === code) : null
}

const sanitizeCode = (data, service) => {
  let code = service.code
  if (data.body.datatype === 'carrental') {
    code = code.split('!')[0]
  }
  return code
}

const getQuantity = (service, bookingService, bookingServiceOption) => {
  let quantity = bookingServiceOption.quantity
  if (bookingService.isPricePerPerson) {
    quantity = bookingServiceOption.quantity * service.participants.filter(Number).length
  }
  return quantity
}

const getServiceOptionCategory = (bookingServiceOption) => {
  if (!bookingServiceOption) { return }
  return `Ancillary > ${bookingServiceOption.bestValueText ? 'Persuasion' : 'Additional cost'} > ${bookingServiceOption.groupCode}`
}

const mapExtraPropertiesForServices = (data, bookingServiceOption) => {
  if (!data.body && !data.body.datatype) { return }

  switch (data.body.datatype) {
    case 'carrental': {
      if (!data.body.data) return {}
      const parsedAttributes = JSON.parse(data.body.data)
      const equipmentSelectedIds = parsedAttributes.equipments || null
      let equipments = []
      if (equipmentSelectedIds && bookingServiceOption.equipments) {
        equipments = bookingServiceOption.equipments.filter(equipment => equipmentSelectedIds.includes(equipment.id))
      }
      return {
        [PRODUCT_DIMENSIONS.equipments]: bookingServiceOption.selectedEquipments ? bookingServiceOption.selectedEquipments : equipments
      }
    }

    default:
      return {}
  }
}
