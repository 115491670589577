import { registerWidget } from '../../../js/core/widget/widget-directory'
import { elementFromString, flush } from '../../../js/document/html-helper'
import { fetchJsonData } from '../../../js/helpers/json-fetch'
import { getUrlFromString } from '../../../js/document/url'
import Component from '../../../js/core/component/component'
import { FaqTopQuestionsContentTemplate } from './w-faq-top-questions__content.template'

const widgetApi = 'w-faq-top-questions'

const classes = {
  isLoading: 'is-loading',
  noResults: 'no-results'
}

const defaults = {
}

const widgetQueries = {
  mainContainer: `[data-${widgetApi}__content]`
}

const attr = {
  url: `data-${widgetApi}__url`
}

export default class FaqTopQuestions {
  /**
   * Creates a new FaqTopQuestions
   *
   * @constructor
   *
   * @param {HTMLElement} element - The HTML component element.
   * @param {Object} [options]    - Options object
   *
   */
  constructor (element, options = {}) {
    this.element = element
    this.id = this.element.id
    this.options = {
      ...defaults,
      url: this.element.getAttribute(attr.url),
      ...options
    }

    // Get elements
    this.elements = {}
    this.elements.mainContainer = this.element.querySelector(widgetQueries.mainContainer)

    // Fetch and update html
    this.fetchAndUpdate()
      .catch(e => { })
  }

  async fetchAndUpdate () {
    const getUrl = getUrlFromString(this.options.url, null)
    const results = await fetchJsonData(getUrl)

    if (results && this._notEmptyResults(results)) {
      this.element.classList.remove(classes.isLoading)
      const templateData = this._mapApiResultsToTemplateData(results)
      const renderedHtml = FaqTopQuestionsContentTemplate(templateData)
      this._renderHTML(renderedHtml, this.elements.mainContainer)
    } else {
      this.element.classList.add(classes.noResults)
      this.element.classList.remove(classes.isLoading)
    }
  }

  _notEmptyResults (results) {
    return results.data && results.data.nodes && results.data.nodes.length > 0
  }

  _mapApiResultsToTemplateData (results) {
    const templateData = {
      id: this.id,
      questions: []
    }

    if (this._notEmptyResults(results)) {
      templateData.questions = results.data.nodes.map(node => {
        const question = {
          title: node.text,
          body: node.answer
        }
        return question
      })
    }

    return templateData
  }

  _renderHTML (html, targetElement) {
    const newContent = elementFromString(html)
    flush(targetElement)
    targetElement.appendChild(newContent)
    Component.initDocumentComponentsFromAPI(newContent)
  }
}

registerWidget(FaqTopQuestions, widgetApi)
