import * as eventTypes from '../../../../js/document/event-types'
import { recommendationItemClickedMapper, recommendationSliderMapper } from '../mappers/accommodation-recommendation/accommodation-recommendation-mapper'
import pusher from '../pusher'

export function listenRecommendedAccoListerWidgetEvents (event) {
  event.on(eventTypes.recommendedAccoListerEvents.RECOMMENDED_ACCO_LISTER_ITEM_CLICKED, (data) => {
    const mappedData = recommendationItemClickedMapper(data)
    pusher.pushDataObject(mappedData)
  })
}

export function listenBtnEvents (event) {
  event.on('clickButton', (data) => {
    const mappedData = recommendationItemClickedMapper(data)
    pusher.pushDataObject(mappedData)
  })
}

export function listenSliderEvents (event) {
  event.on('change', () => {
    const mappedData = recommendationSliderMapper()
    pusher.pushDataObject(mappedData)
  })
}
