import { CUSTOM_PARAMETERS } from '../constants'
import { capitalizeString } from '../../../../js/helpers/string'

export const sanitizeFlightBusSelectorData = (data) => {
  return {
    ...data,
    transportType: data.transportType ?? 'flight'
  }
}

export const flightBusSelectorMapper = (data) => {
  if (data) {
    return {
      event: `${capitalizeString(data.transportType)}`,
      [CUSTOM_PARAMETERS.parameter_2]: `${capitalizeString(data.transportType)} component`, // description
      [CUSTOM_PARAMETERS.parameter_3]: data.element, // element
      [CUSTOM_PARAMETERS.parameter_4]: data.type, // type
      [CUSTOM_PARAMETERS.parameter_5]: data.result, // result
      [CUSTOM_PARAMETERS.parameter_6]: data.interaction // interaction
    }
  }
  return null
}
