import { registerWidget } from '../../../js/core/widget/widget-directory'
import { elementFromString } from '../../../js/document/html-helper'
import { ModalTemplate } from '../../components/modal/c-modal.template'
import { BtnTemplate } from '../../components/btn/c-btn.template'
import Component from '../../../js/core/component/component'
import storage from '../../../js/document/web-storage'
import { CeProfile } from '../ce-profile/ce-profile'

const widgetApi = 'w-ce-modal'

const cookieNames = {
  ceWelcomeSeen: 'cewelcomeseen'
}

const textVar = {
  partnerName: '{PartnerName}',
  partnerDiscount: '{PartnerDiscount}'
}

export const widgetQueries = {
  settings: `[data-${widgetApi}__settings]`,
  modalBtn: `[data-${widgetApi}__modal-btn]`
}

export default class CeModal {
  constructor (element) {
    this.element = element
    this._getHtmlElements()
    this.cookie = storage.cookie
    this.settingsData = this._getSettingsDataFromDom()
    if (this.settingsData) this._showCeModal()
  }

  _getHtmlElements () {
    this.settings = this.element.querySelector(widgetQueries.settings)
  }

  _getSettingsDataFromDom () {
    if (!this.settings) return false
    return JSON.parse(this.settings.textContent)
  }

  _renderModal (partnerDiscount, partnerName) {
    if (!this.settingsData.title || !this.settingsData.body) return
    const title = this._replaceTextVars(this.settingsData.title, partnerName, partnerDiscount)
    const body = this._replaceTextVars(this.settingsData.body, partnerName, partnerDiscount)
    const modal = ModalTemplate({
      id: this.settingsData.modalId,
      bodyContent: bodyModalTemplate(title, body),
      style: 'neutral',
      closable: false,
      footerContent: `${BtnTemplate({
          variant: 'flow',
          block: true,
          text: this.settingsData.btnText,
          attributes: {
                        [`data-${widgetApi}__modal-btn`]: '',
                        'data-c-modal__action': 'close',
                        'data-c-modal__id': this.settingsData.modalId
                      }
        }
      )}`
    })
    const newContent = elementFromString(modal)
    this.element.appendChild(newContent)
    Component.initDocumentComponentsFromAPI(newContent)
    this._attachModalEvent(newContent)
    newContent['c-modal'].open()
  }

  _replaceTextVars (str, partnerName, partnerDiscount) {
    let replacedStr = str.replaceAll(textVar.partnerName, partnerName)
    replacedStr = replacedStr.replaceAll(textVar.partnerDiscount, partnerDiscount)
    return replacedStr
  }

  _showCeModal () {
    const ceWelcomeSeen = this.cookie.get(cookieNames.ceWelcomeSeen)
    if (ceWelcomeSeen !== 0) return false
    const partner = new CeProfile().getPartnerData(this.settingsData.publicationCode)
    if (!partner) return false
    this._renderModal(partner.partnerDiscount, partner.partnerName)
  }

  _attachModalEvent (modal) {
    const modalBtn = modal.querySelector(widgetQueries.modalBtn)
    modalBtn.addEventListener('click', () => {
      this.cookie.set(cookieNames.ceWelcomeSeen, '1', {
        path: '/',
        expirationDays: parseInt(1)
      })
    })
  }
}

registerWidget(CeModal, widgetApi)

const bodyModalTemplate = (title, body) => {
  return `
    <div class="u-align--center sp-stack-children--medium">
      <div>
        <i class="${widgetApi}__icon m-icon m-icon--spark m-icon--size-giant"></i>
      </div>
      <div class="m-heading m-heading--medium">${title}</div>
      ${body}
    </div>`
}
