/**
 * List of events used by payment
 *
 */
export const payment = {
  PAYMENT_METHOD_SELECTED: 'Payment.MethodSelected',
  ADD_VOUCHER: 'Payment.AddVoucher',
  VOUCHER_SELECTED: 'Payment.VoucherSelected',
  VOUCHER_FETCHED: 'Payment.VoucherFetched',
  VOUCHER_AMOUNT_ERROR: 'Payment.VoucherAmountError',
  CANCELED: 'Payment.Canceled',
  PAYMENT_SUCCESS: 'Payment.Success',
  PAYMENT_ERROR: 'Payment.Error',
  PAYMENT_PENDING: 'Payment.Pending',
  PAYMENT_FAILED: 'Payment.Failed',
  PROVIDER_MOUNTED: 'Payment.ProviderMounted',
  ON_SUBMIT: 'Payment.OnSubmit',
  CONFIG_LOADED: 'Payment.ConfigLoaded',
  PARTIAL_PAY: 'Payment.PartialPay',
  PARTIAL_REMOVE: 'Payment.PartialRemove',
  DOWN_PAYMENT_COVERED: 'Payment.DownPaymentCovered'
}
