export const searchFilter = {
  COUNTRY: 'searchFilter.country',
  REGION: 'searchFilter.region',
  CITY: 'searchFilter.city',
  DEPARTURE_DATE: 'searchFilter.departureDate',
  PARTICIPANTS: 'searchFilter.participants',
  AIRPORTS: 'searchFilter.airports',
  ARRIVAL_AIRPORTS: 'searchFilter.arrivalAirports',
  DURATION: 'searchFilter.duration',
  MEALPLAN: 'searchFilter.mealplan',
  LASTMINUTE: 'searchFilter.lastminute',
  PRICE_PER_PERSON: 'searchFilter.pricePerPerson',
  ACCOMMODATION_TYPE: 'searchFilter.accommodationType',
  ROOM_COUNT: 'searchFilter.roomCount',
  SKI_MIN_ALTITUDE: 'searchFilter.skiAreaMinimumAltitudeMeters',
  BATHROOM_COUNT: 'searchFilter.bathroomCount',
  GENERAL_FACILITIES: 'searchFilter.generalFacilities',
  INTERNET_FACILITIES: 'searchFilter.internetFacilities',
  CHILDREN_FACILITIES: 'searchFilter.childrenFacilities',
  WIFI_FACILITY: 'searchFilter.wifiFacility',
  RECOMMENDED: 'searchFilter.recommended',
  CAMPAIGNS: 'searchFilter.campaings',
  THEMES: 'searchFilter.themes',
  CUSTOMER_RATING: 'searchFilter.customerRating',
  CENTER_DISTANCE: 'searchFilter.centerDistance',
  SLOPE_DISTANCE: 'searchFilter.slopeDistance',
  SKI_LIFT_DISTANCE: 'searchFilter.skiLiftDistance',
  BEACH_DISTANCE: 'searchFilter.beachDistance',
  DISCOUNT: 'searchFilter.discount',
  BEDS_LIVINGROOM: 'searchFilter.bedsInLivingroom',
  SKI__PASS_INCLUDED: 'searchFilter.skiPassIncluded',
  HAS_AVAILABILITY: 'searchFilter.hasAvailability',
  FLIGHT_ONLY: 'searchFilter.isFlightOnly',
  STARS: 'searchFilter.stars',
  COLLECTION: 'searchFilter.collection',
  TRANSPORT: 'searchFilter.transportType',
  AIRLINE: 'searchFilter.airline',
  DESTINATION: 'searchFilter.destination',
  SKI_AREA_KMS: 'searchFilter.skiAreaSlopeKms'
}
