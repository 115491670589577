import * as eventTypes from '../../../../js/document/event-types'
import pusher from '../pusher'
import { sanitizeFlightBusSelectorData, flightBusSelectorMapper } from '../mappers/flight-bus-selector-mapper'

const ELEMENT_CLICKED = 'element_clicked'
const BUTTON_GROUP = 'button_group'

export function listenFlightBusSelectorEvents (event) {
  event.on(eventTypes.flightBusSelectorEvents.FLIGHT_SELECTOR_SIDE_DRAWER_CLOSED, (data) => {
    const sanitizedData = sanitizeFlightBusSelectorData(data)
    const mappedData = flightBusSelectorMapper({ ...sanitizedData, element: ELEMENT_CLICKED, result: 'Close component' })
    pusher.pushDataObject(mappedData)
  })

  event.on(eventTypes.flightBusSelectorEvents.FLIGHT_SELECTOR_SIDE_DRAWER_OPENED, (data) => {
    const sanitizedData = sanitizeFlightBusSelectorData(data)
    const mappedData = flightBusSelectorMapper({ ...sanitizedData, element: BUTTON_GROUP, result: `Change ${sanitizedData.transportType}` })
    pusher.pushDataObject(mappedData)
  })

  event.on(eventTypes.flightBusSelectorEvents.FLIGHT_SELECTOR_COLLAPSE_CLOSED, (data) => {
    const sanitizedData = sanitizeFlightBusSelectorData(data)
    const mappedData = flightBusSelectorMapper({ ...sanitizedData, element: ELEMENT_CLICKED, result: 'Show less' })
    pusher.pushDataObject(mappedData)
  })

  event.on(eventTypes.flightBusSelectorEvents.FLIGHT_SELECTOR_COLLAPSE_OPENED, (data) => {
    const sanitizedData = sanitizeFlightBusSelectorData(data)
    const mappedData = flightBusSelectorMapper({ ...sanitizedData, element: BUTTON_GROUP, result: 'Show more' })
    pusher.pushDataObject(mappedData)
  })

  event.on(eventTypes.flightBusSelectorEvents.FLIGHT_SELECTOR_CANCEL, (data) => {
    const sanitizedData = sanitizeFlightBusSelectorData(data)
    const mappedData = flightBusSelectorMapper({ ...sanitizedData, element: ELEMENT_CLICKED, result: 'Remove selection' })
    pusher.pushDataObject(mappedData)
  })

  event.on(eventTypes.flightBusSelectorEvents.FLIGHT_SELECTOR_SAVE, (data) => {
    const sanitizedData = sanitizeFlightBusSelectorData(data)
    const mappedData = flightBusSelectorMapper({ ...sanitizedData, element: ELEMENT_CLICKED, result: 'Save selection' })
    pusher.pushDataObject(mappedData)
  })

  event.on(eventTypes.flightBusSelectorEvents.FLIGHT_SELECTOR_SELECT_FLIGHT, (data) => {
    const sanitizedData = sanitizeFlightBusSelectorData(data)
    const mappedData = flightBusSelectorMapper({ ...sanitizedData, element: ELEMENT_CLICKED, result: `Select ${sanitizedData.transportType}` })
    pusher.pushDataObject(mappedData)
  })

  event.on(eventTypes.flightBusSelectorEvents.FLIGHT_SELECTOR_SWITCH_TAB, (data) => {
    const sanitizedData = sanitizeFlightBusSelectorData(data)
    const mappedData = flightBusSelectorMapper({ ...sanitizedData, element: ELEMENT_CLICKED, result: `Select ${sanitizedData.type}` })
    pusher.pushDataObject(mappedData)
  })
}
