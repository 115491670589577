export const da = {
  filtersError: 'Der er desværre på nuværende tidspunkt ikke nogen værelser ledige, der matcher din søgning. Forsøg venligst at ændre antallet af personer angivet i værelsesfordelingen, ved hjælp af filteret ovenfor.',
  filtersEmpty: 'We encountered problems retrieving data for this accommodation. Retry again.',
  pricetableError: 'We encountered problems retrieving prices for this accommodation. Retry again.'
}

export const de = {
  filtersError: 'Es sind keine Angebote oder Zimmer verfügbar, die Ihren aktuellen Filtern entsprechen, z. B. der Anzahl der Personen/Zimmer in Ihrer Reisegruppe. Ändern Sie bitte die Anzahl der Personen in jedem Zimmer, indem Sie den obigen Filter nutzen.',
  filtersEmpty: 'We encountered problems retrieving data for this accommodation. Retry again.',
  pricetableError: 'We encountered problems retrieving prices for this accommodation. Retry again.'
}

export const en = {
  filtersError: 'There are no offers or rooms available that match your current filters such as the number of people/rooms in your travel party. Please try changing the number of people in each room via the filter above.',
  filtersEmpty: 'We encountered problems retrieving data for this accommodation. Retry again.',
  pricetableError: 'We encountered problems retrieving prices for this accommodation. Retry again.'
}

export const nb = {
  filtersError: 'There are no offers or rooms available that match your current filters such as the number of people/rooms in your travel party. Please try changing the number of people in each room via the filter above.',
  filtersEmpty: 'We encountered problems retrieving data for this accommodation. Retry again.',
  pricetableError: 'We encountered problems retrieving prices for this accommodation. Retry again.'
}

export const fr = {
  filtersError: "Il n'y a pas d'offres ou de chambres disponibles qui correspondent aux filtres sélectionnés tels que le nombre de personnes/chambres. Veuillez modifier le nombre de personnes dans chaque chambre en utilisant le filtre ci-dessus.",
  filtersEmpty: 'We encountered problems retrieving data for this accommodation. Retry again.',
  pricetableError: 'We encountered problems retrieving prices for this accommodation. Retry again.'
}

export const nl = {
  filtersError: 'Er zijn geen aanbiedingen of kamers gevonden die overeenkomen met jouw huidige zoekopdracht. Probeer het aantal personen in elke kamer te wijzigen.',
  filtersEmpty: 'We encountered problems retrieving data for this accommodation. Retry again.',
  pricetableError: 'We encountered problems retrieving prices for this accommodation. Retry again.'
}

export const sv = {
  filtersError: 'Tyvärr finns det för närvarande inga tillgängliga rum som matchar fördelningen av personer per rum. Vänligen ändra fördelningen med hjälp av filtret ovan.',
  filtersEmpty: 'We encountered problems retrieving data for this accommodation. Retry again.',
  pricetableError: 'We encountered problems retrieving prices for this accommodation. Retry again.'
}
