import { validationErrorsMapper } from '../mappers/shared/validation-errors-mapper'
import pusher from '../pusher'
import * as eventTypes from '../../../../js/document/event-types'
import { staticContentDataMapper } from '../mappers/shared/accommodation-static-content-mapper'
import context from '../context'

export function listenFormComponentEvents (event, track) {
  event.on('validated', (data) => {
    const mappedData = validationErrorsMapper(data, track)
    pusher.pushDataObjects(mappedData)
  })
}

export function listenAccommodationStaticContentDataWidgetEvents (event) {
  event.on(eventTypes.staticContentDataEvents.STATIC_CONTENT_DATA_READY, (data) => {
    context.addContext('accommodation', data)

    const mappedData = staticContentDataMapper(data)
    pusher.pushDataObject(mappedData)
  })
}
