export const PRODUCT_DIMENSIONS = {
  id: 'id',
  name: 'name',
  brand: 'brand',
  category: 'category',
  quantity: 'quantity',
  price: 'price',
  accoStars: 'dimension2',
  locationId: 'dimension3',
  transport: 'dimension4',
  composition: 'dimension5',
  badges: 'dimension6',
  rating: 'dimension7',
  duration: 'dimension8',
  tripComposition: 'dimension9',
  daysForDeparture: 'dimension10',
  totalGuests: 'dimension11',
  departureDate: 'dimension13',
  roomType: 'dimension28',
  boardType: 'dimension38',
  originalDepartureDate: 'dimension47',
  davinciServiceGroup: 'dimension75',
  equipments: 'equipments',
  countryId: 'countryId',
  cityId: 'citiId',
  regionId: 'regionId'
}

export const CUSTOM_DIMENSIONS = {
  accoId: 'accoId',
  accoName: 'accoName',
  accoBrand: 'accoBrand',
  accoCategory: 'accoCategory',
  accoStars: 'accoStars',
  transport: 'transport',
  boardType: 'boardType',
  duration: 'duration',
  rating: 'rating',
  partyComposition: 'partyComposition',
  totalGuests: 'totalGuests',
  departureDate: 'departureDate',
  originalDate: 'originalDate',
  entryPrice: 'entryPrice',
  promotedPrice: 'promotedPrice',
  mandatoryPayment: 'mandatoryPayment',
  totalStepPrice: 'totalStepPrice',
  countryId: 'countryId',
  cityId: 'citiId',
  regionId: 'regionId',
  campaignId: 'campaignId'
}

export const SET_SERVICE_OPERATION_TYPE = {
  add: 'Add',
  swap: 'Swap',
  remove: 'Remove'
}

export const CUSTOM_PARAMETERS = {
  parameter_1: 'validated',
  parameter_2: 'description',
  parameter_3: 'element',
  parameter_4: 'type',
  parameter_5: 'result',
  parameter_6: 'interaction'
}

export const SERVICE_MAPPER_TYPE = {
  checkout: 'Checkout',
  selfService: 'SelfService'
}
