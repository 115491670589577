import { partyCompositionGroupFormater, partyCompositionParticipantsFormater } from '../../helpers/standard-partycomposition-helper'

export const promotedPriceMapper = (priceData, accoDataset) => {
  let dataObject = null
  if (priceData) {
    dataObject = {
      event: 'eec.additionalDetail',
      accoId: priceData.accommodationId ? priceData.accommodationId : '',
      transport: (priceData.includedServices && priceData.includedServices.length > 0) ? priceData.includedServices[0] : '',
      boardType: (priceData.featuredFilters && priceData.featuredFilters.length > 2) ? priceData.featuredFilters[2] : '',
      duration: priceData.duration ? priceData.duration : '',
      partyComposition: (priceData.participants && priceData.participants.length > 0) ? partyCompositionGroupFormater(priceData.participants) : '',
      totalGuests: (priceData.featuredFilters && priceData.featuredFilters.length > 2) ? partyCompositionParticipantsFormater(priceData.featuredFilters[1]) : '',
      departureDate: (priceData.departureDate && priceData.departureDate.raw) ? priceData.departureDate.raw : '',
      entryPrice: (priceData.price && priceData.price.value) ? priceData.price.value : 0,
      campaignId: priceData.yieldCampaignCode ?? '',
      promotedPrice: 'shown', // or not shown
      cityId: accoDataset?.dataset?.wStaticContentDataLocationId || '',
      regionId: accoDataset?.dataset?.wStaticContentDataAccoRegionId || '',
      countryId: accoDataset?.dataset?.wStaticContentDataAccoCountryId || ''
    }
  }
  return dataObject
}
