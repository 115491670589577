import { CUSTOM_PARAMETERS } from '../../constants'

export const reviewsListMapper = (data) => {
  return {
    event: 'reviews',
    [CUSTOM_PARAMETERS.parameter_2]: 'Show review translations', // description
    [CUSTOM_PARAMETERS.parameter_3]: 'clickable text', // element
    [CUSTOM_PARAMETERS.parameter_4]: data.country, // type
    [CUSTOM_PARAMETERS.parameter_5]: data.status, // result
    [CUSTOM_PARAMETERS.parameter_6]: true, // interaction
    ecommerce: {
      reviewTransalted: {
        id: data.id,
        overallRating: data.overallRating,
        country: data.country,
        partyComposition: data.partyComp,
        categoryScores: data.categoryScores
      }
    }
  }
}
