
export const staticContentDataMapper = (data) => {
  let accoObjects = null
  let dataObject = null
  const accoData = data ? data.dataset : null
  if (accoData) {
    dataObject = {
      id: accoData.wStaticContentDataId ? accoData.wStaticContentDataId : '',
      name: accoData.wStaticContentDataName ? accoData.wStaticContentDataName : '',
      category: accoData.wStaticContentDataCategory ? accoData.wStaticContentDataCategory : '',
      brand: accoData.wStaticContentDataBrand ? accoData.wStaticContentDataBrand : '',
      accoStars: accoData.wStaticContentDataAccoStars ? accoData.wStaticContentDataAccoStars : '',
      locationId: accoData.wStaticContentDataLocationId ? accoData.wStaticContentDataLocationId : '',
      badges: accoData.wStaticContentDataBadges ? accoData.wStaticContentDataBadges : '',
      rating: accoData.wStaticContentDataRating ? accoData.wStaticContentDataRating : '',
      cityId: accoData.wStaticContentDataLocationId || '',
      regionId: accoData.wStaticContentDataAccoRegionId || '',
      countryId: accoData.wStaticContentDataAccoCountryId || ''
    }

    accoObjects = {
      event: 'eec.detail',
      ecommerce: { detail: { actionField: { list: data.originList }, products: [dataObject] } }
    }
  }
  return accoObjects
}
