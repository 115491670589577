import { CUSTOM_PARAMETERS } from '../../constants'
import { reviewsTeaserType as events } from '../../types'

export const reviewsTeaserTranslateButtonMapper = (data, track) => {
  let dataObject = null
  if (data != null) {
    dataObject = {
      event: 'reviews',
      [CUSTOM_PARAMETERS.parameter_2]:
        'Show review translations - teaser component', // description
      [CUSTOM_PARAMETERS.parameter_3]: 'clickable text', // element
      [CUSTOM_PARAMETERS.parameter_4]: 'original language', // type
      [CUSTOM_PARAMETERS.parameter_5]: getResult(track), // result
      [CUSTOM_PARAMETERS.parameter_6]: true // interaction
    }
  }

  return dataObject
}

const getResult = event => {
  switch (event) {
    case events.REVIEWS_TEASER_TRANSLATIONS:
      return 'translate'

    case events.REVIEWS_TEASER_ORIGINAL:
      return 'original'

    default:
      return ''
  }
}
