export const clickSearchResultMapper = (data) => {
  if (!data) return null
  const dataObject = data.ecommerce.impressions[0]
  return {
    event: 'eec.impressionClick',
    ecommerce: {
      click: {
        actionField: { list: dataObject.list },
        products: [dataObject]
      }
    }
  }
}
