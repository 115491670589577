import * as eventTypes from '../../../../js/document/event-types'
import { mapMarkerMapper, mapOpenedMapper, mapClosedMapper } from '../mappers/products/map-mapper'
import pusher from '../pusher'

export function listenProductMapEvents (event) {
  event.on(eventTypes.productMapEvents.ACCOMMODATION_MARKER_CLICKED, (data) => {
    const mappedData = mapMarkerMapper(data)
    pusher.pushDataObject(mappedData)
  })

  event.on(eventTypes.productMapEvents.PRODUCT_MAP_OPENED, (data) => {
    const mappedData = mapOpenedMapper(data)
    pusher.pushDataObject(mappedData)
  })

  event.on(eventTypes.productMapEvents.PRODUCT_MAP_CLOSED, (data) => {
    const mappedData = mapClosedMapper(data)
    pusher.pushDataObject(mappedData)
  })
}
