import { ErrorOrigins } from '../helpers/error-origin-helper'

export const redeemVoucherServerValidationErrorMapper = (data) => {
  let mappedData = null
  if (data) {
    mappedData = redeemVoucherErrorMapper({
      eventName: 'trackErrorVoucher',
      field: `voucher_invalid_${ErrorOrigins.SERVER_SIDE_VALIDATION_ERROR}`,
      message: data.message,
      voucherCode: data.voucherCode
    })
  }
  return mappedData
}

export const redeemVoucherServerSideErrorMapper = (data) => {
  let mappedData = null
  if (data) {
    mappedData = redeemVoucherErrorMapper({
      eventName: 'trackErrorVoucher',
      field: ErrorOrigins.SERVER_SIDE_ERROR,
      message: data.message,
      voucherCode: data.voucherCode
    })
  }
  return mappedData
}

const redeemVoucherErrorMapper = (data) => {
  let dataObject = null
  if (data) {
    dataObject = {
      event: data.eventName ? data.eventName : '',
      eventAction: data.message ? data.message : '',
      eventLabel: data.field ? data.field : '',
      eventValue: data.voucherCode ? data.voucherCode : ''
    }
  }
  return dataObject
}
