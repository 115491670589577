import pusher from '../pusher'
import * as eventTypes from '../../../../js/document/event-types'
import { loginStatusMapper } from '../mappers/login-status-mapper'

export function listenLoginStatusEvents (event) {
  event.on(eventTypes.loginStatusEvents.LOADED, (data) => {
    const mappedData = loginStatusMapper(data)
    pusher.pushDataObject(mappedData)
  })
}
