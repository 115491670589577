import { CUSTOM_PARAMETERS } from '../../constants'

export const accommodationNavMapper = data => {
  let dataObject = null

  if (data) {
    const dataresult = data.path ? data.path[0] : null
    dataObject = {
      event: 'PDP',
      [CUSTOM_PARAMETERS.parameter_2]:
        'Click to anchor nav menu, on ' + dataresult, // description
      [CUSTOM_PARAMETERS.parameter_3]: 'Clickable text', // element
      [CUSTOM_PARAMETERS.parameter_4]: dataresult, // type
      [CUSTOM_PARAMETERS.parameter_5]: 'Goes to section of ' + dataresult, // result
      [CUSTOM_PARAMETERS.parameter_6]: true // interaction
    }
  }
  return dataObject
}
