import { registerWidget } from '../../../js/core/widget/widget-directory'
import * as cssHelper from '../../../js/document/css'
import Backdrop from '../../components/backdrop/main'
import registeredEvents from '../../../js/helpers/registered-events'
import { mainMenuEvents } from '../../../js/document/event-types'

const EventEmitter = require('eventemitter3')
const widgetApi = 'w-main-menu'
const buttonAPI = 'c-btn'
const sideDrawerApi = 'c-side-drawer'

const widgetQueries = {
  desktopContentWrapper: `data-${widgetApi}__desktop--inside`,
  desktopMiddleLayerContentWrapper: `data-${widgetApi}__desktop--inside--middle-layer`,
  desktopMiddleLayerItem: `data-${widgetApi}__desktop--middle-layer--item`,
  desktopMiddleLayerItemElement: `data-${widgetApi}__item--middle-layer--item`,
  desktopMiddleLayerItemBtn: `data-${widgetApi}__item--middle-layer--btn`,
  desktopMiddleLayerItemId: `data-${widgetApi}__desktop--inside--middle-layer--item--id`,
  desktopRightColumnContentWrapper: `data-${widgetApi}__desktop--inside--right-column--content`,
  desktopSectionId: `data-${widgetApi}__item--section-id`,
  desktopParentId: `data-${widgetApi}__parent-id`,
  desktopItemHasChild: `data-${widgetApi}__item--has-child`,
  desktopItemExtra: `data-${widgetApi}__item--extra`,
  desktopItemSecondLevel: `data-${widgetApi}__item--second-level`,
  desktopItemSecondLevelHasChild: `data-${widgetApi}__item--second-level--has-child`,
  desktopItemRightColumn: `data-${widgetApi}__desktop--inside--right-column--item`,
  desktopWrapperSecondLevelId: `data-${widgetApi}__item--child-id`,
  mobileTitleSection: `data-${widgetApi}__mobile--main-items--section-title`,
  btnOpenSideDrawer: `data-${widgetApi}__side-drawer-open`,
  btnCloseSideDrawer: `data-${widgetApi}__side-drawer-close`,
  mobileMenuItem: `data-${widgetApi}__mobile--nav-item`,
  mobileCurrentLevel: 'data-c-nav__level'
}

const classNames = {
  isActive: 'is-active',
  isOpen: 'is-open',
  isOpened: 'is-opened',
  backdropMenu: 'c-backdrop__main-menu'
}

export const attr = {
  track: 'data-track',
  trace: 'data-trace'
}

export default class MainMenu {
  /**
   * Creates a new my main menu.
   *
   * @constructor
   * @param {HTMLElement} element - The HTML element.
   */
  constructor (element) {
    if (!element) {
      return
    }
    this.element = element
    this.desktopContentWrapper = this.element.querySelector(`[${widgetQueries.desktopContentWrapper}]`)
    this.backdrop = null
    this._transitionTime = cssHelper.cssTimeToMs(cssHelper.getStyle(this.element, 'transition-duration') || '0.3s') // fallback transition time
    this.sideDrawer = document.querySelector(`[data-js-component=${sideDrawerApi}]`)
    this.btnOpenSideDrawer = this.element.querySelector(`[${widgetQueries.btnOpenSideDrawer}]`)
    this.btnCloseSideDrawer = this.element.querySelector(`[${widgetQueries.btnCloseSideDrawer}]`)
    this.desktopMenuElementWithChild = this.element.querySelector(`[${widgetQueries.desktopItemHasChild}]`)
    this.desktopItemExtra = this.element.querySelectorAll(`[${widgetQueries.desktopItemExtra}]`)

    this.events = new EventEmitter()
    registeredEvents.registerWidgetEvents(widgetApi, this.events, {
      ...this.element.hasAttribute(attr.track) && { track: this.element.attributes[attr.track].value }
    })

    this._init()
  }

  _init () {
    this._setEvents()
  }

  _setEvents () {
    // Desktop
    if (this.desktopMenuElementWithChild) {
      this.desktopMenuElementWithChild.addEventListener('click', () =>
        this._handleElementWithChildClick(this.desktopMenuElementWithChild)
      )
    }

    // Desktop items once opened
    const elementSecondLevelWithChild = this.element.querySelectorAll(`[${widgetQueries.desktopItemSecondLevelHasChild}]`)

    elementSecondLevelWithChild.forEach(element => {
      element.addEventListener('click', () =>
        this._handleElementSecondLevelWithChildClick(element)
      )
    })

    const elementMiddleLayerItem = this.element.querySelectorAll(`[${widgetQueries.desktopMiddleLayerItem}]`)

    elementMiddleLayerItem.forEach(element => {
      element.addEventListener('click', () =>
        this._handleElementMiddleLevelWithChildClick(element)
      )
    })

    this._setEventsSideDrawer()
    this._setEventsEmitter()
  }

  _setEventsSideDrawer () {
    // Side Drawer events
    if (this.sideDrawer) {
      this.sideDrawer[sideDrawerApi].events.on('open', () => {
        this.btnOpenSideDrawer.classList.remove(classNames.isActive)
        this.btnCloseSideDrawer.classList.add(classNames.isActive)
      })

      this.sideDrawer[sideDrawerApi].events.on('close', () => {
        this.btnOpenSideDrawer.classList.add(classNames.isActive)
        this.btnCloseSideDrawer.classList.remove(classNames.isActive)
      })
    }

    // In order to avoid closing the side drawer when clicking on the title area
    // of each section we stop the propagation of the event
    const titleElements = this.element.querySelectorAll(`[${widgetQueries.mobileTitleSection}]`)

    if (titleElements) {
      titleElements.forEach(element => {
        const linkElement = element.querySelector('a')
        if (linkElement) {
          linkElement.addEventListener('click', e => {
            e.stopPropagation()
          })
        }
      })
    }
  }

  // Events of the second level of the menu (desktop)
  _handleElementSecondLevelWithChildClick (element) {
    const sectionId = element.getAttribute(widgetQueries.desktopSectionId)

    this._cleanSecondLevelActive()
    this._setNavMenuSecondLevelActive(sectionId)
  }

  _cleanSecondLevelActive () {
    const elementSecondLevelWithChild = this.element.querySelectorAll(`[${widgetQueries.desktopItemSecondLevelHasChild}]`)
    elementSecondLevelWithChild.forEach(element => {
      element.classList.remove(classNames.isActive)
    })

    const elementMiddleLayerWrapper = this.element.querySelectorAll(`[${widgetQueries.desktopMiddleLayerContentWrapper}]`)
    elementMiddleLayerWrapper.forEach(element => {
      element.classList.remove(classNames.isActive)
    })

    const elementMiddleItem = this.element.querySelectorAll(`[${widgetQueries.desktopMiddleLayerItem}]`)
    elementMiddleItem.forEach(element => {
      element.classList.remove(classNames.isActive)
    })

    const elementRightColumnWrapper = this.element.querySelectorAll(`[${widgetQueries.desktopRightColumnContentWrapper}]`)
    elementRightColumnWrapper.forEach(element => {
      element.classList.remove(classNames.isActive)
    })
  }

  _setNavMenuSecondLevelActive (id) {
    const elementSecondLevelWithChild = this.element.querySelectorAll(`[${widgetQueries.desktopItemSecondLevelHasChild}]`)
    elementSecondLevelWithChild.forEach(element => {
      if (element.getAttribute(widgetQueries.desktopSectionId) === id) {
        element.classList.add(classNames.isActive)
      }
    })

    const elementMiddleLayerWrapper = this.element.querySelectorAll(`[${widgetQueries.desktopMiddleLayerContentWrapper}]`)
    elementMiddleLayerWrapper.forEach(element => {
      if (element.getAttribute(widgetQueries.desktopParentId) === id) {
        element.classList.add(classNames.isActive)
        element.focus()
      }
    })
  }

  // Events of the middle layer of the menu (desktop)
  _handleElementMiddleLevelWithChildClick (element) {
    const sectionId = element.getAttribute(widgetQueries.desktopSectionId)

    this._cleanMiddleLevelActive()
    this._setNavMenuMiddleLevelActive(sectionId)
  }

  _cleanMiddleLevelActive () {
    const elementMiddleLayerWrapper = this.element.querySelectorAll(`[${widgetQueries.desktopMiddleLayerItem}]`)
    elementMiddleLayerWrapper.forEach(element => {
      element.classList.remove(classNames.isActive)
    })

    const elementRightColumnWrapper = this.element.querySelectorAll(`[${widgetQueries.desktopRightColumnContentWrapper}]`)
    elementRightColumnWrapper.forEach(element => {
      element.classList.remove(classNames.isActive)
    })
  }

  _setNavMenuMiddleLevelActive (id) {
    const elementMiddleLayerWrapper = this.element.querySelectorAll(`[${widgetQueries.desktopMiddleLayerItem}]`)
    elementMiddleLayerWrapper.forEach(element => {
      if (element.getAttribute(widgetQueries.desktopSectionId) === id) {
        element.classList.add(classNames.isActive)
      }
    })

    const elementRightColumnWrapper = this.element.querySelectorAll(`[${widgetQueries.desktopRightColumnContentWrapper}]`)
    elementRightColumnWrapper.forEach(element => {
      if (element.getAttribute(widgetQueries.desktopParentId) === id) {
        element.classList.add(classNames.isActive)
        element.focus()
      }
    })
  }

  // Events of the first level of the menu (desktop)
  _handleElementWithChildClick (element) {
    if (element.classList.contains(classNames.isActive)) {
      element.classList.remove(classNames.isActive)
      element.ariaExpanded = false
      this._closeWrapper()
    } else {
      this._removeActiveClass()
      element.classList.add(classNames.isActive)
      element.ariaExpanded = true
      this._openWrapper()
    }
  }

  _removeActiveClass () {
    const elementWithChild = this.element.querySelectorAll(
      `[${widgetQueries.desktopItemHasChild}]`
    )
    elementWithChild.forEach(element => {
      element.classList.remove(classNames.isActive)
      element.ariaExpanded = false
    })
  }

  async _openWrapper () {
    return new Promise(resolve => {
      this._openBackdrop()
      this.desktopContentWrapper.classList.add(classNames.isOpen)
      this.desktopContentWrapper.focus()

      setTimeout(() => {
        resolve()
      }, this._transitionTime)
    })
  }

  async _closeWrapper () {
    return new Promise(resolve => {
      this.desktopContentWrapper.classList.remove(classNames.isOpen)
      this._closeBackdrop()
      this.desktopMenuElementWithChild.focus()

      setTimeout(() => {
        resolve()
      }, this._transitionTime)
    })
  }

  _openBackdrop () {
    this.backdrop = Backdrop()
    this.backdrop.element.classList.add(classNames.backdropMenu)
    this.backdrop.open()
    this.backdrop.element.addEventListener('click', () => this._closeWrapper())
    document.addEventListener('keydown', ({ key }) => {
      if (key === 'Escape') {
        if (this.desktopContentWrapper.classList.contains(classNames.isOpen)) {
          this._closeWrapper()
        }
      }
    })
  }

  _closeBackdrop () {
    this.backdrop.element.removeEventListener('click', () => this.close())
    this.backdrop.close(this.backdrop.destroy)
    this._removeActiveClass()
  }

  // Events emitted by the component
  _setEventsEmitter () {
    // Destop
    // first level
    if (this.desktopMenuElementWithChild) {
      this.desktopMenuElementWithChild.addEventListener('click', () => {
        const eventData = {
          text: this.desktopMenuElementWithChild[buttonAPI].getText(),
          isFinalLink: false,
          itemsTrace: this.desktopMenuElementWithChild.getAttribute(attr.trace)
        }
        if (this.desktopMenuElementWithChild.getAttribute('aria-expanded') === 'true') this._sendItemClickedEvent(eventData)
      }
      )
    }
    if (this.desktopItemExtra) {
      this.desktopItemExtra.forEach(element => {
        element.addEventListener('click', () => {
          const eventData = {
            text: element[buttonAPI].getText(),
            isFinalLink: true,
            itemsTrace: element.getAttribute(attr.trace)
          }
          this._sendItemClickedEvent(eventData)
        }
        )
      })
    }

    // second level
    const elementSecondLevel = this.element.querySelectorAll(`[${widgetQueries.desktopItemSecondLevel}]`)

    elementSecondLevel.forEach(element => {
      element.addEventListener('click', () => {
        const eventData = {
          text: element[buttonAPI].getText(),
          isFinalLink: (!element.hasAttribute(widgetQueries.desktopItemSecondLevelHasChild)),
          itemsTrace: element.getAttribute(attr.trace)
        }
        this._sendItemClickedEvent(eventData)
      }
      )
    })

    // third level
    const elementThirdLevel = this.element.querySelectorAll(`[${widgetQueries.desktopMiddleLayerItemElement}]`)

    elementThirdLevel.forEach(element => {
      element.addEventListener('click', () => {
        const eventData = {
          text: element[buttonAPI].getText(),
          isFinalLink: (!element.hasAttribute(widgetQueries.desktopMiddleLayerItemBtn)),
          itemsTrace: element.getAttribute(attr.trace)
        }
        this._sendItemClickedEvent(eventData)
      }
      )
    })

    // fourth level
    const elementFourthLevel = this.element.querySelectorAll(`[${widgetQueries.desktopItemRightColumn}]`)

    elementFourthLevel.forEach(element => {
      element.addEventListener('click', () => {
        const eventData = {
          text: element[buttonAPI].getText(),
          isFinalLink: true,
          itemsTrace: element.getAttribute(attr.trace)
        }
        this._sendItemClickedEvent(eventData)
      }
      )
    })

    // Mobile
    // first level
    const elementMobileItem = this.element.querySelectorAll(`[${widgetQueries.mobileMenuItem}]`)
    elementMobileItem.forEach(element => {
      element.addEventListener('click', () => {
        const eventData = {
          text: this._getTextFromMobileItem(element),
          isFinalLink: (!!element.href),
          itemsTrace: this._buildMobileTrace(element)
        }
        this._sendItemClickedEvent(eventData)
      }
      )
    })
  }

  _buildMobileTrace (element) {
    const parentList = element.closest(`.${classNames.isOpened}`)
    if (!parentList) {
      if (this.desktopMenuElementWithChild) {
        return (this.desktopMenuElementWithChild.getAttribute(attr.trace) + ' > ' + this._getTextFromMobileItem(element))
      } else {
        return this._getTextFromMobileItem(element)
      }
    } else {
      const currentLevelRaw = this._getCurrentLevelMobile(parentList)
      const currentLevel = parseInt(currentLevelRaw)
      let trace = ''

      for (let index = 1; index <= currentLevel; index++) {
        const parentWrapper = this.element.querySelector(`[${widgetQueries.mobileCurrentLevel}="${index}"]`)
        if (parentWrapper) {
          const textSection = parentWrapper.querySelector(`[${widgetQueries.mobileTitleSection}]`)
            ? this._getTextFromMobileItem(parentWrapper.querySelector(`[${widgetQueries.mobileTitleSection}]`))
            : ''
          trace += textSection
          if (index < currentLevel) trace += ' > '
        }
      }

      return trace + ' > ' + this._getTextFromMobileItem(element)
    }
  }

  _getTextFromMobileItem (element) {
    return element.querySelector('.c-nav__link-text') ? element.querySelector('.c-nav__link-text').innerText : ''
  }

  _getCurrentLevelMobile (element) {
    return element.querySelector('.c-nav__list') ? element.querySelector('.c-nav__list').getAttribute(widgetQueries.mobileCurrentLevel) : ''
  }

  _sendItemClickedEvent (data) {
    this.events.emit(mainMenuEvents.MAIN_MENU_ITEM_CLICKED, data)
  }
}

registerWidget(MainMenu, widgetApi)
