import { apiCaller } from '../../../js/helpers/api-caller'
import { registerWidget } from '../../../js/core/widget/widget-directory'
import { PreferenceQuestionTemplate, checkboxContentWithFlag } from './w-preference-questionnaire__question.template'
import { PreferenceSummaryQuestionTemplate } from './w-preference-questionnaire__summary-question.template'
import { elementFromString, flush } from '../../../js/document/html-helper'
import { getAbsoluteUrl, getSearchParamsFromUrl } from '../../../js/document/url'
import Component from '../../../js/core/component/component'
import registeredEvents from '../../../js/helpers/registered-events'
import { preferenceQuestionnaireEvents } from '../../../js/document/event-types'
const EventEmitter = require('eventemitter3')

const classNames = {
  widgetApi: 'w-preference-questionnaire',
  opened: 'in',
  choiceList: 'c-choice-list'
}

const DIRECTION = {
  BACKWARD: 'backward',
  FORWARD: 'forward'
}

export const widgetQueries = {
  settings: `[data-${classNames.widgetApi}__settings]`,
  startBtn: `[data-${classNames.widgetApi}__start-btn]`,
  backBtn: `[data-${classNames.widgetApi}__back-btn]`,
  nextBtn: `[data-${classNames.widgetApi}__next-btn]`,
  sendBtn: `[data-${classNames.widgetApi}__send-btn]`,
  summaryBtn: `[data-${classNames.widgetApi}__summary-btn]`,
  startMessage: `[data-${classNames.widgetApi}__start-message]`,
  successMessage: `[data-${classNames.widgetApi}__success-message]`,
  errorMessage: `[data-${classNames.widgetApi}__error-message]`,
  question: `data-${classNames.widgetApi}__question`,
  conditionalQuestion: `data-${classNames.widgetApi}__conditional-question`,
  conditionedQuestion: `data-${classNames.widgetApi}__conditioned-question`,
  conditionedQuestionDisabled: `data-${classNames.widgetApi}__conditioned-question-disabled`,
  questionChoiceList: `[data-${classNames.widgetApi}__options]`,
  progressBar: `[data-${classNames.widgetApi}__progress-bar]`,
  requestUrl: `data-${classNames.widgetApi}__request-url`,
  postUrl: `data-${classNames.widgetApi}__post-url`,
  questionnaireContainer: `[data-${classNames.widgetApi}__questionnaire-container]`,
  questionsContainer: `[data-${classNames.widgetApi}__questions-container]`,
  loaderContainer: `[data-${classNames.widgetApi}__start-loader-container]`,
  summaryContainer: `[data-${classNames.widgetApi}__summary-container]`,
  summaryQuestionsContainer: `[data-${classNames.widgetApi}__summary-questions-container]`,
  summaryEditQuestionnaireBtn: `[data-${classNames.widgetApi}__summary-questionnaire-edit]`,
  summaryEditQuestionBtn: `data-${classNames.widgetApi}__summary-question-edit`,
  comesTroughEmail: `data-${classNames.widgetApi}__comes-through-email`,
  disableTracking: `data-${classNames.widgetApi}__tracking-disabled`,
  trackAttr: 'data-track'
}

export default class PreferenceQuestionnaire {
  /**
  * Creates a new preference questionnaire widget.
  * @constructor
  * @param {HTMLElement} element The HTML element.
  */
  constructor (element) {
    this.element = element
    this.events = new EventEmitter()
    registeredEvents.registerWidgetEvents(classNames.widgetApi, this.events, {
      ...this.element.hasAttribute(widgetQueries.trackAttr) && { track: this.element.attributes[widgetQueries.trackAttr].value }
    })
    this._getHtmlElements()
    this._openSlide(this.loaderContainer)
    const requestedPreferenceQuestions = this._requestPreferenceQuestions(this.requestUrl)
    requestedPreferenceQuestions.then(response => {
      this.response = response
      this._renderQuestions(this.response.data)
      this.questions = this.element.querySelectorAll(`[${widgetQueries.question}]`)
      this._setupConditionalQuestions()
      this._renderQuestionsSummary(this.response)
      this._showProgressBar()
      this._attachEvents()
      this._questionnaireThroughMail()
      this.isOnEditOneQuestionMode = false
      if (this._questionnaireHasBeenAnswered()) {
        this._openSlide(this.summaryContainer)
        this._disableTracking()
      } else {
        this._openSlide(this.startMessage)
      }
      this._toggleBtn(this.startBtnApi)
    }).catch(() => {
      this._openSlide(this.errorMessage)
    })
  }

  /**
  * Get the necessary elements from the html
  */
  _getHtmlElements () {
    this.settings = this.element.querySelector(widgetQueries.settings)
    this.startBtn = this.element.querySelector(widgetQueries.startBtn)
    this.startBtnApi = this.startBtn['c-btn']
    this.backBtn = this.element.querySelector(widgetQueries.backBtn)
    this.nextBtn = this.element.querySelector(widgetQueries.nextBtn)
    this.sendBtn = this.element.querySelector(widgetQueries.sendBtn)
    this.sendBtnApi = this.sendBtn['c-btn']
    this.summaryBtn = this.element.querySelector(widgetQueries.summaryBtn)
    this.startMessage = this.element.querySelector(widgetQueries.startMessage)
    this.successMessage = this.element.querySelector(widgetQueries.successMessage)
    this.errorMessage = this.element.querySelector(widgetQueries.errorMessage)
    this.progressBar = this.element.querySelector(widgetQueries.progressBar)
    this.progressProgressBar = this.element.querySelector(`${widgetQueries.progressBar} progress`)
    this.requestUrl = this.element.getAttribute(widgetQueries.requestUrl)
    this.postUrl = this.element.getAttribute(widgetQueries.postUrl)
    this.questionnaireContainer = this.element.querySelector(widgetQueries.questionnaireContainer)
    this.questionsContainer = this.element.querySelector(widgetQueries.questionsContainer)
    this.loaderContainer = this.element.querySelector(widgetQueries.loaderContainer)
    this.summaryContainer = this.element.querySelector(widgetQueries.summaryContainer)
    this.summaryQuestionsContainer = this.element.querySelector(widgetQueries.summaryQuestionsContainer)
    this.summaryEditQuestionnaireBtn = this.element.querySelector(widgetQueries.summaryEditQuestionnaireBtn)
  }

  /**
  * Get the Settings from the DOM
  * @return {Object}
  */
  _getSettingsDataFromDom () {
    if (!this.settings) return false
    return JSON.parse(this.settings.textContent)
  }

  /**
  * Attach the necessary events
  */
  _attachEvents () {
    if (this.startBtn) this.startBtn.addEventListener('click', this._startQuestionnaire.bind(this))
    if (this.backBtn) this.backBtn.addEventListener('click', this._goToPreviousQuestion.bind(this))
    if (this.nextBtn) this.nextBtn.addEventListener('click', this._goToNextQuestion.bind(this))
    if (this.sendBtn) this.sendBtn.addEventListener('click', this._sendBtnClicked.bind(this))
    if (this.summaryBtn) this.summaryBtn.addEventListener('click', this._showSummary.bind(this))
    if (this.summaryEditQuestionnaireBtn) this.summaryEditQuestionnaireBtn.addEventListener('click', this._editQuestionnaire.bind(this))
    this._attachSummaryEvents()
  }

  /**
  * get the next question number on both directions that aren't disabled
  * @param {HTMLElement} screen from which question number start to check
  * @param {String} direction backward/forward
  * @return {Number | null}
  *
  */
  _getNextNotDisabledNumberQuestion (numberOfQuestion, direction) {
    const backwardLimit = num => direction === DIRECTION.BACKWARD && num < 0
    const forwardLimit = num => direction === DIRECTION.FORWARD && num > this.questions.length

    if (!this.questions[numberOfQuestion]) return

    while (this.questions[numberOfQuestion].hasAttribute(widgetQueries.conditionedQuestionDisabled)) {
      if (direction === DIRECTION.FORWARD) numberOfQuestion++
      if (direction === DIRECTION.BACKWARD) numberOfQuestion--
      if (backwardLimit(numberOfQuestion) || forwardLimit(numberOfQuestion)) break
    }

    if (backwardLimit(numberOfQuestion) || forwardLimit(numberOfQuestion)) return

    return numberOfQuestion
  }

  /**
  * Start the preference questionnaire
  */
  _startQuestionnaire () {
    this.backBtn.classList.add(classNames.opened)
    const numberOfQuestion = this._getNextNotDisabledNumberQuestion(0, DIRECTION.FORWARD)
    if (isNaN(numberOfQuestion)) {
      this._openSlide(this.errorMessage)
      return
    }
    this._openSlide(this.questionnaireContainer)
    this._openQuestion(this.questions[numberOfQuestion])
  }

  /**
  * Go to the next question of the questionnaire
  */
  _goToNextQuestion () {
    let numberOfQuestion = [...this.questions].indexOf(this.activeQuestion) + 1
    numberOfQuestion = this._getNextNotDisabledNumberQuestion(numberOfQuestion, DIRECTION.FORWARD)
    if (isNaN(numberOfQuestion)) {
      this._openSlide(this.errorMessage)
      return
    }
    this._openQuestion(this.questions[numberOfQuestion])
  }

  /**
  * Go to the previous question of the questionnaire
  */
  _goToPreviousQuestion () {
    let numberOfQuestion = [...this.questions].indexOf(this.activeQuestion) - 1
    numberOfQuestion = this._getNextNotDisabledNumberQuestion(numberOfQuestion, DIRECTION.BACKWARD)
    if (isNaN(numberOfQuestion)) {
      this._openSlide(this.errorMessage)
      return
    }
    this._openQuestion(this.questions[numberOfQuestion])
  }

  /**
  * send button clicked
  */
  _sendBtnClicked () {
    this._toggleBtn(this.sendBtnApi)
    const sendQuestionnaireResponse = this._sendQuestionnaire()
    sendQuestionnaireResponse.then(response => {
      if (!response.success) {
        this._openSlide(this.errorMessage)
        return
      }
      this._openSlide(this.successMessage)
      this._toggleBtn(this.sendBtnApi)
    }).catch(() => {
      this._openSlide(this.errorMessage)
    })
  }

  /**
  * Disable the start and sent tracking
  */
  _disableTracking () {
    this.startBtn.setAttribute(widgetQueries.disableTracking, '')
    this.sendBtn.setAttribute(widgetQueries.disableTracking, '')
  }

  /**
  * Show the summary
  */
  _showSummary () {
    this._disableTracking()
    this._updateQuestionsSummary(this.response)
    this._openSlide(this.summaryContainer)
  }

  /**
  * Edit the questionnaire
  */
  _editQuestionnaire () {
    this.events.emit(preferenceQuestionnaireEvents.PREFERENCE_QUESTIONNAIRE_EDIT, { questionValue: 'all' })
    this._showProgressBar()
    this._openSlide(this.startMessage)
  }

  /**
  * Open the slide passed
  * @param {HTMLElement} screen The HTML slide
  */
  _openSlide (screen) {
    if (this.activeScreen) {
      this.activeScreen.classList.remove(classNames.opened)
    }
    this.activeScreen = screen
    screen.classList.add(classNames.opened)
  }

  /**
  * Open the question passed and update the different UI elements
  * @param {HTMLElement} question The HTML question
  */
  _openQuestion (question) {
    if (this.activeQuestion) this.activeQuestion.classList.remove(classNames.opened)
    this.activeQuestion = question
    question.classList.add(classNames.opened)
    this._updateProgressBar()
    this._setStateBackBtn()
    this._setStateNextAndSendBtn()
    this._toggleNextAndSendBtn()
  }

  /**
  * get the question number you indicated
  * @param {number} questionNumber the number question you want to open
  * @return {HTMLElement}
  */
  _getQuestion (questionNumber) {
    const question = [...this.questions].filter(question => {
      return parseInt(question.getAttribute(widgetQueries.question)) === questionNumber
    })
    if (!question.length) return ''
    return question[0]
  }

  /**
  * Disable and unable the back btn depending on the active question
  */
  _setStateBackBtn () {
    const firstQuestion = this._getNextNotDisabledNumberQuestion(0, DIRECTION.FORWARD)
    if (isNaN(firstQuestion)) {
      this._openSlide(this.errorMessage)
      return
    }
    if ([...this.questions].indexOf(this.activeQuestion) !== firstQuestion) {
      this.backBtn.disabled = false
      return
    }
    this.backBtn.disabled = true
  }

  /**
  * Set the state of the next and send btn depending if the conditional question has been answered
  */
  _setStateNextAndSendBtn () {
    if (!this.activeQuestion.hasAttribute(widgetQueries.conditionalQuestion)) {
      this.nextBtn.disabled = false
      this.sendBtn.disabled = false
      return
    }
    const choiceListApi = this.activeQuestion.querySelector(widgetQueries.questionChoiceList)[classNames.choiceList]
    if (!choiceListApi.getSelectedValues().length) {
      this.nextBtn.disabled = true
      this.sendBtn.disabled = true
    } else {
      this.nextBtn.disabled = false
      this.sendBtn.disabled = false
    }
  }

  /**
  * Show/hide the next and send btn depending on the active question
  */
  _toggleNextAndSendBtn () {
    const lastQuestion = this._getNextNotDisabledNumberQuestion(this.questions.length - 1, DIRECTION.BACKWARD)
    if (isNaN(lastQuestion)) {
      this._openSlide(this.errorMessage)
      return
    }
    if ([...this.questions].indexOf(this.activeQuestion) === lastQuestion || this.isOnEditOneQuestionMode) {
      this.nextBtn.classList.remove(classNames.opened)
      this.sendBtn.classList.add(classNames.opened)
      return
    }
    this.nextBtn.classList.add(classNames.opened)
    this.sendBtn.classList.remove(classNames.opened)
  }

  /**
  * Show the progress bar if there is more than one question
  */
  _showProgressBar () {
    const questionNotDisabled = [...this.questions].filter(question => !question.hasAttribute(widgetQueries.conditionedQuestionDisabled))
    if (questionNotDisabled.length > 1 && this.progressBar && !this.isOnEditOneQuestionMode) {
      this.progressBar.classList.add(classNames.opened)
    } else {
      this.progressBar.classList.remove(classNames.opened)
    }
  }

  /**
  * Update the progress bar depending on the active question is displayed
  */
  _updateProgressBar () {
    const questionNotDisabled = [...this.questions].filter(question => !question.hasAttribute(widgetQueries.conditionedQuestionDisabled))
    if (questionNotDisabled.length <= 1) return
    const numberOfQuestions = questionNotDisabled.length
    const activeQuestion = questionNotDisabled.indexOf(this.activeQuestion)
    const progressBarValue = ((parseInt(activeQuestion) + 1) / numberOfQuestions) * 100
    if (this.progressProgressBar) this.progressProgressBar.value = progressBarValue
  }

  /**
  * Update the questionnaire data
  */
  _updateQuestionnaireData () {
    this.response.data.forEach(responseQuestionData => {
      const question = [...this.questions].filter(question => {
        return question.querySelector(`.${classNames.choiceList}[id='${responseQuestionData.id}']`)
      })
      if (!question) return
      const questionCheckedValues = question[0].querySelector(widgetQueries.questionChoiceList)[classNames.choiceList].getSelectedValues()
      responseQuestionData.answers.forEach(answer => {
        if (questionCheckedValues.includes(answer.value)) {
          answer.checked = true
        } else {
          answer.checked = false
        }
      })
    })
  }

  /**
  * Prepare the data and send the questionnaire
  * @return {bool} true if the data has been send successfully or false if something went wrong
  */
  async _sendQuestionnaire () {
    this._updateQuestionnaireData()
    const questionsData = this.response.data.map(questionData => {
      const selectedValues = questionData.answers.filter(answer => answer.checked === true).map(answerSelected => answerSelected.value)
      return {
        type: questionData.value,
        values: selectedValues
      }
    })
    return await apiCaller(`${this.postUrl}${this._getParameters(this.postUrl)}`, {
      body: { preferences: questionsData },
      method: 'POST'
    })
  }

  /**
  * Renders the preference questions
  * @param {Object} data
  */
  _renderQuestions (data) {
    data.forEach((questionData, index) => {
      const options = []
      const allAnswersHasIllustrations = this._allAnswersHasIllustrations(questionData.answers)
      const someAnswersHasFlags = this._someAnswersHasFlags(questionData.answers)

      questionData.answers.forEach(answerData => {
        const answerDataIllustration = {}
        const htmlCheckbox = {}
        if (allAnswersHasIllustrations) {
          answerDataIllustration.dataset = {}
          answerDataIllustration.dataset.illustrationSrc = answerData.illustrationSrc
          answerDataIllustration.dataset.illustrationAlt = answerData.title
        }

        if (answerData.countryCode && this._getChoiceListMethod(questionData.type) === 'multiple') {
          htmlCheckbox.html = checkboxContentWithFlag(answerData.title, answerData.countryCode)
        }

        const answer = {
          ...htmlCheckbox,
          ...answerDataIllustration,
          id: answerData.id,
          name: answerData.title,
          value: answerData.value,
          text: answerData.title,
          checked: answerData.checked
        }
        options.push(answer)
      })

      const preferenceQuestionHtml = PreferenceQuestionTemplate({
        someAnswersHasFlags,
        allAnswersHasIllustrations,
        id: questionData.id,
        value: questionData.value,
        titleText: questionData.title,
        titleIcon: questionData.icon,
        infoText: questionData.infoText,
        index: index + 1,
        method: this._getChoiceListMethod(questionData.type),
        options,
        conditional: questionData.conditional,
        conditioned: questionData.conditioned
      })

      const preferenceQuestionElement = elementFromString(preferenceQuestionHtml)
      Component.initDocumentComponentsFromAPI(preferenceQuestionElement)
      this.questionsContainer.appendChild(preferenceQuestionElement)
    })
  }

  /**
  * Renders the preference questions summary
  * @param {Object} response
  */
  _renderQuestionsSummary (response) {
    const settingsData = this._getSettingsDataFromDom()
    response.data.forEach((questionData, index) => {
      const answers = []
      const questionDisabled = [...this.questions].filter(question => {
        if (parseInt(question.getAttribute(widgetQueries.question)) !== index + 1) return false
        return question.hasAttribute(widgetQueries.conditionedQuestionDisabled)
      })
      if (questionDisabled.length) return
      questionData.answers.forEach(answerData => {
        if (!answerData.checked) return
        answers.push(answerData.title)
      })

      const preferenceSummaryQuestionHtml = PreferenceSummaryQuestionTemplate({
        titleText: questionData.title,
        titleIcon: questionData.icon,
        index: index + 1,
        editBtnText: settingsData.summaryEditBtnText,
        answers,
        noAnswerText: settingsData.summaryNoAnswerText,
        noAnswerBtn: settingsData.summaryNoAnswerBtn
      })

      const preferenceSummaryQuestionElement = elementFromString(preferenceSummaryQuestionHtml)
      this.summaryQuestionsContainer.appendChild(preferenceSummaryQuestionElement)
    })
  }

  /**
  * Update the questions summary
  * @param {Object} response
  */
  _updateQuestionsSummary (response) {
    flush(this.summaryQuestionsContainer)
    this.isOnEditOneQuestionMode = false
    this._renderQuestionsSummary(response)
    this._attachSummaryEvents()
  }

  /**
  * Attach the events for the different links on the questionnaire summary
  */
  _attachSummaryEvents () {
    const summaryEditQuestionButtons = this.summaryContainer.querySelectorAll(`[${widgetQueries.summaryEditQuestionBtn}]`)
    summaryEditQuestionButtons.forEach(summaryEditQuestionButton => {
      summaryEditQuestionButton.addEventListener('click', event => {
        this.isOnEditOneQuestionMode = true
        event.preventDefault()
        const questionIndex = parseInt(summaryEditQuestionButton.getAttribute(widgetQueries.summaryEditQuestionBtn))
        this.events.emit(preferenceQuestionnaireEvents.PREFERENCE_QUESTIONNAIRE_EDIT, { questionValue: this._getValueQuestion(questionIndex) })
        this.progressBar.classList.remove(classNames.opened)
        this.backBtn.classList.remove(classNames.opened)
        this._openSlide.bind(this, this.questionnaireContainer)()
        this._openQuestion.bind(this, this._getQuestion(questionIndex), true)()
      })
    })
  }

  /**
  * Get the value question with the questionNumber
  * @param {number} questionNumber
  * @return {string}
  */
  _getValueQuestion (questionNumber) {
    const responseQuestion = this.response.data[questionNumber - 1]
    if (!responseQuestion && !responseQuestion.value) return ''
    return responseQuestion.value
  }

  /**
  * Setup the conditional questions
  */
  _setupConditionalQuestions () {
    this.conditionalsValues = [];
    [...this.questions].forEach(question => {
      if (!question.hasAttribute(widgetQueries.conditionalQuestion)) return
      const choiceListConditionalQuestion = question.querySelector(`.${classNames.choiceList}`)
      this.conditionalsValues.push(...choiceListConditionalQuestion[classNames.choiceList].getSelectedValues())
      this._attachConditionalQuestionsEvents(choiceListConditionalQuestion)
    })
    this._disableConditionedQuestions()
  }

  /**
  * Attach the events for the the conditional question
  */
  _attachConditionalQuestionsEvents (choiceList) {
    choiceList[classNames.choiceList].events.on('changeOptions', (options) => {
      const conditionalValuesSelected = options.filter(option => option.checked).map(option => option.value)
      const conditionalValuesUnselected = options.filter(option => !option.checked).map(option => option.value)
      // New set removes duplicate and the filter removes the items unchecked
      this.conditionalsValues = [...new Set([...this.conditionalsValues, ...conditionalValuesSelected]
        .filter(conditionalsValue => !conditionalValuesUnselected.includes(conditionalsValue)))]
      this._disableConditionedQuestions()
      this._setStateBackBtn()
      this._setStateNextAndSendBtn()
      this._toggleNextAndSendBtn()
      this._showProgressBar()
      this._updateProgressBar()
    })
  }

  /**
  * Add disable attr to the conditioned questions if necessary
  */
  _disableConditionedQuestions () {
    this.questions.forEach(question => {
      const conditionedFor = question.getAttribute(widgetQueries.conditionedQuestion)
      if (!conditionedFor) return
      if (!this.conditionalsValues.includes(conditionedFor)) {
        question.setAttribute(widgetQueries.conditionedQuestionDisabled, '')
        const choiceListApi = question.querySelector(widgetQueries.questionChoiceList)[classNames.choiceList]
        choiceListApi.setProp('options', choiceListApi.getProp('options').map(option => ({ ...option, checked: false })))
      } else {
        question.removeAttribute(widgetQueries.conditionedQuestionDisabled)
      }
    })
  }

  /**
  * Returns the method for the choice list
  * @param {String} type
  * @return {String | null}
  */
  _getChoiceListMethod (type) {
    switch (type.toLocaleLowerCase()) {
      case 'singleselect':
        return 'single'
      case 'multiselect':
        return 'multiple'
      default:
        return null
    }
  }

  /**
  * Returns true if all passed answer has illustrations
  * @param {Array} answers
  * @return {Bool}
  */
  _allAnswersHasIllustrations (answers) {
    return answers.map(answerData => {
      return answerData.illustrationSrc ? answerData.illustrationSrc : false
    }).every(answersHasIllustrations => {
      return !!answersHasIllustrations === true
    })
  }

  /**
  * Returns true if some passed answer has country flags
  * @param {Array} answers
  * @return {Bool}
  */
  _someAnswersHasFlags (answers) {
    return answers.map(answerData => {
      return answerData.countryCode ? answerData.countryCode : false
    }).some(answersHasFlags => {
      return !!answersHasFlags === true
    })
  }

  /**
  * Toggle the  button passed state from: disabled and loading to not disabled and not loading
  */
  _toggleBtn (btn) {
    btn.setProps({
      disabled: !btn.getProp('disabled'),
      loading: !btn.getProp('loading')
    })
  }

  /**
  * Returns the requested preference questions
  * @param {String} requestUrl
  * @return {Promise}
  */
  async _requestPreferenceQuestions (requestUrl) {
    if (!requestUrl) return null
    const result = await apiCaller(`${requestUrl}${this._getParameters(requestUrl)}`)
    if (!result.success || !result.response) return null
    return result.response
  }

  /**
  * Returns true if a string url has parameters
  * @param {String} stringUrl
  * @return {Bool}
  */
  _stringUrlHasParameters (stringUrl) {
    try {
      const url = new URL(getAbsoluteUrl(stringUrl))
      const parametersUrl = url.search
      if (parametersUrl) return true
      return false
    } catch {
      return false
    }
  }

  /**
  * return parameters from url formatted
  * @param {String} stringUrl
  * @return {String}
  */
  _getParameters (stringUrl) {
    if (!document.location.search) return ''
    return this._stringUrlHasParameters(stringUrl) ? `&${document.location.search.substring(1)}` : document.location.search
  }

  /**
  * Show the summary or the preference questionnaire depending of the questionnaire has been already answered
  * @return {Bool}
  */
  _questionnaireHasBeenAnswered () {
    return this.response.data.some(question => {
      return question.answers.some(answer => Object.prototype.hasOwnProperty.call(answer, 'checked'))
    })
  }

  /**
  * Add attributes to different elements of the questionnaire if a questionnaire comes through the mail
  * @return {Bool}
  */
  _questionnaireThroughMail () {
    const params = getSearchParamsFromUrl(document.location)
    if (!params || !params.email) return
    this.startBtn.setAttribute(widgetQueries.comesTroughEmail, '')
    this.sendBtn.setAttribute(widgetQueries.comesTroughEmail, '')
  }
}

registerWidget(PreferenceQuestionnaire, classNames.widgetApi)
