import * as types from './types'
import * as eventTypes from '../../../js/document/event-types'

class TypeChecker {
  isSearchFilter (type) {
    return Object.values(types.searchFilterType).includes(type)
  }

  isQuickSearchFilter (type) {
    return Object.values(types.quickSearchFilterType).includes(type)
  }

  isBookingGateFilter (type) {
    return Object.values(types.bookingGateFilterType).includes(type)
  }

  isBookingStepsComponent (type) {
    return Object.values(types.bookingStepsType).includes(type)
  }

  isBookingStepsPreferences (type) {
    return types.bookingPreferences === type
  }

  isAccommodationContent (type) {
    return Object.values(types.staticContentType).includes(type)
  }

  isAccommodationPromotedPrice (type) {
    return Object.values(types.promotedPriceType).includes(type)
  }

  isAccommodationDestination (type) {
    return Object.values(types.accommodationDestinationType).includes(type)
  }

  isAccommodationDescription (type) {
    return Object.values(types.accommodationDescriptionType).includes(type)
  }

  isAccommodationNav (type) {
    return Object.values(types.accommodationNavType).includes(type)
  }

  isAccommodationRecommendation (type) {
    return Object.values(types.accommodationRecommendationType).includes(type)
  }

  isAccommodationTabGoBackBtn (type) {
    return Object.values(types.accommodationTabsType).includes(type)
  }

  isPayment (type) {
    return types.paymentType === type
  }

  isLiveSearchComponent (type) {
    return Object.values(types.liveSearchType).includes(type)
  }

  isAccountBookingComponent (type) {
    return Object.values(types.accountBookingType).includes(type)
  }

  isAccountBookingsComponent (type) {
    return Object.values(types.accountBookingsType).includes(type)
  }

  isRefund (type) {
    return types.refundType === type
  }

  isFaqTopQuestionsComponent (type) {
    return Object.values(eventTypes.faqTopQuestionsEvents).includes(type)
  }

  isHeaderNavigation (type) {
    return Object.values(types.headerNavigationType).includes(type)
  }

  isBookingOptionalAncillariesPopup (type) {
    return type === types.bookingOptionalAncillariesPopup
  }

  isBookingErrorModal (type) {
    return type === types.bookingErrorModal
  }

  isMainNavigation (type) {
    return types.headerNavigationType.MAIN === type
  }

  isMobileNavigation (type) {
    return types.headerNavigationType.MOBILE === type
  }

  isLoginForm (type) {
    return Object.values(eventTypes.loginFormEvents).includes(type)
  }

  isRebookingOptions (type) {
    return Object.values(eventTypes.rebookingOptionsEvents).includes(type)
  }

  isPreferenceQuestionnaire (type) {
    return Object.values(eventTypes.preferenceQuestionnaireEvents).includes(type)
  }

  isProfileData (type) {
    return Object.values(eventTypes.profileDataEvents).includes(type)
  }

  isPromotionImage (type) {
    return Object.values(types.promotionImageType).includes(type)
  }

  isSkiService (type) {
    return types.bookingStepsType.SKI_SERVICE === type
  }

  isAccommodationRoomtypesComponent (type) {
    return Object.values(types.accommodationRoomtypesType).includes(type)
  }

  isAccommodationTabsComponent (type) {
    return Object.values(types.accommodationTabsType).includes(type)
  }

  isAccommodationFacilitiesComponent (type) {
    return Object.values(types.accommodationFacilitiesType).includes(type)
  }

  isAccommodationMealplansComponent (type) {
    return Object.values(types.accommodationMealplansType).includes(type)
  }

  isAccommodationPracticalBlockComponent (type) {
    return Object.values(types.accommodationPracticalBlockType).includes(type)
  }

  isAccommodationSkiBlockComponent (type) {
    return Object.values(types.accommodationSkiBlockType).includes(type)
  }

  isAccommodationGalleryComponent (type) {
    return Object.values(types.accommodationGalleryType).includes(type)
  }

  isBookingCarRental (type) {
    return Object.values(types.bookingCarRentalType).includes(type)
  }

  isPermissionSettings (type) {
    return Object.values(eventTypes.permissionSettingsEvents).includes(type)
  }

  isReviewsListComponent (type) {
    return Object.values(types.reviewsListType).includes(type)
  }

  isReviewsTeaserComponent (type) {
    return Object.values(types.reviewsTeaserType).includes(type)
  }

  isReviewsTeaserPopup (type) {
    return Object.values(types.reviewsTeaserType).includes(type)
  }
}

export default new TypeChecker()
