import { bookedServiceEditEvents as events } from '../../../../../js/document/event-types'
import { CUSTOM_PARAMETERS } from '../../constants'

export const accountBookedServiceEditMapper = (data, event) => {
  let dataObject = null
  if (data && event) {
    const eventData = getEventData(data, event)

    if (eventData && eventData.type) {
      dataObject = {
        event: 'Error',
        ...eventData
      }
    }
  }
  return dataObject
}

/**
* Get the event label, event category and event action depending on the event name
* @param {Object} data
* @param {String} event
* @returns {Object}
*/
function getEventData (data, event) {
  switch (event) {
    case events.CONDITIONS_VALIDATION_FAILED:
      if (!data.serviceName) return {}
      return {
        [CUSTOM_PARAMETERS.parameter_1]: false, // validated
        [CUSTOM_PARAMETERS.parameter_2]: 'choose an option for terms and conditions', // description
        [CUSTOM_PARAMETERS.parameter_3]: data.fieldType || '', // element
        [CUSTOM_PARAMETERS.parameter_4]: data.serviceName.toLowerCase() || '' // type
      }

    case events.SAVE_CHANGES_TO_BOOKING_FAILED:
      if (!data.error || !data.error.message) return {}
      return {
        [CUSTOM_PARAMETERS.parameter_1]: data.success, // validated
        [CUSTOM_PARAMETERS.parameter_2]: data.error.message || '', // description
        [CUSTOM_PARAMETERS.parameter_3]: data.fieldType, // element
        [CUSTOM_PARAMETERS.parameter_4]: 'error_message_service', // type
        [CUSTOM_PARAMETERS.parameter_5]: 'action_save' // result
      }

    case events.API_CALL_FAILED:
      if (!data.error || !data.error.message) return {}
      return {
        [CUSTOM_PARAMETERS.parameter_1]: data.success, // validated
        [CUSTOM_PARAMETERS.parameter_2]: data.error.message || '', // description
        [CUSTOM_PARAMETERS.parameter_3]: 'page', // element
        [CUSTOM_PARAMETERS.parameter_4]: 'error_message_service', // type
        [CUSTOM_PARAMETERS.parameter_5]: 'action_open' // result
      }

    default:
      return {}
  }
}
