export const partyCompositionGroupFormater = (participants) => {
  let nAdults = 0
  let nChildren = 0
  let nBabies = 0
  if (participants && participants.length > 0) {
    let actual = new Date(Date.now())
    const adult = actual.setFullYear(actual.getFullYear() - 17)
    actual = new Date(Date.now())
    const child = actual.setFullYear(actual.getFullYear() - 2)
    participants.forEach(p => {
      const pDate = Date.parse(p)
      if (pDate <= adult) {
        nAdults++
      } else if (pDate <= child) {
        nChildren++
      } else {
        nBabies++
      }
    })
  }
  return `${nAdults}a ${nChildren}c ${nBabies}b`
}

export const partyCompositionParticipantsFormater = (participants) => {
  let nParticipants = ''
  if (participants) {
    nParticipants = participants.split(' ')[0]
  }
  return nParticipants
}
