import { searchFilterType, quickSearchFilterType, bookingGateFilterType } from '../types'
import { filterSelectedData, dropdownSimpleSelectedData } from '../helpers/basic-components-tranformation-helper'
import { FILTER_TYPES } from '../../../../js/data/filters/config'

export const filtersSections = {
  SEARCH: 'search',
  QUICK_SEARCH: 'quicksearch',
  BOOKING_GATE: 'bookingGate'
}

const dataLayersToIncludeInFilter = [
  { datalayer: 'partyComposition', element: 'participants selector', filterTypes: [FILTER_TYPES.PARTICIPANTS, searchFilterType.PARTICIPANTS] },
  { datalayer: 'country', element: 'checkbox', filterTypes: [FILTER_TYPES.COUNTRY, searchFilterType.COUNTRY] },
  { datalayer: 'region', element: 'checkbox', filterTypes: [FILTER_TYPES.REGION, searchFilterType.REGION] },
  { datalayer: 'city', element: 'checkbox', filterTypes: [FILTER_TYPES.CITY, searchFilterType.CITY] },
  { datalayer: 'departureDate', element: 'calendar', eventType: 'departure date', filterTypes: [FILTER_TYPES.DEPARTURE_DATE, searchFilterType.DEPARTURE_DATE], isRangeAccepted: true },
  { datalayer: 'duration', element: 'checkbox', filterTypes: [FILTER_TYPES.DURATION, searchFilterType.DURATION], useValue: true },
  { datalayer: 'price', element: 'slider', filterTypes: [FILTER_TYPES.PRICE_PER_PERSON, searchFilterType.PRICE_PER_PERSON], isRangeAccepted: true },
  { datalayer: 'airports', element: 'checkbox', filterTypes: [FILTER_TYPES.DEPARTURE_AIRPORT, searchFilterType.AIRPORTS], useValue: true },
  { datalayer: 'arrivalAirports', element: 'checkbox', filterTypes: [FILTER_TYPES.ARRIVAL_AIRPORT, searchFilterType.ARRIVAL_AIRPORTS], useValue: true },
  { datalayer: 'boardType', element: 'checkbox', filterTypes: [FILTER_TYPES.MEALPLAN, searchFilterType.MEALPLAN], useValue: true },
  { datalayer: 'number of rooms', element: 'radio button', filterTypes: [FILTER_TYPES.ROOM_COUNT, searchFilterType.ROOM_COUNT], useValue: true },
  { datalayer: 'ski area minimum altitude meters', element: 'checkbox', filterTypes: [FILTER_TYPES.SKI_AREA_MINIMUM_ALTITUDE_METERS, searchFilterType.SKI_MIN_ALTITUDE], useValue: true },
  { datalayer: 'bathroom count', element: 'checkbox', filterTypes: [FILTER_TYPES.BATHROOM_COUNT, searchFilterType.BATHROOM_COUNT], useValue: true },
  { datalayer: 'general facility', element: 'checkbox', filterTypes: [FILTER_TYPES.FACILITIES, searchFilterType.GENERAL_FACILITIES], useValue: true },
  { datalayer: 'internet facility', element: 'checkbox', filterTypes: [FILTER_TYPES.INTERNET_FACILITIES, searchFilterType.INTERNET_FACILITIES], useValue: true },
  { datalayer: 'children facility', element: 'checkbox', filterTypes: [FILTER_TYPES.CHILDREN_FACILITIES, searchFilterType.CHILDREN_FACILITIES], useValue: true },
  { datalayer: 'wifi facility', element: 'checkbox', filterTypes: [FILTER_TYPES.IS_WIFI_FREE, searchFilterType.WIFI_FACILITY], useValue: true },
  { datalayer: 'recommended', element: 'checkbox', filterTypes: [FILTER_TYPES.RECOMMENDED, searchFilterType.RECOMMENDED], useValue: true },
  { datalayer: 'campaigns', element: 'checkbox', filterTypes: [FILTER_TYPES.CAMPAIGNS, searchFilterType.CAMPAIGNS], useValue: true },
  { datalayer: 'themes', element: 'checkbox', filterTypes: [FILTER_TYPES.THEMES, searchFilterType.THEMES], useValue: true },
  { datalayer: 'departureWeeks', element: 'checkbox', eventType: 'departure weeks', filterTypes: [FILTER_TYPES.LAST_MINUTE, searchFilterType.LASTMINUTE], useValue: true },
  { datalayer: 'accoType', element: 'checkbox', eventType: 'accomodation type', filterTypes: [FILTER_TYPES.ACCOMMODATION_TYPE, searchFilterType.ACCOMMODATION_TYPE], useValue: true },
  { datalayer: 'ratings', element: 'checkbox', filterTypes: [FILTER_TYPES.CUSTOMERS_RATING, searchFilterType.CUSTOMER_RATING], useValue: true },
  { datalayer: 'distance from city center', element: 'checkbox', filterTypes: [FILTER_TYPES.METERS_TO_CENTER, searchFilterType.CENTER_DISTANCE], useValue: true },
  { datalayer: 'distance from slope', element: 'checkbox', filterTypes: [FILTER_TYPES.METERS_TO_SLOPE, searchFilterType.SLOPE_DISTANCE], useValue: true },
  { datalayer: 'distance from ski lift', element: 'checkbox', filterTypes: [FILTER_TYPES.METERS_TO_SKILIFT, searchFilterType.SKI_LIFT_DISTANCE], useValue: true },
  { datalayer: 'distance from sea', element: 'checkbox', filterTypes: [FILTER_TYPES.METERS_TO_BEACH, searchFilterType.BEACH_DISTANCE], useValue: true },
  { datalayer: 'discount', element: 'checkbox', filterTypes: [FILTER_TYPES.DISCOUNT, searchFilterType.DISCOUNT], useValue: true },
  { datalayer: 'beds in livingroom', element: 'checkbox', filterTypes: [FILTER_TYPES.BEDS_IN_LIVING_ROOM, searchFilterType.BEDS_LIVINGROOM], useValue: true },
  { datalayer: 'ski pass included', element: 'checkbox', filterTypes: [FILTER_TYPES.SKI_PASS_INCLUDED, searchFilterType.SKI__PASS_INCLUDED], useValue: true },
  { datalayer: 'ski area slopes kms', element: 'checkbox', filterTypes: [FILTER_TYPES.SKI_AREA_SLOPE_KMS, searchFilterType.SKI_AREA_KMS], useValue: true },
  { datalayer: 'has availability', element: 'checkbox', filterTypes: [FILTER_TYPES.HAS_AVAILABILITY, searchFilterType.HAS_AVAILABILITY], useValue: true },
  { datalayer: 'flight only', element: 'radio button', filterTypes: [FILTER_TYPES.FLIGHT_ONLY, searchFilterType.FLIGHT_ONLY], useValue: true },
  { datalayer: 'stars', element: 'radio button', filterTypes: [FILTER_TYPES.STARS, searchFilterType.STARS], useValue: true },
  { datalayer: 'labels', element: 'checkbox', filterTypes: [FILTER_TYPES.COLLECTION, searchFilterType.COLLECTION], useValue: true },
  { datalayer: 'transport', element: 'radio button', filterTypes: [FILTER_TYPES.TRANSPORT_TYPE, searchFilterType.TRANSPORT], useValue: true },
  { datalayer: 'airline', element: 'checkbox', filterTypes: [FILTER_TYPES.AIRLINE, searchFilterType.AIRLINE], useValue: true },
  { datalayer: 'destination', element: 'dropdown', filterTypes: [FILTER_TYPES.DESTINATION, searchFilterType.DESTINATION] }
]

const dataLayersToIncludeInQuickFilter = [
  { datalayer: 'destination', element: 'dropdown', filterTypes: [FILTER_TYPES.DESTINATION, quickSearchFilterType.DESTINATION] },
  { datalayer: 'departureDate', element: 'calendar', eventType: 'departure date', filterTypes: [FILTER_TYPES.DEPARTURE_DATE, quickSearchFilterType.DEPARTURE_DATE], isRangeAccepted: true },
  { datalayer: 'duration', element: 'checkbox', filterTypes: [FILTER_TYPES.DURATION, quickSearchFilterType.DURATION], useValue: true },
  { datalayer: 'airports', element: 'checkbox', filterTypes: [FILTER_TYPES.DEPARTURE_AIRPORT, quickSearchFilterType.AIRPORTS] },
  { datalayer: 'arrivalAirports', element: 'checkbox', filterTypes: [FILTER_TYPES.ARRIVAL_AIRPORT, quickSearchFilterType.ARRIVAL_AIRPORTS] },
  { datalayer: 'partyComposition', element: 'participants selector', filterTypes: [FILTER_TYPES.PARTICIPANTS, quickSearchFilterType.PARTICIPANTS] },
  { datalayer: 'transport', element: 'dropdown', filterTypes: [FILTER_TYPES.TRANSPORT_TYPE, quickSearchFilterType.TRANSPORT], useValue: true }
]

const dataLayersToIncludeInBookingGateFilter = [
  { datalayer: 'partyComposition', element: 'participants selector', filterTypes: [FILTER_TYPES.PARTICIPANTS, bookingGateFilterType.PARTICIPANTS] },
  { datalayer: 'month', element: 'tab', filterTypes: [FILTER_TYPES.MONTH, bookingGateFilterType.MONTH], useValue: true },
  { datalayer: 'durations', element: 'checkbox', filterTypes: [FILTER_TYPES.DURATIONS, bookingGateFilterType.DURATIONS], useValue: true },
  { datalayer: 'airports', element: 'checkbox', filterTypes: ['Airports', bookingGateFilterType.AIRPORTS], useValue: true },
  { datalayer: 'boardType', element: 'checkbox', filterTypes: [FILTER_TYPES.MEALPLAN, bookingGateFilterType.MEALPLAN], useValue: true },
  { datalayer: 'roomType', element: 'checkbox', filterTypes: [FILTER_TYPES.ROOM_TYPE, bookingGateFilterType.ROOM_TYPES], useValue: false },
  { datalayer: 'transport', element: 'dropdown', filterTypes: [FILTER_TYPES.TRANSPORT_TYPE, bookingGateFilterType.TRANSPORT], useValue: true }
]

export const getDatalayerName = (filterType, filtersSection) => {
  const filterConfiguration = getFiltersConfiguration(filtersSection)
  const record = filterConfiguration.find(records => records.filterTypes.includes(filterType)) || null
  return record ? record.datalayer : null
}

export const formatRange = (filterType, value, filtersSection) => {
  const filterConfiguration = getFiltersConfiguration(filtersSection)
  let result = value
  if (value) {
    value = value.map(v => v.toLowerCase())
    const record = filterConfiguration.find(records => records.filterTypes.includes(filterType)) || null
    if (record && record.isRangeAccepted && value.length > 1) {
      result = `${value[0].replace(/\s+/g, '')} ${value[value.length - 1].replace(/\s+/g, '')}`
    } else {
      result = value.join(',')
    }
  }
  return result
}

export const getEventData = (filterType, filtersSection) => {
  const filterConfiguration = getFiltersConfiguration(filtersSection)
  let result = null
  const record = filterConfiguration.find(records => records.filterTypes.includes(filterType)) || null
  if (record) {
    result = {
      eventType: record.eventType || record.datalayer,
      element: record.element
    }
  }
  return result ?? {}
}

export const formatParticipantsData = (data) => {
  return Object.entries(data).map(([key, val]) => {
    return key.charAt(0) + val.length
  })
}

export const formatDataFromChipFilters = (data) => {
  if (data && data.models) {
    return data.models
      .filter(m => m.attributes.isSelected)
      .map(m => m.attributes.caption)
  }

  return []
}

export const filterAndMapSelectedData = (data, track, filtersSection) => {
  return filterSelectedData(data, useValue(track, filtersSection))
}

export const filterAndMapSelectedDropdownData = (data, track, filtersSection) => {
  return dropdownSimpleSelectedData(data, useValue(track, filtersSection))
}

export const useValue = (filterType, filtersSection) => {
  const filterConfiguration = getFiltersConfiguration(filtersSection)
  const record = filterConfiguration.find(records => records.filterTypes.includes(filterType)) || null
  if (record) {
    return record.useValue ? record.useValue : false
  } else {
    return false
  }
}

const getFiltersConfiguration = (filtersSection) => {
  switch (filtersSection) {
    case filtersSections.SEARCH:
      return dataLayersToIncludeInFilter

    case filtersSections.QUICK_SEARCH:
      return dataLayersToIncludeInQuickFilter

    case filtersSections.BOOKING_GATE:
      return dataLayersToIncludeInBookingGateFilter

    default:
      return []
  }
}
