import { ga4Mapper } from '../ga4-mapper'
import * as types from '../../types'

export const bookingCarRentalEquipmentsMapper = (data) => {
  let dataObject = null
  if (data && data.options) {
    const currentSelectedOptions = data.options.filter(option => option.checked)
    const currentSelectedOptionIds = currentSelectedOptions.map(option => option.value)
    const carIdSelected = (data.carItemSelectedData && data.carItemSelectedData.value) || null
    let idsToBePushed = []
    let action = ''
    if (data.previousSelectedEquipmentIds && data.previousSelectedEquipmentIds.length > currentSelectedOptionIds.length) {
      idsToBePushed = getDifferenceBetweenEquipments(data.previousSelectedEquipmentIds, currentSelectedOptionIds)
      action = 'equipment_remove'
    } else {
      idsToBePushed = getDifferenceBetweenEquipments(currentSelectedOptionIds, data.previousSelectedEquipmentIds)
      action = 'equipment_add'
    }
    dataObject = mapEquipments(data, idsToBePushed, carIdSelected, action)
  }
  return dataObject
}

export const bookingCarRentalCreditCardMapper = (data) => {
  let dataObject = null
  if (data) {
    const carIdSelected = (data.carItemSelectedData && data.carItemSelectedData.value) || null
    const action = (data.creditCardSelectedValues && data.creditCardSelectedValues.length > 0) ? 'checked' : 'unchecked'
    dataObject = mapCreditCard(carIdSelected, action)
  }
  return dataObject
}

export const bookingCarRentalButtonsMapper = (track, data) => {
  let dataObject = null
  if (data && track) {
    const carId = (data.attr && data.attr.wBookingCarRental__carCode) || ''
    dataObject = mapBookingCarRentalButton(carId, track)
  }
  return dataObject
}

export const bookingCarRentalCollapseMapper = (data, eventName) => {
  let dataObject = null
  if (data && (eventName === 'open' || eventName === 'close') && data.attr && data.attr.wBookingCarRental__collapseId) {
    const result = (eventName === 'open' ? 'show more' : (eventName === 'close' ? 'show less' : null))
    dataObject = mapBookingCarRental({ description: 'Open component', element: 'Collapse', type: data.attr.wBookingCarRental__collapseId, result, interaction: true })
  }
  return dataObject
}

export const bookingCarRentalSliderButtonMapper = (data) => {
  let dataObject = null
  if (data && data.carListId && data.buttonDirection) {
    const result = data.buttonDirection === 'prev' ? 'move to previous slide' : 'move to next slide'
    dataObject = mapBookingCarRental({ description: 'Slider Navigation', element: 'slider', type: data.carListId, result, interaction: true })
  }
  return dataObject
}

const getDifferenceBetweenEquipments = (array1, array2) => {
  const uniqIds =
    array1.length > 0
      ? array1.filter(element => {
        return !array2.includes(element)
      })
      : array2
  return uniqIds
}

const getEquipmentTexts = (options, idsToBePushed) => {
  if (!idsToBePushed) return
  return options.filter(option => idsToBePushed.includes(option.value)).map(option => option.text).join(',')
}

const mapEquipments = (data, idsToBePushed, carId, action) => {
  if (!carId) return null
  return ga4Mapper({
    event: 'Equipment Selection',
    description: getEquipmentTexts(data.options, idsToBePushed),
    element: 'checkbox',
    type: carId,
    result: action,
    validated: true
  })
}

const mapCreditCard = (carId, action) => {
  if (!carId) return null
  return {
    ...mapBookingCarRental({ description: 'CreditCard clicked', element: 'checkbox', type: carId, result: action, interaction: true, validated: true })
  }
}

const mapBookingCarRentalButton = (carId, track) => {
  const { description, result } = getCarRentalButtonDescriptionAndResult(track)

  return {
    ...mapBookingCarRental({ description, element: 'button', type: carId, result, interaction: true })
  }
}

const mapBookingCarRental = (data) => {
  const { description, element, type, result, interaction, validated } = data
  return ga4Mapper({
    event: 'Car',
    description,
    element,
    type,
    result,
    interaction,
    validated
  })
}

export const getCarRentalButtonDescriptionAndResult = (track) => {
  switch (track) {
    case types.bookingCarRentalType.UPGRADE:
      return { description: 'upgrade button clicked', result: 'upgrade' }

    case types.bookingCarRentalType.REMOVE:
      return { description: 'remove button clicked', result: 'remove' }

    case types.bookingCarRentalType.EDIT:
      return { description: 'edit button clicked', result: 'edit' }

    case types.bookingCarRentalType.CANCEL:
      return { description: 'cancel button clicked', result: 'cancel modifications' }

    case types.bookingCarRentalType.CONDITIONS:
      return { description: 'car conditions link clicked', result: 'conditions page opened' }

    default:
      return { description: '', result: '' }
  }
}
