import { ga4Mapper } from '../mappers/ga4-mapper'

export const serviceNotAvailableMapper = (data) => {
  let dataObject = null
  if (data && data.serviceType) {
    dataObject = ga4Mapper({
      event: 'Ancillaries',
      description: 'ancillary not available',
      element: 'element not available',
      result: data.serviceType,
      interaction: false
    })
  }
  return dataObject
}
