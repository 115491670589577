import * as eventTypes from '../../../../js/document/event-types'
import { accommodationRoomtypesMapper } from '../mappers/accommodation-roomtypes/accommodation-roomtypes-mapper'
import { accommodationRoomtypesCarouselMapper } from '../mappers/accommodation-roomtypes/accommodation-roomtypes-carousel-mapper'
import pusher from '../pusher'

export function listenCollapseEvents (event, track) {
  event.on(eventTypes.collapseEvents.OPEN, data => {
    const mappedData = accommodationRoomtypesMapper(
      data,
      eventTypes.collapseEvents.OPEN,
      track
    )
    pusher.pushDataObject(mappedData)
  })

  event.on(eventTypes.collapseEvents.CLOSE, data => {
    const mappedData = accommodationRoomtypesMapper(
      data,
      eventTypes.collapseEvents.CLOSE,
      track
    )
    pusher.pushDataObject(mappedData)
  })
}

export function listenBtnEvents (event, track) {
  event.on('clickButton', element => {
    const mappedData = accommodationRoomtypesCarouselMapper(element, track)
    pusher.pushDataObject(mappedData)
  })
}
