import storage from '../../../js/document/web-storage'

const cookieNames = {
  userData: 'user-data'
}

export const getProfileData = () => {
  const userDataCookie = storage.cookie.get(cookieNames.userData)

  if (!isValidUserDataCookie(userDataCookie)) {
    return { isUserLoggedIn: false }
  }

  const profileData = base64DecodeStrToObj(userDataCookie.profileData)

  if (!profileData) {
    return { isUserLoggedIn: false }
  }

  return {
    isUserLoggedIn: true,
    profileName: profileData.profileName || '',
    profileId: profileData.userId || ''
  }
}

const isValidUserDataCookie = (userData) => {
  return userData &&
    userData.profileData &&
    userData.expirationDateTime &&
    new Date(userData.expirationDateTime) > new Date()
}

const base64DecodeStrToObj = (str) => {
  let decodedObj = {}
  try {
    const base64data = window.decodeURIComponent(str)
    decodedObj = JSON.parse(base64ToUtf8String(base64data))
  } catch (e) {
    if (window.newrelic) {
      window.newrelic.noticeError(e)
    }
  }
  return decodedObj
}

const base64ToUtf8String = (base64) => {
  const binString = atob(base64)
  const bytes = window.Uint8Array.from(binString, (m) => m.codePointAt(0))
  return new window.TextDecoder().decode(bytes)
}
