import { ga4Mapper } from '../ga4-mapper'

export const bookingErrorMapper = (data) => {
  if (data) {
    return ga4Mapper({
      event: 'Error',
      validated: !data.hasError,
      description: data.description || 'without error message',
      element: data.element || 'page',
      type: 'error_message_booking_steps',
      result: 'action_step_open',
      ...(data.interaction === true || data.interaction === false) && { interaction: data.interaction }
    })
  }
  return null
}

export const bookingErrorModalButtonMapper = (track) => {
  if (!track) { return null }
  return ga4Mapper({
    event: 'Error', // Not sure if this has to be the event name
    description: 'error popup',
    element: 'button',
    result: 'button clicked',
    type: track,
    interaction: true
  })
}
