import { CUSTOM_PARAMETERS } from '../constants'

export const serviceAvailableMapper = (data) => {
  if (data && data.serviceName) {
    return {
      event: 'Ancillaries',
      [CUSTOM_PARAMETERS.parameter_2]: 'ancillary available', // description
      [CUSTOM_PARAMETERS.parameter_3]: 'element available', // element
      [CUSTOM_PARAMETERS.parameter_5]: data.serviceName, // result
      [CUSTOM_PARAMETERS.parameter_6]: false // interaction
    }
  }
  return null
}
