export const impressionsSearchMapper = (impressions) => {
  function extraMap (v) {
    return {
      list: 'search result',
      duration: v.featuredFilters && v.featuredFilters.length > 0 ? v.featuredFilters[0] : '',
      totalGuests: calculateOccupancy(v.occupancyInfo),
      departureDate: v.departureDateDetail ? v.departureDateDetail : ''
    }
  }
  return mapper(impressions, extraMap)
}

export const impressionsAccoListerMapper = (impressions) => {
  function extraMap (v) {
    return {
      id: v.accommodationId ? v.accommodationId : v.id ? v.id : '',
      list: v.isAccoTip ? 'acco-tips' : 'acco lister'
    }
  }
  return mapper(impressions, extraMap)
}

function mapper (impressions, extraMap) {
  const dataObjects = []
  let impressionsObjects = null
  let dataObject = {}
  let index = 1
  if (impressions) {
    impressions.forEach(v => {
      dataObject = {
        ...{
          id: v.id ? v.id : '',
          name: v.name ? v.name : '',
          category: formatLocations(v.countryName, v.regionName, v.cityName),
          position: v.position || index,
          accoStars: v.rating ? v.rating : '',
          badges: v.collection ? v.collection : '',
          rating: v.userRating ? v.userRating : '',
          entryPrice: (v.price && v.price.averagePrice) ? v.price.averagePrice : 0,
          campaignId: (v.price && v.price.yieldCampaignId) ? v.price.yieldCampaignId : '',
          campaignName: (v.price && v.price.yieldCampaignName) ? v.price.yieldCampaignName : '',
          cityId: v.locations?.find(l => l.type === 'city')?.deskId || '',
          countryId: v.locations?.find(l => l.type === 'country')?.deskId || '',
          regionId: v.locations?.find(l => l.type === 'region')?.deskId || ''
        },
        ...extraMap(v)
      }
      dataObjects.push(dataObject)
      index++
    })

    if (dataObjects && (Object.entries(dataObjects).length !== 0)) {
      impressionsObjects = { event: 'eec.impressionView', ecommerce: { impressions: dataObjects } }
    }
  }
  return impressionsObjects
}

export const formatLocations = (country, region, city) => {
  const listOfLocations = []
  if (country) listOfLocations.push(country)
  if (region) listOfLocations.push(region)
  if (city) listOfLocations.push(city)
  return listOfLocations.join(' > ')
}

export const calculateOccupancy = (occupancyInfo) => {
  let numberGuests = 0
  if (occupancyInfo) {
    const adults = occupancyInfo.adultsCount ? occupancyInfo.adultsCount : 0
    const babies = occupancyInfo.babiesCount ? occupancyInfo.babiesCount : 0
    const children = occupancyInfo.childrenCount ? occupancyInfo.childrenCount : 0

    numberGuests = adults + babies + children
  }

  return numberGuests
}
