import { CUSTOM_PARAMETERS } from '../constants'

export const ga4Mapper = (data, extraParams = {}) => {
  if (!data) { return null }
  const { event, validated, description, element, type, result, interaction } = data
  if (!event) { return null }

  return {
    event,
    ...(validated === true || validated === false) && { [CUSTOM_PARAMETERS.parameter_1]: validated },
    ...description && { [CUSTOM_PARAMETERS.parameter_2]: description },
    ...element && { [CUSTOM_PARAMETERS.parameter_3]: element },
    ...type && { [CUSTOM_PARAMETERS.parameter_4]: type },
    ...result && { [CUSTOM_PARAMETERS.parameter_5]: result },
    ...(interaction === true || interaction === false) && { [CUSTOM_PARAMETERS.parameter_6]: interaction },
    ...extraParams
  }
}
