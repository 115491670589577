const widgetQueries = {
  searchCategoryTitle: 'data-live-search__search-title',
  accommodationCategoryTitle: 'data-live-search__accommodation-title',
  informationCategoryTitle: 'data-live-search__information-title',
  flightCategoryTitle: 'data-live-search__flight-title'
}

export const CATEGORIES = {
  SearchPage: 'Destinations',
  Accommodation: 'Accommodations',
  ContentPage: 'Information',
  City: 'Information',
  Subregion: 'Information',
  Region: 'Information',
  Country: 'Information',
  FlightTicket: 'FlightTicket'
}

export function setCategoryTitles (options, element) {
  options.categoryTitles = []
  options.categoryTitles.Destinations = element.getAttribute(widgetQueries.searchCategoryTitle)
  options.categoryTitles.Accommodations = element.getAttribute(widgetQueries.accommodationCategoryTitle)
  options.categoryTitles.Information = element.getAttribute(widgetQueries.informationCategoryTitle)
  options.categoryTitles.FlightTicket = element.getAttribute(widgetQueries.flightCategoryTitle)
}

export function orderCategories (categories) {
  categories = [...new Set(Object.values(CATEGORIES))].reduce((acumulator, currentValue) => {
    if (categories.includes(currentValue)) {
      acumulator.push(currentValue)
    }
    return acumulator
  }, [])
}

export function addCategoryIfNotAlreadyExisting (category, siteType, categories, siteTypes) {
  if (categories.indexOf(category) === -1) {
    categories.push(category)
    siteTypes[category] = siteType
  }
}
