import { ga4Mapper } from './ga4-mapper'

export const FORM_DATA_VALUES = {
  email: 'email',
  whatsapp: 'whatsapp',
  post: 'post',
  account: 'account'
}

export const permissionSettingsMapper = (formData) => {
  let dataObject = null
  const formDataValues = []
  if (formData) {
    formData.forEach(formDataItem => {
      const formDataValueIncluded = Object.values(FORM_DATA_VALUES).filter(formDataValue => {
        return formDataItem.includes(formDataValue)
      })
      formDataValues.push(...formDataValueIncluded)
    })
  }

  if (formDataValues.length) {
    dataObject = dataObject = ga4Mapper({
      event: 'form_submit',
      description: 'Objection form submitted',
      element: 'checkbox',
      interaction: true,
      result: formDataValues.join()
    })
  }

  return dataObject
}
