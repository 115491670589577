// Import helpers, polyfills & utils
require('../../../js/polyfills/main')
require('../../objects/swipe/main')
require('../../../js/locales/_all')
require('../../../js/document/anchors')

// Import components
require('../../components/autocomplete/main')
require('../../components/absolute-dialog/main')
require('../../components/backdrop/main')
require('../../components/btn/main')
require('../../components/button-group/main')
require('../../components/calendar/data-js-api')
require('../../components/card/main')
require('../../components/choice-list/main')
require('../../components/collapse/main')
require('../../components/date-selector/main')
require('../../components/dropdown/main')
require('../../components/dropdown/dropdown-multiple/main')
require('../../components/favorite/button')
require('../../components/favorite/cleaner')
require('../../components/favorite/collector')
require('../../components/floating-box/main')
require('../../components/img/data-js-api')
require('../../components/img-box/main')
require('../../components/modal/main')
require('../../components/modal-v2/main')
require('../../components/nav/main')
require('../../components/number-score/main')
require('../../components/number-stepper/main')
require('../../components/pagination/main')
require('../../components/participants-selector/main')
require('../../components/phone-input/main')
require('../../components/progress-bar/main')
require('../../components/range/main')
require('../../components/side-drawer/main')
require('../../components/slider/main')
require('../../components/tabs/main')
require('../../components/textarea/main')
require('../../components/textbox/main')
require('../../components/tooltip/main')
require('../../components/video/main')
require('../../components/form/data-js-api')
require('../../components/form/main')
require('../../components/actionable-list/main')
require('../../components/video/main')

require('../../../js/core/component/component-api')

// Import widgets
require('../../widgets/data-layer/main')
require('../../widgets/static-content-data/main')
require('../../widgets/acco-lister/main')
require('../../widgets/cookie-consent/main')
require('../../widgets/cookie-emergency-dialog/main')
require('../../widgets/cookie-modal/main')
require('../../widgets/dropdown-redirect-group/main')
require('../../widgets/faq-feedback/main')
require('../../widgets/faq-top-questions/main')
require('../../widgets/language-modal/main')
require('../../widgets/live-search/main')
require('../../widgets/new-live-search/main')
require('../../widgets/login-form/main')
require('../../widgets/reviews-list/data-js-api')
require('../../widgets/dropdown-redirect/main')
require('../../widgets/dynamic-label/main')
require('../../widgets/ski-prices/data-js-api')
require('../../widgets/smart-app-banner/main')
require('../../widgets/voucher/main')
require('../../widgets/voucher-redeemer/main')
require('../../widgets/refund/main')
require('../../widgets/voucher-check-balance/main')
require('../../widgets/ce-login/main')
require('../../widgets/ce-modal/main')
require('../../widgets/ce-welcome-msg/main')
require('../../widgets/preference-questionnaire/main')
require('../../widgets/permission-settings/main')
require('../../widgets/reviews-teaser/main')
require('../../widgets/recommended-acco-lister/main')
require('../../widgets/main-menu/main')
require('../../widgets/account-menu/main')
require('../../widgets/login-status/main')
require('../../widgets/product-ads/main')

// Potentially t-simple-form bundle
require('../../widgets/verify-account/main')

// This should be last
require('../../../js/core/widget/widget-api')

// Autoload all components when the page is ready
const { autoload } = require('../../../js/document/load-queue')
autoload()
