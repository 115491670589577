import { ga4Mapper } from './ga4-mapper'

export const paymentMethodMapper = (data, bookingContext) => {
  let dataObject = null
  if (data) {
    dataObject = {
      event: 'eec.checkout_option',
      ecommerce: {
        checkout_option: {
          actionField: {
            ...bookingContext.step && { step: bookingContext.step },
            option: data.paymentMethod,
            status: `${data.paymentType} ${data.amountPaid}`,
            manual: data.manual,
            isOwned: data.isOwned
          }
        }
      }
    }
  }
  return dataObject
}

export const voucherSelectedMapper = (data, bookingContext) => {
  const dataObject = {
    event: 'eec.checkout_selected',
    ecommerce: {
      checkout_selected: {
        actionField: {
          ...bookingContext.step && { step: bookingContext.step },
          option: 'voucher',
          isOwned: !!((data && data.isOwned))
        }
      }
    }
  }
  return dataObject
}

export const downPaymentCoveredMapper = (data = {}) => {
  if (!data.element || !data.type || !data.result) {
    return null
  }
  return ga4Mapper({
    event: 'rebookingAccount',
    description: 'Down payment covered',
    ...data
  })
}
