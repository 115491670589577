import { ga4Mapper } from '../ga4-mapper'

export const questionToogleMapper = (data, eventName) => {
  let dataObject = null
  if (data && eventName) {
    dataObject = ga4Mapper({
      event: 'faqQuestionInfo',
      result: eventName,
      description: data.attr.questionTitle,
      element: 'button',
      interaction: true
    })
  }
  return dataObject
}
