import { favorite as favoriteEvents } from '../../../components/favorite/event-types'
import { favoritesMapper } from '../mappers/favorites-mapper'
import pusher from '../pusher'

export const listenFavoriteEvents = (event) => {
  event.on(favoriteEvents.FAVORITE_MODAL_CLOSE, ({ staticData }) => {
    const data = {
      ...staticData,
      action: 'Close',
      page: 'PDP'
    }
    const mappedData = favoritesMapper(data)
    pusher.pushDataObject(mappedData)
  })

  event.on(favoriteEvents.FAVORITE_MODAL_OPEN, ({ staticData }) => {
    const data = {
      ...staticData,
      action: 'Open',
      page: 'PDP'
    }
    const mappedData = favoritesMapper(data)
    pusher.pushDataObject(mappedData)
  })

  event.on(favoriteEvents.FAVORITE_LOGIN, ({ staticData }) => {
    const data = {
      ...staticData,
      action: 'Login',
      page: 'PDP'
    }
    const mappedData = favoritesMapper(data)
    pusher.pushDataObject(mappedData)
  })

  event.on(favoriteEvents.FAVORITE_SIGNUP, ({ staticData }) => {
    const data = {
      ...staticData,
      action: 'Signup',
      page: 'PDP'
    }
    const mappedData = favoritesMapper(data)
    pusher.pushDataObject(mappedData)
  })

  event.on(favoriteEvents.FAVORITE_ADD, ({ staticData }) => {
    const data = {
      ...staticData,
      action: 'Add',
      page: 'PDP'
    }
    const mappedData = favoritesMapper(data)
    pusher.pushDataObject(mappedData)
  })

  event.on(favoriteEvents.FAVORITE_REMOVE, ({ staticData }) => {
    const data = {
      ...staticData,
      action: 'Remove',
      page: 'PDP'
    }
    const mappedData = favoritesMapper(data)
    pusher.pushDataObject(mappedData)
  })
}
