import Autocomplete from '../../components/autocomplete/main'
import { registerWidget } from '../../../js/core/widget/widget-directory'
import { getAbsoluteUrl } from '../../../js/document/url'
import { destinationIcons } from '../../../js/data/filters/destinations/icons-config'
import { liveSearchEvents } from '../../../js/document/event-types'
import domEventsHelper from '../../../js/document/dom-events-helper'
import { CategoryBlock } from './category-block.template'
import { CATEGORIES, setCategoryTitles, orderCategories, addCategoryIfNotAlreadyExisting } from './categories'

const widgetApi = 'w-live-search'

const originListParameter = 'originList=live-search'

const widgetQueries = {
  urlAttr: `data-${widgetApi}__url`,
  urlSearchParamAttr: `data-${widgetApi}__url-search-param`,
  useDestinationsLayoutAttr: `data-${widgetApi}__use-destinations-layout`,
  filterAndSortAttr: `data-${widgetApi}__filter-and-sort`,
  extraParams: 'input[type="hidden"]',
  useNewEndpointAttr: `data-${widgetApi}__use-new-endpoint`,
  showAllText: `data-${widgetApi}__show-all-text`,
  showLessText: `data-${widgetApi}__show-less-text`,
  showMoreText: `data-${widgetApi}__show-more-text`,
  cancelText: `data-${widgetApi}__cancel-text`,
  newWidgetClass: 'c-autocomplete--live-search_new',
  asyncSearchMinChars: `data-${widgetApi}__minimum-characters`
}

const attr = {
  track: 'data-track'
}

const defaults = {
  hiddenClassName: 'is-hidden'
}

export default class LiveSearch extends Autocomplete {
  /**
   * Creates a new LiveSearch
   *
   * @constructor
   *
   * @param {HTMLElement} element - The HTML component element.
   * @param {AutocompleteOptions} [options={}] - Options object
   *
   */
  constructor (element, options = {}) {
    // Get url extra params if they're available
    const extraParamsElements = element.querySelectorAll(widgetQueries.extraParams)
    const extraParams = extraParamsElements
      ? [...extraParamsElements].reduce((obj, el) => {
          obj[el.name] = el.value
          return obj
        }, {})
      : undefined

    // Find for autocomplete element
    const autocompleteElement = element.querySelector('[data-js-component="c-autocomplete"]')

    const useNewEndpoint = element.hasAttribute(widgetQueries.useNewEndpointAttr)
    const asyncSearchMinChars = element.getAttribute(widgetQueries.asyncSearchMinChars)
    // Get some url options from root element, and others hardcoded
    const autocompleteOptions = {
      ...{
        url: element.getAttribute(widgetQueries.urlAttr),
        urlSearchParam: element.getAttribute(widgetQueries.urlSearchParamAttr),
        urlParams: extraParams,
        searchAttributes: useNewEndpoint ? ['name'] : ['caption'],
        displayAttributes: useNewEndpoint ? ['name'] : ['caption'],
        emptyShownResults: false,
        disableTextBoxOnBlur: useNewEndpoint,
        asyncSearchMinChars: asyncSearchMinChars > 0 ? asyncSearchMinChars : 1,
        delay: useNewEndpoint ? 300 : 150
      },
      ...defaults,
      ...options
    }

    // Call super
    super(autocompleteElement, autocompleteOptions)

    this.widgetElement = element
    this.options.useDestinationsLayout = this._parseBooleanAttribute(
      element,
      widgetQueries.useDestinationsLayoutAttr,
      true
    )
    this.options.filterAndSort = this._parseBooleanAttribute(element, widgetQueries.filterAndSortAttr, true)
    this.options.useNewEndpoint = useNewEndpoint
    this.options.showAllText = element.getAttribute(widgetQueries.showAllText)
    this.options.showLessText = element.getAttribute(widgetQueries.showLessText)
    this.options.showMoreText = element.getAttribute(widgetQueries.showMoreText)
    this.options.cancelText = element.getAttribute(widgetQueries.cancelText)
    this.options.parentComponentIsForm = this._parentComponentIsForm()
    this.options.attr = attr

    this.categories = []
    this.siteTypes = []
    setCategoryTitles(this.options, element)

    if (this.options.useNewEndpoint) {
      element.classList.add(widgetQueries.newWidgetClass)
    }

    this.getResultsHtml = this.options.useNewEndpoint ? this._getResultsHtml : super.getResultsHtml

    this.events.on('open', () => {
      if (!this.showMoreButton) this._setupHasMoreResultsBtn()
      if (this.resultsElementApi.getProp('opened')) {
        domEventsHelper.detachEvents(this._openedEvents, widgetApi)
        this._openedEvents = [[document, { mouseup: ev => this._handleDomCLick(ev) }]]
        domEventsHelper.attachEvents(this._openedEvents, widgetApi)
      }
    })

    this.events.on('close', () => {
      if (!this.resultsElementApi.getProp('opened')) {
        domEventsHelper.detachEvents(this._openedEvents, widgetApi)
      }
    })

    this.events.on('change', tempSelection => {
      if (!this.options.parentComponentIsForm) return
      if (
        this.dataCache &&
        this.dataCache[tempSelection.text] &&
        !Object.prototype.hasOwnProperty.call(this.dataCache[tempSelection.text], 'hasMoreResults')
      ) {
        this.dataCache[tempSelection.text].hasMoreResults = this.options.hasMoreResults
      }
      this._toggleHasMoreResults()
    })

    // Bind submit event to follow the URL attribute
    this.events.on('submit', selection => {
      if (selection && selection.model && selection.model.attributes && selection.model.attributes.url) {
        const url = getAbsoluteUrl(selection.model.attributes.url)
        const urlWithOrigin = url + (url.includes('?') ? '&' : '?') + originListParameter
        if (!this.element.hasAttribute(attr.track)) {
          window.location.href = urlWithOrigin
        } else {
          this.events.emit(liveSearchEvents.SUBMIT, {
            selection,
            targetUrl: url,
            callback: () => {
              window.location.href = urlWithOrigin
            }
          })
        }
      }
    })

    // Bind known actionElements data-w-live-search__action
    document.querySelectorAll(`[data-w-live-search__id="${element.id}"]`).forEach(e => {
      if (e.matches('[data-w-live-search__action="force-open"]')) {
        e.addEventListener('click', e => {
          this.textBoxInput.focus()
          this.updateResults()
        })
      }
    })
  }

  /**
   * Override processFreshData
   * - Get results array & append missing type attribute
   */
  processFreshData (data) {
    let results = []
    this.options.hasMoreResults = data.hasMoreResults ? data.hasMoreResults : false
    if (this.options.useNewEndpoint) {
      data.results.forEach(result => {
        if (result.searchUrl) {
          results.push({ ...result, url: result.searchUrl, category: CATEGORIES.SearchPage })
          addCategoryIfNotAlreadyExisting(CATEGORIES.SearchPage, result.siteType, this.categories, this.siteTypes)
        }
        const category = CATEGORIES[result.type]
        addCategoryIfNotAlreadyExisting(category, result.siteType, this.categories, this.siteTypes)
        results.push({ ...result, url: result.pageUrl, category })
      })

      orderCategories(this.categories)
    } else {
      results = data.results ? data.results.map(result => ({ ...result })) : []
    }
    return results
  }

  /**
   * Select and add event to the has more result btn
   */
  _setupHasMoreResultsBtn () {
    if (!this.options.parentComponentIsForm) return
    this.showMoreButton = this.resultsElement.querySelector('[data-c-autocomplete__action="show-more"]')
    this.showMoreButton.addEventListener('mousedown', () => {
      this.widgetElement.parentElement.submit()
    })
  }

  /**
   * show or hide the more result button depending if the hasMoreResult property
   */
  _toggleHasMoreResults () {
    if (this.options.data.hasMoreResults) {
      this.showMoreButton.classList.remove(this.options.hiddenClassName)
    } else {
      this.showMoreButton.classList.add(this.options.hiddenClassName)
    }
  }

  /**
   * return true if the parent of the element is a form
   * @returns {Bool}
   */
  _parentComponentIsForm () {
    const parentTag = this.widgetElement.parentElement.tagName.toLowerCase()
    if (parentTag === 'form') return true
    return false
  }

  /*
   * -----------------------------------------------------
   * TEMPLATING RELATED METHODS
   * -----------------------------------------------------
   * */

  _getResultsHtml () {
    return `
    <div class="c-autocomplete__results" >
    ${this.categories
      .map(
        category =>
          `${CategoryBlock(
            category,
            this.currentSearch.resultsShown,
            this.currentSearch.termRegExp,
            this.siteTypes,
            this.options
          )}`
      )
      .join('')}
    </div>
    `
  }

  /**
   * Override getResultHtml
   * - Adding destinationType & icon
   */
  getResultHtml (result) {
    if (this.options.useDestinationsLayout) {
      return `
      <li class="c-autocomplete__result" data-destination-type="${result.getAttribute(
        'type'
      )}" aria-selected="false" data-cid="${result.cid}">
        <i class="m-icon m-icon--${destinationIcons(result.getAttribute('siteType'), result.getAttribute('type'))}"></i>
        <span data-attribute="caption">
          ${result.getAttribute('caption').replace(this.currentSearch.termRegExp, '<mark>$1</mark>')}
        </span>
      </li>
      `
    } else {
      return `
      <li class="c-autocomplete__result"data-cid="${result.cid}">
        <span data-attribute="caption">
          ${result.getAttribute('caption').replace(this.currentSearch.termRegExp, '<mark>$1</mark>')}
        </span>
      </li>
      `
    }
  }

  /**
   * Override getResultsElementHtml
   *
   * @returns {String} The HTML string ready to be converted or appended
   */
  getResultsElementHtml () {
    return `
      <div
        class="c-floating-box c-floating-box--with-gradient c-floating-box--floats-from@${
          this.options.floatsFrom
        } c-autocomplete__floating-box"
        data-c-autocomplete__floating-box=""
        data-js-component="c-floating-box"
        data-c-floating-box__min-height="${this.options.minHeight}"
        data-c-floating-box__max-height="${this.options.maxHeight}">
        <div class="c-floating-box__header"></div>
        <div class="c-floating-box__body">
          <div class="c-floating-box__body-content">
          </div>
        </div>
        <div class="c-floating-box__footer">
          <div class="c-autocomplete__actions u-align--right">
            <button type="button" class="c-btn c-btn--flat-neutral c-autocomplete__show-more is-hidden" data-c-autocomplete__action="show-more">${
              this.options.showMoreText ? this.options.showMoreText : this.locales.showMore
            }</button>
            <button type="button" class="c-btn c-btn--flat-neutral c-autocomplete__cancel" data-c-autocomplete__action="cancel">${
              this.options.cancelText ? this.options.cancelText : this.locales.cancel
            }</button>
          </div>
        </div>
      </div>`
  }

  _handleDomCLick (ev) {
    const el = ev.target
    const shouldCloseFloatingBox =
      el.closest(`.${widgetQueries.newWidgetClass}`) === null || el.classList.contains('c-textbox-element')
    if (shouldCloseFloatingBox) this.removeResultsElement(ev)
  }

  _parseBooleanAttribute (element, attrName, defaultValue) {
    let attrValue = element.getAttribute(attrName)
    if (!attrValue) {
      return defaultValue
    }
    attrValue = attrValue.toLowerCase()
    const value = attrValue === 'true' ? true : attrValue === 'false' ? false : defaultValue

    return value
  }
}

registerWidget(LiveSearch, widgetApi)
