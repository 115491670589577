import storage from '../../../js/document/web-storage'
import { toCamelCase } from '../../../js/helpers/string'
import { registerWidget } from '../../../js/core/widget/widget-directory'

const widgetApi = 'w-cookie-consent'

const widgetQueries = {
  goBackButton: `data-${widgetApi}-modal-settings-go-back`,
  refuseButton: `data-${widgetApi}-modal-introduction__refuse-btn`,
  refuseButtonSettings: `data-${widgetApi}-modal-settings__refuse-btn`,
  modalIntroduction: `data-${widgetApi}-modal-introduction`,
  modalSettings: `data-${widgetApi}-modal-settings`,
  acceptButton: `data-${widgetApi}-modal-introduction__accept-btn`,
  confirmButton: `data-${widgetApi}-modal-settings__confirm-btn`
}

const classNames = {
  cookieName: 'data-cookie-consent-name',
  cookieValue: 'cookie-values-enabled',
  masterCookieName: 'master-cookie',
  accordionCollapseClass: 'c-accordion__item-collapse',
  accordionHeadingClass: 'c-accordion__item-heading',
  switchItems: `${widgetApi}__option-item-cookies`
}

const cookieLevels = {
  CookieValueUndefined: 'undefined',
  CookieValueStandard: 'Standard',
  CookieValueLimited: 'Limited'
}

export default class CookieConsent {
  /**
  *
  * @constructor
  * @param {HTMLElement} element - The HTML element.
  */
  constructor (element) {
    if (!element) { return }
    this.element = element

    this.cookie = storage.cookie
    this.modal = this.element.querySelector(`[${widgetQueries.modalIntroduction}]`)['c-modal-v2']
    this.modalSettings = this.element.querySelector(`[${widgetQueries.modalSettings}]`)['c-modal-v2']
    this.refuseButton = this.element.querySelector(`[${widgetQueries.refuseButton}]`)
    this.refuseButtonSettings = this.element.querySelector(`[${widgetQueries.refuseButtonSettings}]`)
    this.acceptButton = this.element.querySelector(`[${widgetQueries.acceptButton}]`)
    this.confirmButton = this.element.querySelector(`[${widgetQueries.confirmButton}]`)
    this.goBackButton = this.element.querySelector(`[${widgetQueries.goBackButton}]`)

    this._setListeners()

    this._init()
  }

  _hideBackdropIfModalIsNotShown () {
    const styles = window.getComputedStyle(this.element)
    const modalIsHidden = styles.display === 'none' || styles.visibility === 'hidden'
    if (modalIsHidden) {
      this.modal.setProp('hiddenBackdrop', true)
    }
  }

  _closeModals () {
    this.modal.close()
    this.modalSettings.close()
  }

  _init () {
    const dataset = this._getDataset()

    if (!this._isCookieSet(dataset)) {
      this._confirmSelection(cookieLevels.CookieValueUndefined)
      this._hideBackdropIfModalIsNotShown()
      this.modal.open()
    }
  }

  _isCookieSet (data) {
    const cookieValue = this.cookie.get(data.cookieName)
    return (cookieValue !== null && cookieValue !== undefined && cookieValue !== cookieLevels.CookieValueUndefined)
  }

  _setCookies (data) {
    if (this._isCookieSet(data)) {
      this._cleanCookie()
    }
    this.cookie.set(data.cookieName, data[toCamelCase(classNames.cookieValue)], {
      domain: data.cookieDomain,
      path: data.cookiePath,
      expirationDays: parseInt(data.cookieExpirationDays)
    })
    this._closeModals()
  }

  _setListeners () {
    this.modalSettings.events.on('open', () => this.modal.close())
    this.acceptButton.addEventListener('click', () => this._confirmSelection(cookieLevels.CookieValueStandard))
    this.refuseButton.addEventListener('click', () => this._confirmSelection(cookieLevels.CookieValueLimited))
    this.refuseButtonSettings.addEventListener('click', () => this._confirmSelection(cookieLevels.CookieValueLimited))
    this.confirmButton.addEventListener('click', () => this._confirmSelection())
    this.goBackButton.addEventListener('click', () => {
      this.modalSettings.close()
      this.modal.open()
    })
  }

  /**
   * Confirm selection of cookies
   * - Revision pending -
   */
  _confirmSelection (cookieLevel) {
    const dataset = this._getDataset()
    const cookieSettingElements = Array.from(this.element.querySelectorAll(`.${classNames.switchItems} input[class="c-checkbox__input"]`))
    if (cookieLevel) {
      dataset[toCamelCase(classNames.cookieValue)] = cookieLevel
      this._setCookies(dataset)
    } else if (cookieSettingElements) {
      let standard = false
      cookieSettingElements.forEach(element => {
        if (element.value === cookieLevels.CookieValueStandard) {
          if (element.checked) {
            standard = true
          }
        }
      })

      if (!standard) {
        dataset[toCamelCase(classNames.cookieValue)] = cookieLevels.CookieValueLimited
      } else {
        dataset[toCamelCase(classNames.cookieValue)] = cookieLevels.CookieValueStandard
      }
      this._setCookies(dataset)
      this._closeModals()
    }
  }

  /**
   * Remove cookies
   */
  _cleanCookie () {
    const dataset = this._getDataset()
    this.cookie.delete(dataset.cookieName)
  }

  /**
   * get dataset needed to get cookie info
   */
  _getDataset () {
    return this.element.dataset
  }
}

registerWidget(CookieConsent, widgetApi)
