import { ga4Mapper } from '../mappers/ga4-mapper'

/**
* Mapper for the rebooking options
* @param {String} textBtn
* @returns {Object | null}
*/
export const rebookingOptionsMapper = (textBtn) => {
  let dataObject = null
  if (textBtn) {
    dataObject = ga4Mapper({
      event: 'rebookingAccount',
      description: 'book a different holiday',
      element: 'button on overlay',
      type: textBtn,
      result: 'redirect to PLP',
      interaction: true
    })
  }
  return dataObject
}
