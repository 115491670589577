import { profileDataEvents as events } from '../../../../js/document/event-types'
import { ga4Mapper } from './ga4-mapper'

/**
* Mapper for the profile data
* @param {String} eventName
* @param {Object} data
* @returns {Object}
*/
export const profileDataMapper = (eventName, data) => {
  let dataObject = null
  const eventData = getEventData(eventName, data)
  if (eventData && eventData.event && eventData.type && eventData.result) {
    dataObject = ga4Mapper({
      ...eventData,
      description: 'edit profile',
      element: 'clickable text',
      interaction: true
    })
  }
  return dataObject
}

/**
* Get the event data for the profile data mapper
* @param {String} eventName
* @param {Object} data
* @returns {Object}
*/
export const getEventData = (eventName, data) => {
  if (!data || !data.attr) return
  switch (eventName) {
    case events.DATA_OPENED:
      if (!data.attr.wProfileData__collapse) return
      return {
        event: 'profileDataOpened',
        type: `${data.attr.wProfileData__collapse} opened`,
        result: 'opened'
      }

    case events.DATA_EDITED:
      if (!data.attr.wProfileData__formEditBtn) return
      return {
        event: 'profileDataEdited',
        type: `${data.attr.wProfileData__formEditBtn} edited`,
        result: 'edited'
      }

    default:
      return ''
  }
}
