import { SERVICE_MAPPER_TYPE, SET_SERVICE_OPERATION_TYPE } from '../../constants'
import { mapAdd, mapRemove, mapAddInboundLuggage, mapAddAppliesToAllParticipants } from './booking-service-mapper'

export const checkoutMapper = (data, bookingData, servicesData) => {
  let events = []
  if (bookingData && bookingData.products.length > 0) {
    events = bookingServicesMapper(SERVICE_MAPPER_TYPE.checkout, data, bookingData, servicesData)
  }
  return events
}

export const selfServiceMapper = (data, servicesData) => {
  return bookingServicesMapper(SERVICE_MAPPER_TYPE.selfService, data, null, servicesData)
}

const bookingServicesMapper = (serviceMapperType, data, bookingData, servicesData) => {
  let events = []
  if (data && data.body && servicesData) {
    let event = null
    if (data.body.appliesToAllParticipants) {
      const appliesToAllParticipantsEvents = mapAddAppliesToAllParticipants(serviceMapperType, data, data.body, bookingData, servicesData)
      if (Array.isArray(appliesToAllParticipantsEvents)) {
        events = events.concat(appliesToAllParticipantsEvents)
      }
    }

    if (data.body.operationType === SET_SERVICE_OPERATION_TYPE.swap) {
      event = mapRemove(serviceMapperType, data, data.body.oldService, bookingData, servicesData)
      event && events.push(event)
    } else if (data.body.operationType === SET_SERVICE_OPERATION_TYPE.remove) {
      event = mapRemove(serviceMapperType, data, data.body.newService, bookingData, servicesData)
      event && events.push(event)
    }

    if ([SET_SERVICE_OPERATION_TYPE.swap, SET_SERVICE_OPERATION_TYPE.add].includes(data.body.operationType)) {
      event = mapAdd(serviceMapperType, data, data.body.newService, bookingData, servicesData)
      event && events.push(event)
      const luggageEvents = mapAddInboundLuggage(serviceMapperType, data, bookingData, servicesData)
      if (Array.isArray(luggageEvents)) {
        events = events.concat(luggageEvents)
      }
    }
  }
  return events
}
