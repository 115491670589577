import { registerWidget } from '../../../js/core/widget/widget-directory'

const widgetApi = 'w-faq-feedback'

const defaults = {
  method: 'POST'
}

const widgetQueries = {
  buttonsContainer: `[data-${widgetApi}__buttons]`,
  buttons: `[data-${widgetApi}__button]`,
  messageYes: `[data-${widgetApi}__message="yes"]`,
  messageNo: `[data-${widgetApi}__message="no"]`
}

const attr = {
  url: `data-${widgetApi}__url`,
  method: `data-${widgetApi}__method`,
  questionId: `data-${widgetApi}__question-id`,
  apiData: `data-${widgetApi}__api-data-`,
  buttonOption: `data-${widgetApi}__button`
}

const showClass = 'is-active'

export default class FaqFeedback {
  /**
   * Creates a new FaqFeedback
   *
   * @constructor
   *
   * @param {HTMLElement} element - The HTML component element.
   * @param {Object} [options]    - Options object
   *
   */
  constructor (element, options = {}) {
    this.element = element
    this.options = {
      ...defaults,
      url: this.element.getAttribute(attr.url),
      method: this.element.getAttribute(attr.method),
      questionId: this.element.getAttribute(attr.questionId),
      apiData: FaqFeedback._getDataAttributes(attr.apiData, element.attributes),
      ...options
    }

    // Get elements
    this.elements = {}
    this.elements.buttonsContainer = this.element.querySelector(widgetQueries.buttonsContainer)
    this.elements.buttons = this.element.querySelectorAll(widgetQueries.buttons)
    this.elements.messageYes = this.element.querySelector(widgetQueries.messageYes)
    this.elements.messageNo = this.element.querySelector(widgetQueries.messageNo)

    // Bind events
    this.elements.buttons.forEach(element => element.addEventListener('click', this._sendFeedback.bind(this)))

    // Update html
    this._setVisibility(this.elements.buttonsContainer, true)
    this._hideFeedbackMessages()

    // Send feedback to increase FAQ's ranking in top five
    const apiUrl = this._buildApiUrl()
    if (!apiUrl) return
    window.fetch(apiUrl, { method: this.options.method, mode: 'no-cors', credentials: 'include' })
  }

  /**
   * Posts a feedback ok message to the api,
   *  using the id of the question that was last fetched
   */
  sendFeedbackOk () {
    const apiUrl = this._buildApiUrl()
    if (!apiUrl) return
    window.fetch(apiUrl, { method: this.options.method, mode: 'no-cors', credentials: 'include' })
      .then(function (response) {
        if (response.ok) {
          this._showFeedbackOkSent()
        }
      }.bind(this))
  }

  _buildApiUrl () {
    let apiUrl = this.options.url
    const faqId = this.options.questionId
    if (!faqId) return null
    apiUrl = `${apiUrl}?questionId=${faqId}${this._getFixedQueryParams()}`
    return apiUrl
  }

  _sendFeedback (ev) {
    const buttonType = ev.target.getAttribute(attr.buttonOption)
    if (buttonType) {
      if (buttonType === 'yes') {
        this.sendFeedbackOk()
      } else if (buttonType === 'no') {
        this._hideFeedbackMessages()
        this._setVisibility(this.elements.buttonsContainer, false)
        this._setVisibility(this.elements.messageNo, true)
      }
    }
  }

  _showFeedbackOkSent () {
    this._hideFeedbackMessages()
    this._setVisibility(this.elements.buttonsContainer, false)
    this._setVisibility(this.elements.messageYes, true)
  }

  _hideFeedbackMessages () {
    this._setVisibility(this.elements.messageYes, false)
    this._setVisibility(this.elements.messageNo, false)
    return this
  }

  _setVisibility (element, show) {
    if (!element) return
    element.classList.toggle(showClass, show)
    return this
  }

  _getFixedQueryParams () {
    let params = ''
    for (const property in this.options.apiData) {
      if (Object.prototype.hasOwnProperty.call(this.options.apiData, property)) {
        params = params + '&' + property + '=' + this.options.apiData[property]
      }
    }
    return params
  }

  static _getDataAttributes (prefix, data) {
    const attrData = {}
    if (data) {
      for (let i = 0; i < data.length; i++) {
        const attribute = data[i]
        if (attribute.name.length > prefix.length && attribute.name.toLowerCase().startsWith(prefix.toLowerCase())) {
          attrData[attribute.name.substring(prefix.length).toLowerCase()] = attribute.value
        }
      }
    }
    return attrData
  }
}

registerWidget(FaqFeedback, widgetApi)
