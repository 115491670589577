import Dropdown from '../../components/dropdown/main'
import { registerWidget } from '../../../js/core/widget/widget-directory'

const widgetApi = 'w-dropdown-redirect'

export default class DropdownRedirect extends Dropdown {
  /**
   * Creates a new DropdownRedirect
   *
   * @constructor
   *
   * @param {HTMLElement} element - The element where to attach DropdownRedirect
   */
  constructor (element) {
    super(element)

    this.events.on('propChanged', (changes) => {
      if (changes.name === 'value') {
        this._redirect()
      }
    })
  }

  _redirect () {
    const selectedValue = this.props.value || ''
    if (selectedValue) window.location.href = selectedValue
  }
}

registerWidget(DropdownRedirect, widgetApi)
