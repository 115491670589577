import storage from '../../../js/document/web-storage'
import { getDiscount } from './get-discount'

const cookieNames = {
  userData: 'user-data'
}

export class CeProfile {
  constructor () {
    this.cookie = storage.cookie
  }

  /**
     *  If logged in user is a Partner, gets the parnter data with:
     *  - PartnerName
     *  - PartnerDiscount
     *
     *  Otherwise returns null
     */
  getPartnerData (publicationCode) {
    const userData = this.cookie.get(cookieNames.userData)
    if (!userData ||
          !userData.profileData ||
          !userData.expirationDateTime ||
          this._isPastDate(userData.expirationDateTime)) return null
    const profileData = this._base64DecodeStrToObj(userData.profileData)
    if (!profileData ||
          !profileData.isSunwebCE) return null
    const partnerDiscount = getDiscount(profileData.partnerDiscounts, publicationCode)
    if (!partnerDiscount ||
          !profileData.partnerName) return null

    const profile = {
      partnerName: profileData.partnerName,
      partnerDiscount
    }
    return profile
  }

  _base64DecodeStrToObj (str) {
    let decodedObj = {}
    try {
      decodedObj = JSON.parse(this._base64ToUtf8String(window.decodeURIComponent(str)))
    } catch (e) {
      if (window.newrelic) {
        window.newrelic.noticeError(e)
      }
    }
    return decodedObj
  }

  // Based on https://developer.mozilla.org/en-US/docs/Glossary/Base64#the_unicode_problem
  _base64ToUtf8String (base64) {
    const binString = atob(base64)
    const bytes = window.Uint8Array.from(binString, (m) => m.codePointAt(0))
    return new window.TextDecoder().decode(bytes)
  }

  _isPastDate (date) {
    return new Date(date) < new Date()
  }
}
