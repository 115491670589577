import * as eventTypes from '../../../../js/document/event-types'
import { accommodationDescriptionMapper } from '../mappers/accommodation-description/accommodation-description-mapper'
import pusher from '../pusher'

export function listenCollapseEvents (event, track) {
  event.on(eventTypes.collapseEvents.OPEN, data => {
    const mappedData = accommodationDescriptionMapper(
      data,
      eventTypes.collapseEvents.OPEN,
      track
    )
    pusher.pushDataObject(mappedData)
  })

  event.on(eventTypes.collapseEvents.CLOSE, data => {
    const mappedData = accommodationDescriptionMapper(
      data,
      eventTypes.collapseEvents.CLOSE,
      track
    )
    pusher.pushDataObject(mappedData)
  })
}
