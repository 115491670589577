import * as eventTypes from '../../../../js/document/event-types'
import { accommodationMealplansMapper } from '../mappers/accommodation-mealplans/accommodation-mealplans-mapper'
import pusher from '../pusher'

export function listenCollapseEvents (event, track) {
  event.on(eventTypes.collapseEvents.OPEN, (data) => {
    const mappedData = accommodationMealplansMapper(data, eventTypes.collapseEvents.OPEN, track)
    pusher.pushDataObject(mappedData)
  })

  event.on(eventTypes.collapseEvents.CLOSE, (data) => {
    const mappedData = accommodationMealplansMapper(data, eventTypes.collapseEvents.CLOSE, track)
    pusher.pushDataObject(mappedData)
  })
}
