import { registerWidget } from '../../../js/core/widget/widget-directory'

const widgetApi = 'w-permission-settings'

const widgetQueries = {
  form: `[data-${widgetApi}__form]`,
  mandatoryCombinedInputs: `[data-${widgetApi}__combined-required-input]`,
  mandatoryCombinedText: `data-${widgetApi}__combined-required-text`,
  errorMessage: `data-${widgetApi}__error-message`
}
export default class PermissionSettings {
  constructor (element) {
    this.element = element
    this._getHtmlElements()
    this._attachEvents()
  }

  _getHtmlElements () {
    this.form = this.element.querySelector(widgetQueries.form)
    this.mandatoryCombinedInputs = this.element.querySelectorAll(widgetQueries.mandatoryCombinedInputs)
  }

  _attachEvents () {
    if (this.form && this.mandatoryCombinedInputs.length) this.form.addEventListener('submit', this._handlerMandatoryCombinedInputs.bind(this))
  }

  _handlerMandatoryCombinedInputs (ev) {
    if (!this._isInputChecked()) {
      ev.preventDefault()
      this._toggleCheckboxState(false)
      this._attachEventsInputs()
    }
  }

  _isInputChecked () {
    return Array.from(this.mandatoryCombinedInputs).some(input => {
      return input['c-choice-list'].getProp('options').some(option => option.checked)
    })
  }

  _toggleCheckboxState (isValid) {
    this.mandatoryCombinedInputs.forEach(input => {
      const errorText = input.getAttribute(widgetQueries.mandatoryCombinedText) || ''
      input['c-choice-list'].styleValidity(isValid, [errorText])
    })
  }

  _attachEventsInputs () {
    this.mandatoryCombinedInputs.forEach(input => {
      input['c-choice-list'].events.on('changeOptions', () => {
        this._toggleCheckboxState(this._isInputChecked())
      })
    })
  }
}

registerWidget(PermissionSettings, widgetApi)
