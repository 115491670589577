import * as eventTypes from '../../../../js/document/event-types'
import { globalSearchMapper, searchModifiersMapper, searchErrorMapper, searchMapMapper } from '../mappers/search/search-global-mapper'
import { impressionsAccoListerMapper, impressionsSearchMapper } from '../mappers/search/search-impressions-mapper'
import { clickSearchResultMapper } from '../mappers/search/search-result-click-mapper'
import { clickFilterMapper } from '../mappers/filters-mapper'
import pusher from '../pusher'
import { filtersSections, formatParticipantsData, formatDataFromChipFilters, filterAndMapSelectedData, filterAndMapSelectedDropdownData } from '../helpers/filters-config-convention-transform'
import { searchMap as searchMapEvents } from '../../search-map/event-types'
import { favorite as favoriteEvents } from '../../../components/favorite/event-types'
import { favoritesMapper } from '../mappers/favorites-mapper'

export function listenSearchWidgetEvents (event) {
  event.on(eventTypes.searchEvents.SEARCH_FILTERS_APPLIED, (data) => {
    const mappedData = globalSearchMapper(data)
    pusher.pushDataObjects(mappedData)
  })

  event.on(eventTypes.searchEvents.SEARCH_RESULTS, (data) => {
    const mappedData = impressionsSearchMapper(data)
    pusher.pushDataObject(mappedData)
  })

  event.on(eventTypes.searchEvents.SEARCH_REMOVE_SINGLE_CHIP_FILTER, (data) => {
    const formattedData = formatDataFromChipFilters(data.data)
    const mappedData = clickFilterMapper(data.type, formattedData, filtersSections.SEARCH)
    pusher.pushDataObject(mappedData)
  })

  event.on(eventTypes.searchEvents.SEARCH_MODIFIER_CHANGE, (data) => {
    const mappedData = searchModifiersMapper(data)
    pusher.pushDataObject(mappedData)
  })

  event.on(eventTypes.searchEvents.SEARCH_MAP_OPENED, (data) => {
    const mappedData = searchMapMapper({
      event: 'mapOpenedSearch',
      type: 'show map',
      result: 'map open'
    })
    pusher.pushDataObject(mappedData)
  })

  event.on(eventTypes.searchEvents.SEARCH_ERROR, (data) => {
    const mappedData = searchErrorMapper(data)
    pusher.pushDataObject(mappedData)
  })

  event.on(eventTypes.searchEvents.SEARCH_RESULT_CLICK, (data) => {
    const mappedData = impressionsSearchMapper([data.data])
    const clickEvent = clickSearchResultMapper(mappedData)
    pusher.pushDataObject(clickEvent)
  })

  event.on(favoriteEvents.FAVORITE_MODAL_CLOSE, (data) => {
    data = {
      ...data,
      action: 'Close',
      page: 'PLP'
    }
    const mappedData = favoritesMapper(data)
    pusher.pushDataObject(mappedData)
  })

  event.on(favoriteEvents.FAVORITE_MODAL_OPEN, (data) => {
    data = {
      ...data,
      action: 'Open',
      page: 'PLP'
    }
    const mappedData = favoritesMapper(data)
    pusher.pushDataObject(mappedData)
  })

  event.on(favoriteEvents.FAVORITE_LOGIN, (data) => {
    data = {
      ...data,
      action: 'Login',
      page: 'PLP'
    }
    const mappedData = favoritesMapper(data)
    pusher.pushDataObject(mappedData)
  })

  event.on(favoriteEvents.FAVORITE_SIGNUP, (data) => {
    data = {
      ...data,
      action: 'Signup',
      page: 'PLP'
    }
    const mappedData = favoritesMapper(data)
    pusher.pushDataObject(mappedData)
  })

  event.on(favoriteEvents.FAVORITE_ADD, (data) => {
    data = {
      ...data,
      action: 'Add',
      page: 'PLP'
    }
    const mappedData = favoritesMapper(data)
    pusher.pushDataObject(mappedData)
  })

  event.on(favoriteEvents.FAVORITE_REMOVE, (data) => {
    data = {
      ...data,
      action: 'Remove',
      page: 'PLP'
    }
    const mappedData = favoritesMapper(data)
    pusher.pushDataObject(mappedData)
  })
}

export function listenDateFilterWidgetEvents (event, track) {
  event.on(eventTypes.datesFilterEvents.DATES_FILTERS_APPLIED, (data) => {
    const mappedData = clickFilterMapper(track, data, filtersSections.SEARCH)
    pusher.pushDataObject(mappedData)
  })
}

export function listenPriceFilterWidgetEvents (event, track) {
  event.on(eventTypes.priceFilterEvents.PRICE_FILTERS_APPLIED, (data) => {
    const mappedData = clickFilterMapper(track, data, filtersSections.SEARCH)
    pusher.pushDataObject(mappedData)
  })
}

export function listenParticipantsFilterWidgetEvents (event, track) {
  event.on(eventTypes.participantsFilterEvents.PARTICIPANTS_FILTER__FILTER_APPLIED, (data) => {
    data = formatParticipantsData(data)
    const mappedData = clickFilterMapper(track, data, filtersSections.SEARCH)
    pusher.pushDataObject(mappedData)
  })
}

export function listenAccoListerWidgetEvents (event) {
  event.on(eventTypes.accoListerEvents.ACCO_LISTER_PRINTED, (data) => {
    const mappedData = impressionsAccoListerMapper(data)
    pusher.pushDataObject(mappedData)
  })

  event.on(eventTypes.accoListerEvents.ACCO_LISTER_ITEM_CLICKED, (data) => {
    const mappedData = impressionsAccoListerMapper([data.data])
    const clickEvent = clickSearchResultMapper(mappedData, data.targetUrl)
    pusher.pushDataObject(clickEvent)
  })
}

export function listenDropdownMultipleEvents (event, track) {
  event.on('submit', (data) => {
    data = filterAndMapSelectedData(data, track, filtersSections.SEARCH)
    const mappedData = clickFilterMapper(track, data, filtersSections.SEARCH)
    pusher.pushDataObject(mappedData)
  })
}

export function listenChoiceListEvents (event, track) {
  event.on('changeOptions', (data, oldItems, method) => {
    if (oldItems === undefined || method === 'single') {
      data = filterAndMapSelectedData(data, track, filtersSections.SEARCH)
      const mappedData = clickFilterMapper(track, data, filtersSections.SEARCH)
      pusher.pushDataObject(mappedData)
    }
  })
}

export function listenDropdownEvents (event, track) {
  event.on('changedOptions', (data) => {
    data = filterAndMapSelectedDropdownData(data, track, filtersSections.SEARCH)
    const mappedData = clickFilterMapper(track, data, filtersSections.SEARCH)
    pusher.pushDataObject(mappedData)
  })
}

export function listenMapEvents (event, track) {
  event.on(searchMapEvents.SEARCH_MAP_CLOSED, (data) => {
    const mappedData = searchMapMapper({
      event: 'mapClosedSearch',
      type: 'cancel',
      result: 'map closed'
    })
    pusher.pushDataObject(mappedData)
  })
  event.on(searchMapEvents.SEARCH_MAP_REFRESH, (data) => {
    const mappedData = searchMapMapper({
      event: 'mapRefreshSearch',
      type: 'refresh',
      result: 'map refresh'
    })
    pusher.pushDataObject(mappedData)
  })
}

export function listenDestinationMultipleEvents (event, track) {
  event.on('submit', (data) => {
    data = formatDataFromChipFilters(data)
    const mappedData = clickFilterMapper(track, data, filtersSections.SEARCH)
    pusher.pushDataObject(mappedData)
  })
}
