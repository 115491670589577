import { personalDetailsEditEvents as events } from '../../../../js/document/event-types'
import { ga4Mapper } from './ga4-mapper'

export const personalDetailsEditMapper = (data, event) => {
  let dataObject = null
  if (data && event) {
    const type = data.labels ? { type: data.labels } : {}
    dataObject = ga4Mapper({
      event: 'manageBooking',
      description: getEventDescription(event),
      ...type,
      interaction: true,
      element: 'button'
    })
  }
  return dataObject
}

const getEventDescription = (event) => {
  switch (event) {
    case events.PERSONAL_DETAILS_EDIT_UPDATED:
      return 'change personal details'

    case events.PERSONAL_DETAILS_EDIT_EXTERNAL_FLIGHTS_FORM_OPEN:
      return 'open personal details external flights form'

    case events.PERSONAL_DETAILS_EDIT_EXTERNAL_FLIGHTS_FORM_UPDATED:
      return 'change personal details external flights form'

    default:
      return ''
  }
}
