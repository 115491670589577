
export const da = {
  period: 'Periode',
  oneWeek: 'En uge',
  twoWeeks: 'To uger',
  noCarNotification: 'Vi har ikke fundet nogen lejebiler til dette indkvartering'
}

export const de = {
  period: 'Zeitraum',
  oneWeek: 'Eine Woche',
  twoWeeks: 'Zwei Wochen',
  noCarNotification: 'Wir haben keine Autovermietung für diese Unterkunft gefunden'
}

export const en = {
  period: 'Period',
  oneWeek: 'One Week',
  twoWeeks: 'Two Weeks',
  noCarNotification: 'We haven\'t found any car rentals for this accommodation'
}

export const nb = {
  period: 'Periode',
  oneWeek: 'En uke',
  twoWeeks: 'To uker',
  noCarNotification: 'Vi har ikke funnet noen leiebil for dette overnattingsstedet'
}

export const fr = {
  period: 'Période',
  oneWeek: 'Une semaine',
  twoWeeks: 'Deux semaines',
  noCarNotification: 'Nous n\'avons pas trouvé de location de voiture pour ce logement'
}

export const nl = {
  period: 'Periode',
  oneWeek: 'Een week',
  twoWeeks: 'Twee weken',
  noCarNotification: 'We hebben geen huurauto\'s gevonden voor deze accommodatie'
}

export const sv = {
  period: 'Period',
  oneWeek: 'En vecka',
  twoWeeks: 'Två veckor',
  noCarNotification: 'Vi har inte hittat några hyrbilar för detta boende'
}
