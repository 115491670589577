import { PRODUCT_DIMENSIONS, CUSTOM_DIMENSIONS } from '../../constants'
import { mapExtraData } from '../../helpers/booking-dimensions-helper'

export const bookingStepMapper = (data) => {
  let dataObject = null
  if (data) {
    dataObject = {
      event: 'eec.checkout',
      ecommerce: {
        checkout: {
          actionField: {
            step: data.step ? data.step : 0
          }
        }
      },
      ...mapCommonData(data)
    }
  }
  return dataObject
}

export const bookingDraftMapper = (data) => {
  let dataObject = null
  if (data) {
    dataObject = bookingStepMapper(data)
    if (data.products && data.products.length > 0) {
      const accoObject = mapAccoProduct(data.products[0], data)
      if (accoObject) {
        dataObject.ecommerce.checkout.products = []
        dataObject.ecommerce.checkout.products.push(accoObject)
      }
      dataObject = { ...dataObject, ...mapExtraData(data.products[0], data) }
    }
  }
  return dataObject
}

export const bookingTransactionCompleteMapper = (data) => {
  let dataObject = null
  if (data) {
    dataObject = {
      event: data.eCommerceAnalytics.event || 'transactionComplete',
      ecommerce: data.eCommerceAnalytics.ecommerce,
      ...mapCommonData(data.eCommerceCheckout),
      email: data.eCommerceCheckout.email,
      name: data.eCommerceCheckout.name,
      lastName: data.eCommerceCheckout.lastName
    }
  }
  return dataObject
}

export const bookingPurchaseMapper = (data) => {
  let dataObject = null
  if (data) {
    dataObject = bookingTransactionCompleteMapper(data)
    if (data.eCommerceCheckout.products && data.eCommerceCheckout.products.length > 0) {
      let accoData = { ...data.eCommerceCheckout.products[0] }
      if (data.eCommerceAnalytics && data.eCommerceAnalytics.ecommerce && data.eCommerceAnalytics.ecommerce.purchase.products.lenght > 0) {
        accoData = { ...accoData, price: data.eCommerceAnalytics.ecommerce.purchase.products[0].price }
      }
      const accoObject = mapAccoProduct(accoData, data.eCommerceCheckout)
      if (accoObject) {
        dataObject.ecommerce.purchase.products.shift()
        dataObject.ecommerce.purchase.products.unshift(accoObject)
      }
      dataObject = { ...dataObject, ...mapExtraData(accoData, data.eCommerceCheckout) }
    }
  }
  return dataObject
}

function mapAccoProduct (accoData, data) {
  let accoObject = null
  if (accoData) {
    accoObject = {
      ...{
        [PRODUCT_DIMENSIONS.id]: accoData.id || '',
        [PRODUCT_DIMENSIONS.name]: accoData.name || '',
        [PRODUCT_DIMENSIONS.brand]: data.brand || '',
        [PRODUCT_DIMENSIONS.category]: accoData.category || '',
        [PRODUCT_DIMENSIONS.quantity]: accoData.quantity || 0,
        [PRODUCT_DIMENSIONS.price]: accoData.price || 0,
        [PRODUCT_DIMENSIONS.accoStars]: accoData.accoStars ? parseFloat(accoData.accoStars) : 0, // accoStars
        [PRODUCT_DIMENSIONS.locationId]: data.locationId || 0, // locationId
        [PRODUCT_DIMENSIONS.transport]: accoData.transport || '', // transport
        [PRODUCT_DIMENSIONS.composition]: data.partyComposition || '', // composition
        [PRODUCT_DIMENSIONS.badges]: accoData.badges || '', // badges
        [PRODUCT_DIMENSIONS.rating]: accoData.rating ? parseFloat(accoData.rating) : 0, // rating
        [PRODUCT_DIMENSIONS.duration]: accoData.duration ? accoData.duration : 0, // duration
        [PRODUCT_DIMENSIONS.tripComposition]: data.partyComposition || '', // composition
        [PRODUCT_DIMENSIONS.daysForDeparture]: calculateDaysBeforeDeparture(data.departureDate), // days for departure
        [PRODUCT_DIMENSIONS.totalGuests]: data.totalGuests || 0, // totalguests
        [PRODUCT_DIMENSIONS.departureDate]: data.departureDate || '', // selected departuredate
        [PRODUCT_DIMENSIONS.roomType]: accoData.roomType || '', // roomtype
        [PRODUCT_DIMENSIONS.boardType]: accoData.boardType || '' // boardtype
      }
    }
  }
  return accoObject
}
function mapCommonData (data) {
  return {
    [CUSTOM_DIMENSIONS.accoId]: data.id || '',
    [CUSTOM_DIMENSIONS.accoBrand]: data.brand || '',
    [CUSTOM_DIMENSIONS.partyComposition]: data.partyComposition || '',
    [CUSTOM_DIMENSIONS.totalGuests]: data.totalGuests || 0,
    [CUSTOM_DIMENSIONS.departureDate]: data.departureDate || '',
    [CUSTOM_DIMENSIONS.mandatoryPayment]: data.mandatoryPayment || false
  }
}

function calculateDaysBeforeDeparture (dateString) {
  let daysDiff = 0
  let departureDate = new Date()
  const oneDay = 24 * 60 * 60 * 1000
  const now = new Date()
  now.setHours(0, 0, 0, 0)
  if (dateString && dateString.length === 8) {
    departureDate = new Date(dateString.substr(0, 4), dateString.substr(4, 2) - 1, dateString.substr(6, 2))
  } else if (dateString && dateString.length === 10) {
    departureDate = new Date(dateString)
  }
  daysDiff = Math.round(Math.abs((now - departureDate) / oneDay))

  return daysDiff
}
