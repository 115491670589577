import { ga4Mapper } from '../ga4-mapper'

export const bookingChoiceListSwitchMapper = (data) => {
  let dataObject = null
  if (data && data.serviceName) {
    const serviceName = data.serviceName || ''
    const result = data.choosePerPersonIsChecked ? 'element selected' : 'element unselected'

    dataObject = ga4Mapper({
      event: 'Generic Service',
      description: 'Choose per participant',
      element: 'switch',
      type: serviceName,
      result,
      interaction: true
    })
  }
  return dataObject
}
