import { CUSTOM_PARAMETERS } from '../../constants'

export const bookingSkiServiceAccordionMapper = (data) => {
  return bookingSkiServiceMapper(data, 'Open Component', data.result ? 'show more' : 'show less')
}

export const bookingSkiServiceSwitchMapper = (data) => {
  return bookingSkiServiceMapper(data, 'Open Component', data.result ? 'on' : 'off')
}

export const bookingSkiServiceCancelAndSaveButtonMapper = (data) => {
  return bookingSkiServiceMapper(data, 'Close Component', data.result)
}

export const bookingSkiServiceFilterSportMapper = (data) => {
  return bookingSkiServiceMapper(data, 'Select sport', data.result)
}

export const bookingSkiServiceFilterDurationMapper = (data) => {
  return bookingSkiServiceMapper(data, 'Select duration', data.result)
}

export const bookingSkiServiceFilterMaterialCategoryMapper = (data) => {
  return bookingSkiServiceMapper(data, 'Select class of material', data.result)
}

export const bookingSkiServiceFilterSportLevelMapper = (data) => {
  return bookingSkiServiceMapper(data, 'Select sport level', data.result)
}

export const bookingSkiServiceChoiceListMapper = (data) => {
  return bookingSkiServiceMapper(data, 'choice list', data.result)
}

const bookingSkiServiceMapper = (data, description, result) => {
  return {
    event: 'SKI',
    [CUSTOM_PARAMETERS.parameter_2]: description, // description
    [CUSTOM_PARAMETERS.parameter_3]: data.element, // element
    [CUSTOM_PARAMETERS.parameter_4]: data.serviceType, // type
    [CUSTOM_PARAMETERS.parameter_5]: result, // result
    [CUSTOM_PARAMETERS.parameter_6]: true // interaction
  }
}
