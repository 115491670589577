import pusher from '../pusher'
import * as eventTypes from '../../../../js/document/event-types'
import { personalDetailsEditMapper } from '../mappers/personal-details-edit-mapper'

export function listenPersonalDetailsEditWidgetEvents (event) {
  const personaDetailsEditUpdatedEvent = eventTypes.personalDetailsEditEvents.PERSONAL_DETAILS_EDIT_UPDATED
  const personaDetailsEditExternalFlightsFormOpenEvent = eventTypes.personalDetailsEditEvents.PERSONAL_DETAILS_EDIT_EXTERNAL_FLIGHTS_FORM_OPEN
  const personaDetailsEditExternalFlightsFormUpdatedEvent = eventTypes.personalDetailsEditEvents.PERSONAL_DETAILS_EDIT_EXTERNAL_FLIGHTS_FORM_UPDATED

  event.on(personaDetailsEditUpdatedEvent, data => {
    const mappedData = personalDetailsEditMapper(data, personaDetailsEditUpdatedEvent)
    pusher.pushDataObject(mappedData)
  })

  event.on(personaDetailsEditExternalFlightsFormOpenEvent, data => {
    const mappedData = personalDetailsEditMapper(data, personaDetailsEditExternalFlightsFormOpenEvent)
    pusher.pushDataObject(mappedData)
  })

  event.on(personaDetailsEditExternalFlightsFormUpdatedEvent, data => {
    const mappedData = personalDetailsEditMapper(data, personaDetailsEditExternalFlightsFormUpdatedEvent)
    pusher.pushDataObject(mappedData)
  })
}
