import { CUSTOM_PARAMETERS } from '../../constants'

export const recommendationItemClickedMapper = (data) => {
  let dataObject = null

  const accoName = data.title || data.data.name || ''

  if (data) {
    dataObject = {
      event: 'PDP',
      [CUSTOM_PARAMETERS.parameter_2]: 'Recommendation interaction PDP', // description
      [CUSTOM_PARAMETERS.parameter_3]: 'Package Item', // element
      [CUSTOM_PARAMETERS.parameter_5]: 'Item clicked -> ' + accoName, // result
      [CUSTOM_PARAMETERS.parameter_6]: true // interaction
    }
  }
  return dataObject
}

export const recommendationSliderMapper = () => {
  let dataObject = null

  dataObject = {
    event: 'PDP',
    [CUSTOM_PARAMETERS.parameter_2]: 'Recommendation interaction PDP', // description
    [CUSTOM_PARAMETERS.parameter_3]: 'Slider', // element
    [CUSTOM_PARAMETERS.parameter_5]: 'scroll', // result
    [CUSTOM_PARAMETERS.parameter_6]: true // interaction
  }
  return dataObject
}
