import { filtersSections, formatRange, getEventData } from '../helpers/filters-config-convention-transform'
import { ga4Mapper } from './ga4-mapper'

export const clickFilterMapper = (type, data, filtersSection) => {
  const { eventType, element } = getEventData(type, filtersSection)
  const eventResult = formatRange(type, data, filtersSection)
  if (eventType !== null && eventResult !== null && eventResult.trim() !== '') {
    const { name, description } = getEventName(filtersSection)
    return ga4Mapper({
      event: name,
      description,
      element,
      type: eventType,
      result: eventResult,
      interaction: true
    })
  }
  return null
}

const getEventName = (filtersSection) => {
  switch (filtersSection) {
    case filtersSections.QUICK_SEARCH:
      return {
        name: 'clickQuickSearch',
        description: 'set quick search filter'
      }

    case filtersSections.SEARCH:
      return {
        name: 'clickFilter',
        description: 'set filter on product listing'
      }

    case filtersSections.BOOKING_GATE:
      return {
        name: 'clickAccoTabsFilter',
        description: 'set filter on price and book'
      }

    default:
      return {}
  }
}
