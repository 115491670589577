import { CUSTOM_PARAMETERS } from '../../constants'

export const accommodationDestinationButtonMapper = (data) => {
  return {
    event: 'PDP',
    [CUSTOM_PARAMETERS.parameter_2]: 'Read region info', // description
    [CUSTOM_PARAMETERS.parameter_3]: 'Button', // element
    [CUSTOM_PARAMETERS.parameter_4]: (data.href ? data.href : null), // type
    [CUSTOM_PARAMETERS.parameter_5]: 'Go to region page', // result
    [CUSTOM_PARAMETERS.parameter_6]: true // interaction
  }
}
