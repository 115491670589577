import { CUSTOM_PARAMETERS } from '../../constants'

export const reviewsListPaginationMapper = (data) => {
  let dataObject = null
  if (data && data.currentPage != null) {
    let result = data.currentPage.toString()
    if (data.previous) {
      result = 'previous'
    } else if (data.next) {
      result = 'next'
    }

    dataObject = {
      event: 'reviews',
      [CUSTOM_PARAMETERS.parameter_2]: 'page interaction', // description
      [CUSTOM_PARAMETERS.parameter_3]: 'pagination', // element
      [CUSTOM_PARAMETERS.parameter_4]: window.navigator.language || window.navigator.userLanguage, // type
      [CUSTOM_PARAMETERS.parameter_5]: result, // result
      [CUSTOM_PARAMETERS.parameter_6]: true // interaction
    }
  }

  return dataObject
}
