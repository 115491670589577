import { ga4Mapper } from '../ga4-mapper'

export const bookingParticipantsFormFieldValidationErrorMapper = (data) => {
  if (data) {
    return ga4Mapper({
      event: 'Error',
      validated: data.isValid,
      description: data.errorTypes && data.errorTypes.length > 0 ? data.errorTypes[0] : 'without error message',
      element: 'booking participants form',
      type: data.type,
      result: 'field_validation',
      interaction: true
    })
  }
  return null
}
