import { ga4Mapper } from '../ga4-mapper'

export const bookingComparisonTableSelectionMapper = (data, interaction = true) => {
  if (data !== null && data !== undefined && data.text && data.text !== '') {
    return ga4Mapper({
      event: 'Ancillaries',
      description: 'select ancillary with comparison table',
      element: 'button',
      type: data.text,
      result: data.price,
      interaction
    })
  }
  return null
}
