import { CUSTOM_PARAMETERS } from '../../constants'
import * as eventTypes from '../../../../../js/document/event-types'

export const reviewsTeaserShowMoreMapper = track => {
  let dataObject = null
  if (
    track === eventTypes.collapseEvents.OPEN ||
    track === eventTypes.collapseEvents.CLOSE
  ) {
    dataObject = {
      event: 'reviews',
      [CUSTOM_PARAMETERS.parameter_2]:
        'Read complete review - teaser component', // description
      [CUSTOM_PARAMETERS.parameter_3]: 'clickable text', // element
      [CUSTOM_PARAMETERS.parameter_4]: showMore(track)
        ? 'read more'
        : 'read less', // type
      [CUSTOM_PARAMETERS.parameter_5]: showMore(track)
        ? 'show more'
        : 'show less', // result
      [CUSTOM_PARAMETERS.parameter_6]: true // interaction
    }
  }

  return dataObject
}

const showMore = event => {
  switch (event) {
    case eventTypes.collapseEvents.OPEN:
      return true

    default:
      return false
  }
}
