import { ga4Mapper } from '../ga4-mapper'

export const bookingPreferencesMapper = (data) => {
  if (data) {
    return ga4Mapper({
      event: 'checkout_event',
      description: 'accommodation preferences',
      element: 'checkbox',
      type: data.getAttribute('data-text'),
      result: data.checked ? 'preference selected' : 'preference deselected'
    })
  }
  return null
}
